import dayjs from "dayjs";
import { useCallback } from "react";
import { debounce } from "../../utils/debounce";

export default function Input({
  title,
  placeholder,
  valKey,
  inputValues,
  setInputValues,
  displayFunction=(k) => {return k},
  singleFieldValidation=null,
  setFormErrors,
  formErrors,
  disabled = false,
  isTextArea = false,
  isDate = false,
  isIcon = false,
  icon = null,
  isMasked = false,
  type = "text",
  isRequired = false,
}) {
  const debounceSingleFieldValidation = useCallback(
    debounce(async ({ name, value }) => {
      const { isValid, errors } = await singleFieldValidation(name, value);
      console.log("debounceSingleFieldValidation", isValid, errors, value);
      let tempError = { ...formErrors };
      if (isValid) tempError = { ...tempError, [name]: "" };
      else tempError = { ...tempError, [name]: errors[name] };
      setFormErrors(tempError);
    }, 1000),
    [formErrors]
  );

  const handleChange = (event) => {
    const { value } = event.target;
    // coming from zustand
    setInputValues({ ...inputValues, [valKey]: value });
    if (singleFieldValidation)
      debounceSingleFieldValidation({
        name: valKey,
        value: value,
      });
  };

  const handleDateInput = (val) => {
    return dayjs(val).format("YYYY-MM-DD");
  };

  const showMasked = (val) => {
    if (isMasked) {
      return val?.replace(/.(?=.{4})/g, "*");
    }
    return val;
  };

  return (
    <div className="mt-4">
      <label
        htmlFor={title}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {title} {isRequired && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1">
        {isTextArea ? (
          <textarea
            rows={4}
            name={title}
            id={title}
            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder ? placeholder : "Write here"}
            value={
              isDate
                ? handleDateInput(inputValues?.[valKey])
                : inputValues?.[valKey]
            }
            onChange={handleChange}
            disabled={disabled}
          />
        ) : (
          <input
            type={type}
            name={title}
            id={title}
            className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder={placeholder ? placeholder : "Write here"}
            value={
              isDate
                ? handleDateInput(inputValues?.[valKey])
                : (disabled ? displayFunction(inputValues?.[valKey]) : inputValues?.[valKey])
            }
            onChange={handleChange}
            disabled={disabled}
          />
        )}
      </div>
      {formErrors?.[valKey] && (
        <p className="mt-1 text-xs text-red-500">{formErrors[valKey]}</p>
      )}
    </div>
  );
}
