import * as yup from "yup";

const rules = {
  name: yup.string().required("Name is required"),
  add1: yup.string().required("Address is required"),
  // contact_person_detail: yup.object().shape({
  //   name: yup.string().required("Name is required"),
  //   email: yup
  //     .string()
  //     .email("Email is not valid")
  //     .required("Email is required"),
  //   mobile: yup.string().required("Mobile is required"),
  //   password: yup.string().required("Password is required"),
  // }),
  // guards: yup.array().of(
  //   yup.object().shape({
  //     name: yup.string().required("Name is required"),
  //     email: yup
  //       .string()
  //       .email("Email is not valid")
  //       .required("Email is required"),
  //     mobile: yup.string().required("Mobile is required"),
  //     password: yup.string().required("Password is required"),
  //   })
  // ),
};

const schema = yup.object(rules);

export const unitMasterValidate = async (values) => {
  try {
    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const unitMasterSingleValidate = async (fieldName, value) => {
  try {
    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};
