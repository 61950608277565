import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Input from "../../components/Input/Input";
import {
  getAdminuserDetailApi,
  updateAdminUserDetailApi,
} from "../../services/user.services";
import FileInput from "../../components/Input/FileInput";

export default function AdminProfilePage() {
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [profile, setProfile] = useState({
    email: "",
    password: "",
    name: "",
    mobile: "",
    // start_shift: "",
    // end_shift: "",
    image: null, // Handle image upload
  });

  const getProfile = useQuery({
    queryKey: ["profile-admin"],
    queryFn: getAdminuserDetailApi,
    enabled: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      setProfile({
        email: data.email,
        name: data.name,
        mobile: data.mobile,
        password: data.password,
        // start_shift: data.start_shift,
        // end_shift: data.end_shift,
        image: data.image,
      });
    },
  });

  const updateProfileMutation = useMutation({
    mutationFn: updateAdminUserDetailApi,
    onSuccess: (data) => {
      toast.success("Profile updated successfully");
      //   navigate(-1);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  useEffect(() => {
    getProfile.refetch();
  }, []);

  const handleSave = () => {
    updateProfileMutation.mutate({
      userId: getProfile.data._id,
      data: profile
    })
  };

  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-base font-semibold leading-6 text-gray-900 mb-8">
        Profile Details
      </h1>

      <div>
        <Input
          placeholder="Enter email"
          title="Email"
          valKey="email"
          inputValues={profile}
          setInputValues={setProfile}
          isRequired={true}
        />

        <Input
          placeholder="Enter name"
          title="Name"
          valKey="name"
          inputValues={profile}
          setInputValues={setProfile}
          isRequired={true}
        />

        <Input
          placeholder="Enter mobile number"
          title="Mobile"
          valKey="mobile"
          inputValues={profile}
          setInputValues={setProfile}
          isRequired={true}
        />

        {/* <Input
          placeholder="Enter start shift time"
          title="Start Shift"
          valKey="start_shift"
          inputValues={profile}
          setInputValues={setProfile}
          isRequired={true}
        />

        <Input
          placeholder="Enter end shift time"
          title="End Shift"
          valKey="end_shift"
          inputValues={profile}
          setInputValues={setProfile}
          isRequired={true}
        /> */}

        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-4">
          Profile Image
        </p>
        <FileInput
          valKey="image"
          inputValues={profile}
          setInputValues={setProfile}
          
        />

        <label className="inline-flex items-center cursor-pointer mt-4">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 rounded-sm"
            checked={isNewPassword}
            onChange={() => setIsNewPassword((prev) => !prev)}
          />
          <span className="ml-2 text-gray-700">Change existing password?</span>
        </label>

        {isNewPassword && (
          <Input
            placeholder="Enter new password"
            title="New Password"
            valKey="new_password"
            inputValues={profile}
            setInputValues={setProfile}
            isRequired={true}
          />
        )}
      </div>

      <div className="isolate inline-flex rounded-md mt-8 justify-end w-full">
        <button
          type="button"
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSave}
        >
          {updateProfileMutation.isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </main>
  );
}
