import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const uploadFilesApi = async (data) => {
  try {
    console.log("uploadData", data);
    const res = await axios.post(BASE_URL + "/file-upload", data, {
      headers: {
        ...authHeader(),
        'Content-Type' : 'undefined'
      },
    });
    return res.data;
  } catch (e) {
    console.log("error", e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};