import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const uploadDocApi = async (payload) => {
  try {
    console.log({ payload }, payload.reminder_date.toString().length);
    if (
      !payload?.reminder_date ||
      (payload?.reminder_date && !payload.reminder_date.toString().length)
    ) {
      payload["reminder_date"] = undefined;
      console.log("Payload", payload);
    }
    const res = await axios.post(BASE_URL + "/doc-uploader", payload, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    console.log("error", e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const extractFilePropertiesFromBulkDocApi = async (data) => {
  try {
    const res = await axios.post(
      BASE_URL + "/doc-uploader/bulk-filename-extract-properties",
      data,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return res.data.data;
  } catch (e) {
    console.log("error", e);
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const uploadBulkDocApi = async (data_temp) => {
  try {
    let data = {};

    for (let key in data_temp) {
      if (data_temp[key]) {
        data[key] = data_temp[key];
      }
    }
    const res = await axios.post(BASE_URL + "/doc-uploader/bulk", data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    console.log("error", e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllDocsApi = async (filteredDocs) => {
  
  console.log('====================================');
  console.log('filteredDocs', filteredDocs);
  console.log('====================================');

  const queryParams = Object.entries(filteredDocs)
    .map(([group, types]) => {
      const queryString = Array.isArray(types) ? `${group}=${encodeURIComponent(types.join(","))}` : `${group}=${encodeURIComponent(types)}`
      return queryString;
    })
    .filter((queryString) => queryString.split("=")[1]) // Exclude empty values
    .join("&");
  console.log("filteredDocs", filteredDocs, queryParams);

  try {
    const res = await axios.get(BASE_URL + "/doc-uploader?" + queryParams, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllDocsSummartApi = async () => {
  try {
    const res = await axios.get(BASE_URL + "/doc-uploader/summary", {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAccountsYearlyDocMismatchSummaryApi = async (data) => {
  try {
    let query_string_arr = []
    for(let key in data){
      if(data[key]){
        query_string_arr.push(`${key}=${encodeURIComponent(data[key])}`)
      }
    }
    const query_str = query_string_arr.join("&");

    const res = await axios.get(
      BASE_URL +
        `/doc-uploader/accounts-mismatch-summary-yearly?${query_str}`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAccountsDocMismatchSummaryApi = async (
  monthString,
  doc_for,
  doc_type
) => {
  try {
    if (!monthString || !doc_for || !doc_type) {
      return {};
    }

    const res = await axios.get(
      BASE_URL +
        `/doc-uploader/accounts-mismatch-summary/doc-type/${doc_type}/doc-for/${doc_for}/month/${monthString}/`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getDocApi = async (id) => {
  try {
    const res = await axios.get(BASE_URL + "/doc-uploader/" + id, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateDocApi = async ({ id, payload }) => {
  try {
    const res = await axios.put(BASE_URL + "/doc-uploader/" + id, payload, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllRequestedDocsApi = async (queryParams) => {
  try {
    const res = await axios.get(BASE_URL + "/doc-request?" + queryParams, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getDocRequestApi = async (docId, docRequestId) => {
  try {
    const res = await axios.get(
      BASE_URL + "/doc-request/" + docRequestId + "/" + docId,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const uploadDocRequestApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/doc-request", data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    console.log("error", e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateDocRequestApi = async ({ id, payload }) => {
  try {
    const res = await axios.put(BASE_URL + "/doc-request/" + id, payload, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteDocApi = async ({ docId }) => {
  try {
    const res = await axios.delete(BASE_URL + "/doc-uploader/" + docId, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};