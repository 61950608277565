import React, { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import Input from "../../components/Input/Input";
import FileInput from "../../components/Input/FileInput";
import CustomSelect from "../../components/Input/Select";
import {
  unitMasterSingleValidate,
  unitMasterValidate,
} from "../../validation/unitMasterValidation";
import { toast } from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createUnitMasterApi,
  getUnitMasterApi,
  updateUnitMasterApi,
} from "../../services/unit-master.services";
import { useNavigate, useParams } from "react-router-dom";
import stateOptions from "../../utils/data/state_data.json";

const allStatuses = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
]
export default function AddUnitMasterPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isContactPersonNewPass, setIsContactPersonNewPass] = useState(false);
  const [unitMaster, setUnitMaster] = useState({
    name: "",
    remarks: "",
    status: allStatuses[0],
    add1: "",
    add2: "",
    state: "",
    city: "",
    district: "",
    pincode: "",
    // contact_person_detail: {
    //   name: "",
    //   email: "",
    //   mobile: "",
    //   password: "",
    // },
    // guards: [
    //   {
    //     name: "",
    //     email: "",
    //     mobile: "",
    //     password: "",
    //     start_shift: "",
    //     end_shift: "",
    //     image: "",
    //   },
    // ],
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    remarks: "",
    status: "",
    add1: "",
    add2: "",
    state: "",
    city: "",
    district: "",
    pincode: "",
    // contact_person_detail: {
    //   name: "",
    //   email: "",
    //   mobile: "",
    //   password: "",
    // },
    // guards: [
    //   {
    //     name: "",
    //     email: "",
    //     mobile: "",
    //     password: "",
    //     start_shift: "",
    //     end_shift: "",
    //     image: "",
    //   },
    // ],
  });

  const handleSave = async () => {
    // const { isValid, errors } = await unitMasterValidate(unitMaster);
    // if (isValid) {
      const tempUnitMaster = { ...unitMaster };
      tempUnitMaster.status = tempUnitMaster.status?.value ?? false;
      tempUnitMaster.state = tempUnitMaster.state?.label ?? "";
      if (id === "create") {
        createUnitMasterMutation.mutate(tempUnitMaster);
      } else {
        // if (tempUnitMaster.guards.length > 0) {
        //   tempUnitMaster.guards = tempUnitMaster.guards.map((guard) => {
        //     delete guard.change_current_password;
        //     return guard;
        //   });
        // }
        updateUnitMasterMutation.mutate({ id, data: tempUnitMaster });
      }
    // } 
    // else {
    //   setFormErrors((prevErrors) => {
    //     const updatedErrors = { ...prevErrors };

    //     Object.keys(errors).forEach((key) => {
    //       const value = errors[key];

    //       // Handle nested keys like "contact_person_detail.email"
    //       const keyParts = key.split(".");

    //       // If it's a nested key, drill down into the object
    //       if (keyParts.length > 1) {
    //         let currentLevel = updatedErrors;
    //         for (let i = 0; i < keyParts.length - 1; i++) {
    //           const part = keyParts[i];
    //           // Handle array notation like "guards[0]"
    //           if (part.includes("[") && part.includes("]")) {
    //             const [arrayKey, index] = part.split(/[[\]]/).filter(Boolean);
    //             // Ensure the array exists and has the required length
    //             if (!currentLevel[arrayKey]) currentLevel[arrayKey] = [];
    //             while (currentLevel[arrayKey].length <= parseInt(index)) {
    //               currentLevel[arrayKey].push({
    //                 name: "",
    //                 email: "",
    //                 mobile: "",
    //                 password: "",
    //               });
    //             }
    //             currentLevel = currentLevel[arrayKey][parseInt(index)];
    //           } else {
    //             currentLevel = currentLevel[part];
    //           }
    //         }
    //         // Set the final key's value
    //         currentLevel[keyParts[keyParts.length - 1]] = value;
    //       } else {
    //         // If it's a top-level key, update directly
    //         updatedErrors[key] = value;
    //       }
    //     });

    //     return updatedErrors;
    //   });
    // }
  };

  const onChangeField = (data, field, subField) => {
    setUnitMaster((prev) => {
      return {
        ...prev,
        [field]: {
          ...prev[field],
          [subField]: data[subField],
        },
      };
    });
  };

  const onChangeGuardField = (data, index, subField) => {
    const updatedUnitMaster = { ...unitMaster };
    updatedUnitMaster.guards[index][subField] = data[subField];

    setUnitMaster(updatedUnitMaster);
  };

  // const handleAddGurad = () => {
  //   toast.success("New Guard added");
  //   setUnitMaster((prev) => {
  //     return {
  //       ...prev,
  //       guards: [
  //         ...prev.guards,
  //         {
  //           name: "",
  //           email: "",
  //           mobile: "",
  //           password: "",
  //           start_shift: "",
  //           end_shift: "",
  //           image: "",
  //         },
  //       ],
  //     };
  //   });
  // };

  const createUnitMasterMutation = useMutation({
    mutationFn: createUnitMasterApi,
    onSuccess: (data) => {
      toast.success("Unit Master saved");
      navigate(-1);
    },
    onError: (err) => {
      // toast.error(err.message);
    },
  });

  const updateUnitMasterMutation = useMutation({
    mutationFn: updateUnitMasterApi,
    onSuccess: (data) => {
      toast.success("Unit Master updated");
      navigate(-1);
    },
    onError: (err) => {
      // toast.error(err.message);
    },
  });

  const getUnitMaster = useQuery({
    queryKey: ["unitmaster", id],
    retry: false,
    enabled: false,
    staleTime: Infinity,
    queryFn: () => getUnitMasterApi(id),
    onSuccess: (data) => {
      const tempData = { ...data };
      tempData.status = {
        label: data.status ? "Active" : "Inactive",
        value: data.status,
      };
      tempData.state = stateOptions.find(state => state.label === data.state)
      if (tempData.guards.length > 0) {
        tempData.guards = tempData.guards.map((guard) => {
          return {
            ...guard,
            change_current_password: false,
          };
        });
      }
      setUnitMaster(tempData);
    },
  });

  useEffect(() => {
    if (id && id !== "create") {
      getUnitMaster.refetch();
    }
  }, [id]);

  console.log("sd", unitMaster);

  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-base font-semibold leading-6 text-gray-900 mb-8">
        Add Unit Master
      </h1>

      <div>
        <Input
          placeholder="Enter name"
          title="Name"
          valKey="name"
          inputValues={unitMaster}
          setInputValues={(data) => setUnitMaster(data)}
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) => setFormErrors(data)}
          formErrors={formErrors}
          isRequired={true}
        />

        <Input
          placeholder="Enter remarks"
          title="Remarks"
          valKey="remarks"
          inputValues={unitMaster}
          setInputValues={(data) => setUnitMaster(data)}
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) => setFormErrors(data)}
          formErrors={formErrors}
        />

        <div className="mt-4"></div>
        <CustomSelect
          id="status"
          name="Status"
          value={unitMaster.status}
          onChange={(e) =>
            setUnitMaster((prev) => {
              return {
                ...prev,
                status: e,
              };
            })
          }
          options={allStatuses.map((unit) => ({
            label: unit.label,
            value: unit.value,
          }))}
          isRequired={true}
          
        />

        <Input
          placeholder="Enter add1"
          title="Address 1"
          valKey="add1"
          inputValues={unitMaster}
          setInputValues={(data) => setUnitMaster(data)}
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) => setFormErrors(data)}
          formErrors={formErrors}
          isRequired={true}
        />

        <Input
          placeholder="Enter add2"
          title="Address 2"
          valKey="add2"
          inputValues={unitMaster}
          setInputValues={(data) => setUnitMaster(data)}
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) => setFormErrors(data)}
          formErrors={formErrors}
          isRequired={true}
        />
        <div className="grid grid-cols-2 gap-4">
          <div className="mt-4">
            <CustomSelect
            id="state"
            name="State"
            value={unitMaster.state}
            onChange={(e) =>
              setUnitMaster((prev) => {
                return {
                  ...prev,
                  state: e,
                };
              })
            }
            options={stateOptions}
            isRequired={true}
          />
          </div>
          

          <Input
            placeholder="Enter city"
            title="City"
            valKey="city"
            inputValues={unitMaster}
            setInputValues={(data) => setUnitMaster(data)}
            singleFieldValidation={unitMasterSingleValidate}
            setFormErrors={(data) => setFormErrors(data)}
            formErrors={formErrors}
            isRequired={true}
          />

          <Input
            placeholder="Enter district"
            title="District"
            valKey="district"
            inputValues={unitMaster}
            setInputValues={(data) => setUnitMaster(data)}
            singleFieldValidation={unitMasterSingleValidate}
            setFormErrors={(data) => setFormErrors(data)}
            formErrors={formErrors}
            isRequired={true}
          />

          <Input
            placeholder="Enter pincode"
            title="Pincode"
            valKey="pincode"
            inputValues={unitMaster}
            setInputValues={(data) => setUnitMaster(data)}
            singleFieldValidation={unitMasterSingleValidate}
            setFormErrors={(data) => setFormErrors(data)}
            formErrors={formErrors}
            isRequired={true}
          />
        </div>

        {/* <p className="mt-8">
          Contact person detail <span className="text-red-600">*</span>
        </p>

        <Input
          placeholder="Enter name"
          title="Name"
          valKey="name"
          inputValues={unitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "name")
          }
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) =>
            setFormErrors((prev) => ({ ...prev, contact_person_detail: data }))
          }
          formErrors={formErrors.contact_person_detail}
        />
        <Input
          placeholder="Enter email"
          title="Email"
          valKey="email"
          inputValues={unitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "email")
          }
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) =>
            setFormErrors((prev) => ({ ...prev, contact_person_detail: data }))
          }
          formErrors={formErrors.contact_person_detail}
        />
        <Input
          placeholder="Enter mobile number"
          title="Mobile"
          valKey="mobile"
          inputValues={unitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "mobile")
          }
          singleFieldValidation={unitMasterSingleValidate}
          setFormErrors={(data) =>
            setFormErrors((prev) => ({ ...prev, contact_person_detail: data }))
          }
          formErrors={formErrors.contact_person_detail}
        />
        {id === "create" ? (
          <Input
            placeholder="Enter password"
            title="Password"
            valKey="password"
            inputValues={unitMaster.contact_person_detail}
            setInputValues={(data) =>
              onChangeField(data, "contact_person_detail", "password")
            }
            singleFieldValidation={unitMasterSingleValidate}
            setFormErrors={(data) =>
              setFormErrors((prev) => ({
                ...prev,
                contact_person_detail: data,
              }))
            }
            formErrors={formErrors.contact_person_detail}
          />
        ) : (
          <label className="inline-flex items-center cursor-pointer mt-4">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 rounded-sm"
              checked={isContactPersonNewPass}
              onChange={() => setIsContactPersonNewPass((prev) => !prev)}
            />
            <span className="ml-2 text-gray-700">
              Change existing password?
            </span>
          </label>
        )}
        {isContactPersonNewPass && (
          <Input
            placeholder="Enter new password"
            title="New password"
            valKey="new_password"
            inputValues={unitMaster.contact_person_detail}
            setInputValues={(data) =>
              onChangeField(data, "contact_person_detail", "new_password")
            }
          />
        )} */}

        {/* <div className="flex justify-between items-center mt-8">
          <p className="">
            Guards <span className="text-red-600">*</span>
          </p>
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-violet-600 shadow-sm ring-1 ring-inset ring-violet-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
            onClick={() => handleAddGurad()}
          >
            Add Guard
          </button>
        </div> */}
        {/* {unitMaster.guards.map((guard, index) => {
          return (
            <div key={`guard-list-${index}`}>
              <div className="flex justify-between items-center mt-6">
                <p className="">
                  Guard {index + 1} <span className="text-red-600">*</span>
                </p>

                <button
                  onClick={() => {
                    setUnitMaster((prev) => {
                      return {
                        ...prev,
                        guards: prev.guards.filter((_, i) => i !== index),
                      };
                    });
                    toast.success(`Guard ${index + 1} removed`);
                  }}
                >
                  <TrashIcon className="h-5 w-5 text-red-600" />
                </button>
              </div>
              <Input
                placeholder="Enter name"
                title="Name"
                valKey="name"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "name")
                }
                singleFieldValidation={unitMasterSingleValidate}
                setFormErrors={(data) => {
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      guards: prev.guards.map((guard, i) => {
                        if (i === index) {
                          return data;
                        }
                        return guard;
                      }),
                    };
                  });
                }}
                formErrors={formErrors.guards[index]}
              />
              <Input
                placeholder="Enter email"
                title="Email"
                valKey="email"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "email")
                }
                singleFieldValidation={unitMasterSingleValidate}
                setFormErrors={(data) => {
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      guards: prev.guards.map((guard, i) => {
                        if (i === index) {
                          return data;
                        }
                        return guard;
                      }),
                    };
                  });
                }}
                formErrors={formErrors.guards[index]}
              />
              <Input
                placeholder="Enter mobile number"
                title="Mobile"
                valKey="mobile"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "mobile")
                }
                singleFieldValidation={unitMasterSingleValidate}
                setFormErrors={(data) => {
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      guards: prev.guards.map((guard, i) => {
                        if (i === index) {
                          return data;
                        }
                        return guard;
                      }),
                    };
                  });
                }}
                formErrors={formErrors.guards[index]}
              />

              {!unitMaster.guards[index].hasOwnProperty(
                "change_current_password"
              ) ? (
                <Input
                  placeholder="Enter password"
                  title="Password"
                  valKey="password"
                  inputValues={guard}
                  setInputValues={(data) =>
                    onChangeGuardField(data, index, "password")
                  }
                  singleFieldValidation={unitMasterSingleValidate}
                  setFormErrors={(data) => {
                    setFormErrors((prev) => {
                      return {
                        ...prev,
                        guards: prev.guards.map((guard, i) => {
                          if (i === index) {
                            return data;
                          }
                          return guard;
                        }),
                      };
                    });
                  }}
                  formErrors={formErrors.guards[index]}
                />
              ) : (
                <label className="inline-flex items-center cursor-pointer mt-4">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 rounded-sm"
                    checked={guard.change_current_password}
                    onChange={() =>
                      setUnitMaster((prev) => {
                        return {
                          ...prev,
                          guards: prev.guards.map((guard, i) => {
                            if (i === index) {
                              return {
                                ...guard,
                                change_current_password:
                                  !guard.change_current_password,
                              };
                            }
                            return guard;
                          }),
                        };
                      })
                    }
                  />
                  <span className="ml-2 text-gray-700">
                    Change existing password?
                  </span>
                </label>
              )}
              {guard.change_current_password && (
                <Input
                  placeholder="Enter new password"
                  title="New password"
                  valKey="new_password"
                  inputValues={guard}
                  setInputValues={(data) =>
                    onChangeGuardField(data, index, "new_password")
                  }
                />
              )}

              <Input
                placeholder="Enter shift start time"
                title="Shift Start"
                valKey="start_shift"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "start_shift")
                }
                singleFieldValidation={unitMasterSingleValidate}
                setFormErrors={(data) => {
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      guards: prev.guards.map((guard, i) => {
                        if (i === index) {
                          return { ...guard, ...data };
                        }
                        return guard;
                      }),
                    };
                  });
                }}
                formErrors={formErrors.guards[index]}
                type="time"
              />

              <Input
                placeholder="Enter shift end time"
                title="Shift End"
                valKey="end_shift"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "end_shift")
                }
                singleFieldValidation={unitMasterSingleValidate}
                setFormErrors={(data) => {
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      guards: prev.guards.map((guard, i) => {
                        if (i === index) {
                          return { ...guard, ...data };
                        }
                        return guard;
                      }),
                    };
                  });
                }}
                formErrors={formErrors.guards[index]}
                type="time"
              />
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-4">
                Gaurd Image
              </p>
              <FileInput
                valKey="image"
                inputValues={guard}
                setInputValues={(data) =>
                  onChangeGuardField(data, index, "image")
                }
              />
            </div>
          );
        })} */}

        <div className="isolate inline-flex rounded-md mt-8 justify-end w-full">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSave}
          >
            {createUnitMasterMutation.isLoading ||
            updateUnitMasterMutation.isLoading
              ? "Saving..."
              : "Save"}
          </button>
        </div>
      </div>
    </main>
  );
}
