import { useState, Fragment, useEffect } from "react";
import DataFilter from "./DataFilter";
import AddDocModal from "./AddDocModal";
import { useUserStore } from "../../../store/user";
import { useQuery } from "@tanstack/react-query";
import { getAllDocsApi } from "../../../services/document.services";
import { toast } from "react-hot-toast";
const moment = require("moment");
export default function DataTable({
  filteredDocs,
  setFilteredDocs,
  setAddDocModal,
  setCart,
  setCurrentSelectedDoc,
  isShowCard,
}) {
  const userDetail = useUserStore((state) => state.userDetail);
  const [cartTrack, setCartTrack] = useState([]);
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const getAllDocsQuery = useQuery({
    queryKey: ["allDocs", filteredDocs],
    retry: false,
    enabled: false,
    queryFn: () => getAllDocsApi(filteredDocs),
    onSuccess: (data) => console.log(data),
    onError: (error) => toast.error(error.message),
  });

  const totalPages = getAllDocsQuery.data?.data?.totalPages;
  const data = getAllDocsQuery.data?.data?.docs_uploaded ?? [];

  useEffect(() => {
    // Refetch only if isShowCard and all 3 doc_group, doc_type and doc_for are present
    if (
      isShowCard &&
      filteredDocs?.doc_for &&
      filteredDocs?.doc_group &&
      filteredDocs?.doc_type
    ) {
      getAllDocsQuery.refetch();
    }
  }, [filteredDocs, isShowCard]);

  function getClassname(enumValue) {
    switch (enumValue) {
      case "CHECKED":
        return " text-yellow-800";
      case "AUTHORIZED":
        return " text-green-800";
      case "PENDING":
        return "text-red-800";
      default:
        return "";
    }
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setFilteredDocs((prev) => ({
      ...prev,
      limit: [limit],
      page: [currentPage],
    }));
  }, [currentPage, limit]);

  const enableViewButton = (status) => {
    if (
      status === "AUTHORIZED" &&
      ["U", "STAFF", "MAKER", "CHECKER", "AUTHORIZER", "A"].includes(
        userDetail.userType
      )
    ) {
      return true;
    } else if (
      status === "CHECKED" &&
      ["CHECKER", "AUTHORIZER", "A"].includes(userDetail.userType)
    ) {
      return true;
    } else if (
      status === "PENDING" &&
      ["MAKER", "CHECKER", "A"].includes(userDetail.userType)
    ) {
      return true;
    } else return false;
  };

  return (
    <>
      <DataFilter
        filteredDocs={filteredDocs}
        setFilteredDocs={setFilteredDocs}
      />
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  {filteredDocs?.doc_group === "Accounts" ? (
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Data Month
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Invoice Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Invoice Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Invoice Value
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Party Legal Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Party GST
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  ) : (
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Doc Group
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Doc Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Doc For
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status{" "}
                      </th>

                      <th scope="col" className=" py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getAllDocsQuery?.isLoading ? (
                    <tr>
                      <td
                        colSpan="5"
                        className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      ></td>
                    </tr>
                  ) : (
                    data.map((doc) => {
                      return filteredDocs?.doc_group === "Accounts" ? (
                        <tr key={`${doc._id}`}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment
                              .utc(doc.doc_month)
                              .local()
                              .format("MM-YYYY")}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.metadata.invoice_date
                              .slice(0, 10)
                              .split("-")
                              .reverse()
                              .join("-")}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.metadata.invoice_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ₹{doc.metadata.invoice_value}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.metadata.legal_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.metadata.gst_of_party}
                          </td>
                          <td className=" whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {doc.status === "AUTHORIZED" && (
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white mr-2"
                                onClick={() => {
                                  setCart((prev) => [...prev, doc]);
                                  setCartTrack((prev) => [...prev, doc._id]);
                                }}
                                disabled={cartTrack.includes(doc._id)}
                              >
                                Add Cart
                              </button>
                            )}
                            {enableViewButton(doc.status) && (
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                onClick={() => {
                                  setCurrentSelectedDoc(doc);
                                  setAddDocModal(true);
                                }}
                              >
                                {doc?.filepath && doc.filepath.length ? "View" : "Upload Doc"}
                              </button>
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr key={doc._id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {doc.doc_group}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.doc_type}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {doc.doc_for?.legal_name ||
                              doc.doc_for?.loginMobile}
                          </td>
                          <td
                            className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${getClassname(
                              doc.status
                            )}`}
                          >
                            {doc.status}
                          </td>

                          <td className=" whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            {doc.status === "AUTHORIZED" && (
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white mr-2"
                                onClick={() => {
                                  setCart((prev) => [...prev, doc]);
                                  setCartTrack((prev) => [...prev, doc._id]);
                                }}
                                disabled={cartTrack.includes(doc._id)}
                              >
                                Add Cart
                              </button>
                            )}
                            {enableViewButton(doc.status) && (
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                onClick={() => {
                                  setCurrentSelectedDoc(doc);
                                  setAddDocModal(true);
                                }}
                              >
                                {doc?.filepath && doc.filepath.length ? "View" : "Upload Doc"}
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* showing pagination */}
        <nav
          className="flex items-center justify-betwee py-3 "
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{currentPage}</span> of{" "}
              <span className="font-medium">{totalPages}</span>
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className=" inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className=" ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}
