import React, { useEffect } from "react";
// import {
//   BiometricAuth,
//   AndroidBiometryStrength,
// } from "@aparajita/capacitor-biometric-auth";
import DataTable from "./components/DataTable";

export default function AttendancePage() {
  // async function authenticate() {
  //   try {
  //     await BiometricAuth.authenticate({
  //       reason: "Please authenticate",
  //       cancelTitle: "Cancel",
  //       allowDeviceCredential: true,
  //       iosFallbackTitle: "Use passcode",
  //       androidTitle: "Biometric login",
  //       androidSubtitle: "Log in using biometric authentication",
  //       androidConfirmationRequired: false,
  //       androidBiometryStrength: AndroidBiometryStrength.weak,
  //     });
  //     // Fingerprint verified successfully
  //     markAttendanceMutation.mutate({
  //       type: "IN",
  //     });
  //     console.log("Fingerprint verified successfully");
  //   } catch (error) {
  //     if (error) {
  //       console.error("Biometry error:", error);
  //       alert(error.reason);
  //     }
  //   }
  // }

  // async function authenticateFingerprint() {
  //   try {
  //     const result = await BiometricAuth.checkBiometry({
  //       reason: "For attendance verification",
  //       title: "Attendance System",
  //       subtitle: "Verify your identity",
  //       description: "Please scan your fingerprint to mark your attendance",
  //     });

  //     if (result.isAvailable) {
  //       // Fingerprint verified successfully
  //       await authenticate();
  //     } else {
  //       // Authentication failed
  //       console.error(
  //         "Fingerprint authentication error",
  //         JSON.stringify(result)
  //       );
  //       alert(result.reason);
  //     }
  //   } catch (error) {
  //     console.error("Error during biometric auth:", error);
  //     alert("An error occurred during authentication");
  //   }
  // }

  // const markAttendanceMutation = useMutation({
  //   mutationFn: markAttendanceApi,
  //   onSuccess: (data) => {
  //     toast.success("Attendance marked successfully");
  //     getAttendanceQuery.refetch();
  //   },
  // });

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      {/* Your content */}
      <DataTable
      />
    </main>
  );
}
