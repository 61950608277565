import { useQuery } from "@tanstack/react-query";
import DataTable from "./components/DataTable";
import {
  getAllDocsSummartApi,
} from "../../services/document.services";
import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { Menu, Transition, Dialog } from "@headlessui/react";
import { useUserStore } from "../../store/user";
import { documentStore } from "../../store/documents";
import BulkUploadDocModal from "./components/BulkUploadDocModal";
import SummaryCard from "./components/SummaryCard";
import AddDocModal from "./components/AddDocModal";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export default function DocumentPage() {
  const navigate = useNavigate();
  const setIsBulkDocumentUploadModalOpened = documentStore(
    (state) => state.setIsBulkDocumentUploadModalOpened
  );
  const userDetail = useUserStore((state) => state.userDetail);

  const [filteredDocs, setFilteredDocs] = useState({ limit: [5], page: [1] });
  const [addDocModal, setAddDocModal] = useState(false);
  const [cart, setCart] = useState([]);
  const [isShowCard, setIsShowCard] = useState(false);  
  const [currentSelectedDoc, setCurrentSelectedDoc] = useState("");

  const getAllDocsSummaryQuery = useQuery({
    queryKey: ["allDocsSummary"],
    retry: false,
    queryFn: () => getAllDocsSummartApi(),
    onSuccess: (data) => console.log("summart", data),
    onError: (error) => toast.error(error.message),
  });

  const handleCardClick = (type) => {
    let status = null;
    if (type === "total_authorized") {
      status = "AUTHORIZED";
    } else if (type === "total_checked") {
      status = "CHECKED";
    } else if (type === "total_pending") {
      status = "PENDING";
    }
    if (status) {
      setIsShowCard(true);
      setFilteredDocs({ ...filteredDocs, status: [status] });
    }
  };

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Documents
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the important documents at one place.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-4">
          <button
            type="button"
            className="relative block rounded-md border border-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => navigate("/documents/accounts/mismatch-summary")}
          >
            Summary
          </button>
          <button
            type="button"
            className="relative block rounded-md border border-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => navigate("/documents/approve")}
          >
            Requested Docs
          </button>
          <button
            type="button"
            className="relative block rounded-md border border-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() =>
              navigate("/documents/cart", {
                state: cart,
              })
            }
          >
            Cart
            {cart.length ? (
              <span className="absolute -top-2 -right-2 inline-flex items-center rounded-full bg-indigo-600 px-1.5 py-0.5 text-xs font-medium text-white">
                {cart.length}
              </span>
            ) : null}
          </button>
          {["MAKER", "A"].includes(userDetail.userType) && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button
                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 "
                  // onClick={() => setAddDocModal(true)}
                >
                  Add doc
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setAddDocModal(true)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          Single Doc
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            setIsBulkDocumentUploadModalOpened(true)
                          }
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm w-full text-left"
                          )}
                        >
                          Bulk Upload
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      </div>

      {/* Your content */}
      <SummaryCard
        getAllDocsSummaryQuery={getAllDocsSummaryQuery}
        onCardClick={handleCardClick}
        filteredDocs={filteredDocs}
      />
      {isShowCard ? (
        <DataTable
          filteredDocs={filteredDocs}
          setFilteredDocs={setFilteredDocs}
          setAddDocModal={setAddDocModal}
          setCart={setCart}
          setCurrentSelectedDoc={setCurrentSelectedDoc}
          isShowCard={isShowCard}
        />
      ) : (
        <div>
          <p className="text-center text-sm mt-8 text-gray-500">
            Select a card to view data
          </p>
        </div>
      )}

      <BulkUploadDocModal />
       {/* Add doc modal */}
       <AddDocModal
        open={addDocModal}
        setOpen={setAddDocModal}
        currentData={currentSelectedDoc}
        setCurrentSelectedDoc={setCurrentSelectedDoc}
      />
    </main>
  );
}
