import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const createAccessRequestApi = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/access-requests`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAccessRequestsApi = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/access-requests`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAccessRequestApi = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/access-requests/${id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateAccessRequestApi = async ({ id, data }) => {
  try {
    const res = await axios.put(`${BASE_URL}/access-requests/${id}`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const processAccessRequestApi = async ({ id, data }) => {
  try {
    const res = await axios.post(`${BASE_URL}/access-requests/${id}/process`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteAccessRequestApi = async (id) => {
  try {
    const res = await axios.delete(`${BASE_URL}/access-requests/${id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};