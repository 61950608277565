export default function Card({ label, value }) {
  return (
    <div className="flex border border-gray-300 rounded-sm bg-gray-100">
      <p className="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 border-r border-gray-300 ">
        {label}
      </p>
      <p className="flex-grow whitespace-nowrap px-3 py-2 text-sm text-gray-600 bg-gray-50 overflow-auto">
        {value}
      </p>
    </div>
  );
}
