import { useState } from "react";
import DeleteModal from "./DeleteModal";
import AddUserModal from "./AddUserModal";
import AssignRoleModal from "./AssignRoleModal";
import AttendancePopup from "../../AllAdminUsers/components/AttendancePopup";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { deleteUserApi } from "../../../services/user.services";
import { getUserRole, getRoleOptions } from "../../../utils/user";
import CustomSelect from "../../../components/Input/Select";
import { useGetAllUnitMasters } from "../../../http-hooks/useUnitMaster";
import { useGetAllUsers } from "../../../http-hooks/useUser";

export default function DataTable() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [unit, setUnit] = useState(null);
  const [role, setRole] = useState(null);
  const [filter, setFilter] = useState({
    user_type: null,
    unit: null,
    // startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    // endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const userType = localStorage.getItem("storeform-user-type");
  const { data, isLoading } = useGetAllUsers(filter);
  const { data: unitMasters, isLoading: isLoadingUnitMasters } =
    useGetAllUnitMasters();

  const deleteUserMutation = useMutation({
    mutationFn: deleteUserApi,
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("allusers");
      setCurrentSelectedUser(null);
      toast.success("User deleted successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their company pan
            number, logged in mobile number, business type and legal name.
          </p>
        </div>
        {userType === "A" ? (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setAddUserModalOpen(true);
                setCurrentSelectedUser(null);
              }}
            >
              Add user
            </button>
          </div>
        ) : null}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="flex gap-2">
              <div className="mt-2 mb-4">
                <CustomSelect
                  id="unit"
                  name="Unit"
                  value={unit}
                  isSmallHeight={false}
                  onChange={(selectedOption) => {
                    setUnit(selectedOption);
                    setFilter((prev) => {
                      return { ...prev, unit: selectedOption?.value };
                    });
                    setFilter((prev) => {
                      return { ...prev, unit: selectedOption?.value };
                    });
                  }}
                  options={
                    unitMasters
                      ? unitMasters.map((k) => {
                          return {
                            label: k.name,
                            value: k._id,
                          };
                        })
                      : []
                  }
                />
              </div>

              <div className="mt-2 mb-4">
                <CustomSelect
                  id="user_type"
                  name="Role"
                  value={role}
                  isSmallHeight={false}
                  onChange={(selectedOption) => {
                    setRole(selectedOption);
                    setFilter((prev) => {
                      return { ...prev, user_type: selectedOption?.value };
                    });
                  }}
                  options={getRoleOptions()}
                />
              </div>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {/* <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Personal
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Details
                    </th>
                    {userType !== "UM_BASIC" ? (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Login Mobile
                      </th>
                    ) : null}

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      User Name / Legal Name
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <p>loading...</p>
                  ) : (
                    data.map((user) => (
                      <tr key={user._id}>
                        {userType === "UM_BASIC" ? (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {user?.personal_details?.[0]?.user_photo ? (
                              <img
                                className="w-16"
                                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${user?.personal_details?.[0]?.user_photo}`}
                                alt=""
                              />
                            ) : null}
                          </td>
                        ) : null}

                        {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user.is_personal_user ? (
                            <TickIcon className="h-6 w-6" />
                          ) : (
                            <CrossIcon className="h-6 w-6" />
                          )}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col gap-2">
                            <span>
                              {getUserRole(
                                user.user_type,
                                user.is_personal_user
                              )}
                            </span>
                            {user.user_type === "UM_GUARD" ? (
                              <span>
                                ({user.start_shift} - {user.end_shift})
                              </span>
                            ) : null}
                            <span>
                              <b>{user?.unit?.name}</b>
                            </span>
                            {user.user_type === "SUM_STAFF" ? (
                              <span>
                                <b>({user.subUnit?.name})</b>
                              </span>
                            ) : null}
                          </div>
                        </td>
                        {userType !== "UM_BASIC" ? (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.loginMobile}
                          </td>
                        ) : null}

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user?.is_personal_user
                            ? user?.personal_details[0]?.name
                            : user.legal_name}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {userType === "UM_BASIC" ? (
                            <button
                              type="button"
                              className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                              onClick={() => {
                                setCurrentSelectedUser(user);
                                setAttendanceModalOpen(true);
                              }}
                            >
                              Mark Attendance
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                onClick={() => navigate(`/user/${user._id}`)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                onClick={() =>
                                  navigate(`/user-edit/${user._id}`)
                                }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                onClick={() => {
                                  setCurrentSelectedUser(user);
                                  setAddUserModalOpen(true);
                                }}
                              >
                                Assign
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                onClick={() => {
                                  setCurrentSelectedUser(user);
                                  setDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        handleDelete={() =>
          deleteUserMutation.mutate({ userId: currentSelectedUser?._id })
        }
      />

      {/* Add user modal */}
      {addUserModalOpen ? (
        <AddUserModal
          open={addUserModalOpen}
          onClose={(val) => {
            setAddUserModalOpen(val);
            setCurrentSelectedUser(null);
          }}
          user={currentSelectedUser}
        />
      ) : null}

      {/* Assign user role modal */}
      {assignModalOpen ? (
        <AssignRoleModal
          open={assignModalOpen}
          setOpen={setAssignModalOpen}
          user={currentSelectedUser}
        />
      ) : null}

      {/* Attendance Popup */}
      {attendanceModalOpen ? (
        <AttendancePopup
          open={attendanceModalOpen}
          setOpen={setAttendanceModalOpen}
          userId={currentSelectedUser?._id}
        />
      ) : null}
    </div>
  );
}

const TickIcon = (props) => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="#38ff4f"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <polyline points="2.75 8.75,6.25 12.25,13.25 4.75" />
    </g>
  </svg>
);

const CrossIcon = (props) => (
  <svg
    viewBox="0 -0.5 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ff2424"
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path
        d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
        fill="#ff2424"
      />
    </g>
  </svg>
);
