import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { getCurrentUserDetailApi } from "../../services/user.services";
import UserEdit from "./components";

// Type can be PROFILE/USER - If PROFILE - Then user can update his/her profile else admin can edit them
export default function ProfileEditPage() {
  const [userData, setUserData] = useState();

  const getUseDetailQuery = useQuery({
    queryKey: ["getCurrentUserDetail"],
    queryFn: async () => {
      return getCurrentUserDetailApi();
    },
    onSuccess: (data) => {
      console.log({ data });
      setUserData(data.data);
    },
    onError: (err) => toast.error(err.message),
  });

  return <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <h1 className="text-base font-semibold leading-6 text-gray-900 mb-8">
        User Profile Details
      </h1>
    {userData ? <UserEdit userData={userData} /> : null}</main>
}
