import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
    getUnitMastersApi
} from "../services/unit-master.services";

export const useGetAllUnitMasters = () => {
  return useQuery({
    queryKey: ["unit-masters"],
    queryFn: getUnitMastersApi,
    onError: (error) => {
      toast.error(error.message || "Failed to fetch admin users");
    }
  });
};

// export const useGetAdminUser = (userId) => {
//   return useQuery({
//     queryKey: ["admin-user", userId],
//     queryFn: () => getAdminUserApi(userId),
//     enabled: !!userId,
//     onError: (error) => {
//       toast.error(error.message || "Failed to fetch admin user");
//     }
//   });
// };

// export const useDeleteAdminUser = () => {
//   return useMutation({
//     mutationFn: deleteAdminUserApi,
//     onSuccess: () => {
//       toast.success("Admin user deleted successfully");
//     },
//     onError: (error) => {
//       toast.error(error.message || "Failed to delete admin user");
//     }
//   });
// };