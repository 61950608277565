import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAttendanceAnalytics } from "../../http-hooks/useAttendance";
import { useGetAllUnitMasters } from "../../http-hooks/useUnitMaster";
import CustomSelect from "../../components/Input/Select";
import { getUserRole } from "../../utils/user";
import Input from "../../components/Input/Input";
import moment from "moment";

const reportTypeOptions = [
  { label: "Daywise", value: "daywise" },
  { label: "Monthwise", value: "monthwise" },
];

const yearOptions = [
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

const monthOptions = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" },
];

function getMonthName(monthIndex) {
  return monthOptions[parseInt(monthIndex)].label;
}

const Badge = ({ children, type }) => {
  const typeMapping = {
    error: "text-red-700 bg-red-50 ring-red-600/10",
    warning: "text-green-700 bg-green-50 ring-green-600/10",
    success: "text-yellow-700 bg-yellow-50 ring-yellow-600/10",
  };
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset ${typeMapping[type]}`}
    >
      {children}
    </span>
  );
};
export default function AttendanceAnalytics() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = currentDate.getMonth().toString();

  const [unit, setUnit] = useState(null);
  const [reportType, setReportType] = useState(reportTypeOptions[1]);
  const [selectedYear, setSelectedYear] = useState({
    label: currentYear,
    value: currentYear,
  });
  const [selectedMonth, setSelectedMonth] = useState({
    label: getMonthName(currentMonth),
    value: currentMonth,
  });

  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    unit: null,
  });
  const { data, isLoading } = useAttendanceAnalytics(filter);
  const { data: unitMasters, isLoading: isLoadingUnitMasters } =
    useGetAllUnitMasters();

  useEffect(() => {
    if (reportType?.value === "monthwise" && selectedYear && selectedMonth) {
      const year = parseInt(selectedYear.value);
      const month = parseInt(selectedMonth.value);

      // Create Date objects for the first and last day of the selected month
      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);

      // Format dates as ISO strings and extract the date part
      const formattedStartDate = startDate.toLocaleDateString("en-CA"); // 'en-CA' gives YYYY-MM-DD format
      const formattedEndDate = endDate.toLocaleDateString("en-CA");

      setFilter((prev) => ({
        ...prev,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      }));
    }
  }, [reportType, selectedYear, selectedMonth]);

  // Set initial filter on component mount
  useEffect(() => {
    const year = parseInt(currentYear);
    const month = parseInt(currentMonth);

    // Create Date objects for the first and last day of the current month
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    // Format dates as ISO strings and extract the date part
    const formattedStartDate = startDate.toLocaleDateString("en-CA");
    const formattedEndDate = endDate.toLocaleDateString("en-CA");

    setFilter((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
  }, [currentMonth, currentYear]);

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Attendance Analytics
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Attendance analytics of a unit
          </p>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="flex gap-2">
              <div className="mt-2 mb-4">
                <div className="mt-4">
                  <CustomSelect
                    id="unit"
                    name="Unit"
                    value={unit}
                    isSmallHeight={false}
                    onChange={(selectedOption) => {
                      setUnit(selectedOption);
                      setFilter((prev) => {
                        return { ...prev, unit: selectedOption?.value };
                      });
                      setFilter((prev) => {
                        return { ...prev, unit: selectedOption?.value };
                      });
                    }}
                    options={
                      unitMasters
                        ? unitMasters.map((k) => {
                            return {
                              label: k.name,
                              value: k._id,
                            };
                          })
                        : []
                    }
                  />
                </div>
              </div>

              <div className="mt-2 mb-4">
                <div className="mt-4">
                  <CustomSelect
                    id="reportType"
                    name="Report Type"
                    value={reportType}
                    isSmallHeight={false}
                    onChange={(selectedOption) => {
                      setReportType(selectedOption);
                      if (selectedOption.value === "daywise") {
                        setFilter((prev) => ({
                          ...prev,
                          startDate: null,
                          endDate: null,
                        }));
                      } else {
                        // Reset to current month when switching back to monthwise
                        setSelectedYear({
                          label: currentYear,
                          value: currentYear,
                        });
                        setSelectedMonth({
                          label: getMonthName(currentMonth),
                          value: currentMonth,
                        });
                      }
                    }}
                    options={reportTypeOptions}
                  />
                </div>
              </div>

              {reportType?.value === "daywise" && (
                <div className="mt-2 mb-4">
                  <Input
                    type="date"
                    title="Date"
                    placeholder="Select date"
                    valKey="startDate"
                    inputValues={filter}
                    isDate={true}
                    setInputValues={(data) => {
                      setFilter((prev) => ({
                        ...prev,
                        startDate: data.startDate,
                        endDate: data.startDate,
                      }));
                    }}
                  />
                </div>
              )}

              {reportType?.value === "monthwise" && (
                <>
                  <div className="mt-2 mb-4">
                    <div className="mt-4">
                      <CustomSelect
                        id="year"
                        name="Year"
                        value={selectedYear}
                        isSmallHeight={false}
                        onChange={setSelectedYear}
                        options={yearOptions}
                      />
                    </div>
                  </div>
                  <div className="mt-2 mb-4">
                    <div className="mt-4">
                      <CustomSelect
                        id="month"
                        name="Month"
                        value={selectedMonth}
                        isSmallHeight={false}
                        onChange={setSelectedMonth}
                        options={monthOptions}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* <div className="mt-2 mb-4">
                <Input
                  type="date"
                  title="From Date"
                  placeholder="From date"
                  valKey="startDate"
                  inputValues={filter}
                  isDate={true}
                  setInputValues={(data) => {
                    console.log("fromDate", data);
                    setFilter((prev) => {
                      return {
                        ...prev,
                        startDate: data.startDate,
                      };
                    });
                  }}
                />
              </div>

              <div className="mt-2 mb-4">
                <Input
                  type="date"
                  title="To Date"
                  placeholder="To date"
                  valKey="endDate"
                  inputValues={filter}
                  isDate={true}
                  setInputValues={(data) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        endDate: data.endDate,
                      };
                    });
                  }}
                />
              </div> */}
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Photo
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Present
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Absent
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Total Days
                    </th>

                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Holidays
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Late
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      NG-24
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <p>loading...</p>
                  ) : (
                    data.map((user) => (
                      <tr key={user._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user?.employeePhoto ? (
                            <img
                              className="w-16"
                              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${user?.employeePhoto}`}
                              alt=""
                            />
                          ) : null}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user?.employeeName}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex flex-col gap-2">
                            <span>
                              {getUserRole(
                                user.user_type,
                                user.is_personal_user
                              )}
                            </span>
                            {user.user_type === "UM_GUARD" ? (
                              <span>
                                ({user.start_shift} - {user.end_shift})
                              </span>
                            ) : null}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.present}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.absent}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.totalDays}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.late}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Badge
                            type={
                              parseFloat(user["NG-24"]) < 50
                                ? "error"
                                : parseFloat(user["NG-24"]) < 80
                                ? "warning"
                                : "success"
                            }
                          >
                            {user["NG-24-Data"]["marked"]} / {user["NG-24-Data"]["expected"]}
                          </Badge>
                        </td>

                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          
                            <>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                // onClick={() => navigate(`/user/${user._id}`)}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                // onClick={() =>
                                //   navigate(`/user-edit/${user._id}`)
                                // }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                // onClick={() => {
                                //   setCurrentSelectedUser(user);
                                //   setAssignModalOpen(true);
                                // }}
                              >
                                Assign
                              </button>
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                // onClick={() => {
                                //   setCurrentSelectedUser(user);
                                //   setDeleteModalOpen(true);
                                // }}
                              >
                                Delete
                              </button>
                            </>
                         
                        </td> */}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
