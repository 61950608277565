import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  getOtpApi,
  verifyOtpApi,
} from "../../services/user.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BusinessLoginPage() {
  const navigate = useNavigate();
  const setToken = useUserStore((state) => state.setToken);
  const setUserDetail = useUserStore((state) => state.setUserDetail);
  
  const [showOTPField, setShowOTPField] = useState(false);

  const sendOtpMutation = useMutation({
    mutationFn: getOtpApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      setShowOTPField(true);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const verifyOtpMutation = useMutation({
    mutationFn: verifyOtpApi,
    onSuccess: ({data}) => {
      console.log(data);
      toast.success("OTP Verified");
      // toast.success(data.msg);
      setToken(data.token);
      setUserDetail({
        email: data.mobile,
        userType: data.type,
      });
      navigate("/");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSendOTP = (e) => {
    e.preventDefault();
    // Add logic to send OTP here
    sendOtpMutation.mutate({ mobile: e.target.mobile.value });
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    // Add logic to verify OTP here
    verifyOtpMutation.mutate({
      mobile: e.target.mobile.value,
      otp: e.target.otp.value,
    });
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-64 w-auto"
            src={logo}
            alt="Nutri Bake"
          />
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Business Login/Signup
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          
            <form
              className="space-y-6"
              onSubmit={(e) =>
                showOTPField ? handleVerifyOTP(e) : handleSendOTP(e)
              }
              method="POST"
            >
              {/* take mobile and otp */}
              <div>
                <label
                  htmlFor="mobile"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mobile
                </label>
                <div className="mt-2">
                  <input
                    id="mobile"
                    name="mobile"
                    type="text"
                    autoComplete="mobile"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                  />
                </div>

                {showOTPField && (
                  <>
                    {" "}
                    <label
                      htmlFor="otp"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-4"
                    >
                      OTP
                    </label>
                    <div className="mt-2">
                      <input
                        id="otp"
                        name="otp"
                        type="text"
                        autoComplete="otp"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                      />
                    </div>
                  </>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={
                    sendOtpMutation.isLoading || verifyOtpMutation.isLoading
                  }
                >
                  {sendOtpMutation.isLoading || verifyOtpMutation.isLoading
                    ? "Loading..."
                    : showOTPField
                    ? "Verify OTP"
                    : "Send OTP"}
                </button>
              </div>
            </form>
        </div>
      </div>
    </>
  );
}

export default BusinessLoginPage;
