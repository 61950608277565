import * as yup from "yup";

const rules = {
  gst: yup
    .string()
    .matches(/^.{15}$/, "GST Number must be 15-character long")
    .required("GST Number is required"),
  pan: yup
    .string()
    .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/, "Invalid PAN Card Number")
    .required("PAN Card Number is required"),
  trade: yup.string().required("Trade License Number is required"),
  aadhar: yup.string().required("Aadhar Number is required"),
  fsaai: yup
    .string()
    .matches(/^[0-9]{14}$/, "Invalid FSSAI License Number")
    .required("FSSAI License Number is required"),
};

const schema = yup.object(rules);

export const statutoryDetailValidate = async (values) => {
  try {
    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const statutoryDetailSingleValidate = async (fieldName, value) => {
  try {
    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};

const otpRules= {
 
  otp: yup.string().required("Otp is required"),
  
};

const otpSchema = yup.object(otpRules);


export const aadharOtpValidation = async (fieldName, value) => {
  try {
    await otpSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};