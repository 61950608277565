import { useGetAllUsers } from "../../http-hooks/useUser";
import DataTable from "./components/DataTable";
export default function HomePage() {
  
  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      {/* Your content */}
      <DataTable
      />
    </main>
  );
}