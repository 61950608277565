import { useUserDetailStore } from "../../../store/userDetail";
import { useMemo, useState } from "react";

export default function TopBar({
  currentComponentName,
  currentComponentIndex,
  components,
  setCurrentComponentIndex
}) {
  const totalSteps = components.length;
  const statutoryDetail = useUserDetailStore((state) => state.statutoryDetail);
  const personalDetail = useUserDetailStore((state) => state.personalDetail);
  const businessDetail = useUserDetailStore((state) => state.businessDetail);
  const bankDetail = useUserDetailStore((state) => state.bankDetail);
  const uploadDetail = useUserDetailStore((state) => state.uploadDetail);

  function calculateFilledPercentage(object) {
    const nonEmptyStringProperties = Object.values(object).filter(
      (value) => typeof value === "string" && value.trim() !== ""
    );

    const totalStringProperties = Object.values(object).filter(
      (value) => typeof value === "string"
    ).length;

    const percentage =
      (nonEmptyStringProperties.length / totalStringProperties) * 100;

    return {
      percentage: Math.floor(percentage),
      totalProperties: totalStringProperties,
    };
  }

  const { percentage } = useMemo(() => {
    if (currentComponentName === "statutory") {
      return calculateFilledPercentage(statutoryDetail);
    } else if (currentComponentName === "business") {
      return calculateFilledPercentage(businessDetail);
    } else if (currentComponentName === "bank") {
      return calculateFilledPercentage(bankDetail);
    } else if (currentComponentName === "personal") {
      return calculateFilledPercentage(personalDetail);
    } else if (currentComponentName === "upload") {
      return calculateFilledPercentage(uploadDetail);
    } else return 0, 5;
  }, [
    currentComponentName,
    statutoryDetail,
    personalDetail,
    businessDetail,
    bankDetail,
    uploadDetail,
  ]);

  return (
    <div>
      <div className="flex w-full gap-2 mb-2">
        {components.map((c, ci) => {
          return (
            <button
              onClick={() => setCurrentComponentIndex(ci)}
              key={c}
              className={`px-2 py-1.5 text-sm rounded-md border border-gray-300 ${
                currentComponentIndex === components.indexOf(c)
                  ? "text-indigo-600 bg-gray-200"
                  : "text-gray-500"
              } hover:text-indigo-600 hover:bg-gray-200 hover:border-indigo-600`}
            >
              {c.charAt(0).toUpperCase() + c.substring(1)}
            </button>
          );
        })}
      </div>
      <p>
        {currentComponentIndex + 1}/{totalSteps}: Step{" "}
        {currentComponentIndex + 1}
      </p>
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          className="h-2 rounded-full bg-indigo-600"
          style={{ width: percentage + "%" }}
        />
      </div>
    </div>
  );
}
