import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { getUserDetailApi } from "../../services/user.services";
import UserEdit from "./components";
import { useParams } from "react-router-dom";

// Type can be PROFILE/USER - If PROFILE - Then user can update his/her profile else admin can edit them
export default function ProfileEditPage() {
  const { id: userId } = useParams();
  const [userData, setUserData] = useState();

  const getUseDetailQuery = useQuery({
    queryKey: ["getUserDetail", userId],
    queryFn: async () => {
      return getUserDetailApi({ id: userId });
    },
    onSuccess: (data) => {
      setUserData(data.data);
    },
    onError: (err) => toast.error(err.message),
  });

  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-base font-semibold leading-6 text-gray-900 mb-4">
        Edit User Details
      </h1>
      {userData ? <UserEdit userData={userData} /> : null}
    </main>
  );
}
