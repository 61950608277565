import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const getSubUnitMastersApi = async (filter) => {
  try {
    let queryStringArr = []
    for(const filterKey in filter) {
      if(filter[filterKey] !== undefined && filter[filterKey] !== null) {
        queryStringArr.push(`${filterKey}=${filter[filterKey]}`)
      }
    }
    const queryParams = queryStringArr.join('&');
    
    const res = await axios.get(`${BASE_URL}/sub-unit-master?${queryParams}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getSubUnitMasterApi = async (id) => {
  try {
    const res = await axios.get(BASE_URL + `/sub-unit-master/${id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const createSubUnitMasterApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/sub-unit-master", data, {
      headers: {
        ...authHeader(),
      },
    });
    toast.success(res.data.msg)
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateSubUnitMasterApi = async ({ id, data }) => {
  try {
    const res = await axios.put(BASE_URL + `/sub-unit-master/${id}`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteSubUnitMasterApi = async ({ id }) => {
  try {
    const res = await axios.delete(BASE_URL + `/sub-unit-master/${id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
