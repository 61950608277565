import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useGeolocation } from "@uidotdev/usehooks";
import MapPinIcon from "../../assets/images/map-pointer.png"
const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 0,
  lng: 0,
};

// Custom marker icon
const customIcon = new L.Icon({
  iconUrl: MapPinIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function MapUpdater({ center }) {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
}

export default function SetMap({
  initialMapData,
  onSetMap = () => {},
  label = "Set Location",
  allowDragging = false,
  isGetCurrentLocation = true,
  isRenderMap = true,
}) {
  const [center, setCenter] = useState(defaultCenter);
  const [pin, setPin] = useState(null);

  const { loading: isLoading, latitude, longitude } = useGeolocation();

  useEffect(() => {
    if (initialMapData?.lat && initialMapData?.lng) {
      console.log({ initialMapData });
      setPin(initialMapData);
      setCenter(initialMapData);
    }
  }, [initialMapData]);

  useEffect(() => {
    if (!!latitude && !!longitude) {
      const currentLocation = {
        lat: latitude,
        lng: longitude,
      };
      setCenter(currentLocation);
      setPin(currentLocation);
      onSetMap(currentLocation);
    }
  }, [latitude, longitude]);

  const handleMarkerDrag = (e) => {
    const { lat, lng } = e.target.getLatLng();
    const data = { lat, lng };
    setPin(data);
    onSetMap(data);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-4">
      <label className="mt-4 block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      {isRenderMap ? (
        <MapContainer
          center={center}
          zoom={14}
          style={containerStyle}
          scrollWheelZoom={false}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <MapUpdater center={center} />
          {pin && (
            <Marker
              position={pin}
              icon={customIcon}
              draggable={allowDragging}
              eventHandlers={{
                dragend: handleMarkerDrag,
              }}
            />
          )}
        </MapContainer>
      ) : null}

      <div className="flex justify-between">
        {pin && (
          <div>
            <p>Latitude: {pin.lat.toFixed(6)}</p>
            <p>Longitude: {pin.lng.toFixed(6)}</p>
          </div>
        )}
      </div>
    </div>
  );
}