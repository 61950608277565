import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createPortal } from "react-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createUserApi } from "../../../services/user.services";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CustomSelect from "../../../components/Input/Select";
import Input from "../../../components/Input/Input";
import { getRoleOptions, getUserRole } from "../../../utils/user";
import { useGetAllSubUnitMasters } from "../../../http-hooks/useSubUnit";
import { useGetAllUnitMasters } from "../../../http-hooks/useUnitMaster";
import { useEditUserRole } from "../../../http-hooks/useUser";

const userTypes = [
  {
    value: "P",
    label: "Visitor",
  },
  {
    value: "E",
    label: "Employee",
  },
  {
    value: "B",
    label: "Business",
  },
];

const getUserTypeObject = (user_type, is_personal_user) => {
  if (!is_personal_user) return userTypes[2];

  if (user_type === "U") return userTypes[0];

  return userTypes[1];
};

/*
Personal Login - user_type = "U" and is_personal_user = true 
Employee Login - user_type = "UM_BASIC, UM_STAFF, SUM_STAFF, UM_GUARD" and is_personal_user = true 
Business Login - user_type = "U" and is_personal_user = false 
*/

export default function AddUserModal({ open, onClose, user }) {
  const queryClient = useQueryClient();

  const [userMobile, setUserMobile] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [userType, setUserType] = useState(null);
  const [unit, setUnit] = useState(null);
  const [subUnit, setSubUnit] = useState(null);
  const [shift, setShift] = useState({ start_shift: null, end_shift: null });

  const { data: unitMasters, isLoading: isLoadingUnitMasters } =
    useGetAllUnitMasters();

  const { isLoading: isLoadingSubUnits, data: subUnitsData } =
    useGetAllSubUnitMasters({ unit: unit?.value });

  const createUserMutation = useMutation({
    mutationFn: createUserApi,
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries("allusers");
      onClose(false);
      toast.success("User created successfully");
      // navigate(`/user-edit/${data.data._id}`);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const { mutate: editUserRoleMutate, isLoading: isLoadingEditUserRole } =
    useEditUserRole({
      onSuccess: (data) => {
        console.log(data);
        onClose(false);
        toast.success("User edited successfully");
        queryClient.invalidateQueries("allusers");
      },
    });

  const checkIfPersonalUser = () => {
    return ["E", "P"].includes(userType?.value);
  };

  const handleCreateUpdateUser = () => {
    if (user) {
      editUserRoleMutate({
        id: user._id,
        data: {
          mobile: userMobile?.phone,
          user_type: ["P", "B"].includes(userType?.value)
            ? "U"
            : userRole?.value,
          is_personal_user: checkIfPersonalUser(userRole?.value),
          unit: unit?.value,
          subUnit: subUnit?.value,
          start_shift: shift.start_shift,
          end_shift: shift.end_shift,
        },
      });
    } else {
      createUserMutation.mutate({
        mobile: userMobile?.phone,
        user_type: ["P", "B"].includes(userType?.value) ? "U" : userRole?.value,
        is_personal_user: checkIfPersonalUser(userRole?.value),
        unit: unit?.value,
        subUnit: subUnit?.value,
      });
    }
  };

  useEffect(() => {
    if (user) {
      setUserMobile({ phone: user.loginMobile });
      setUserType(getUserTypeObject(user.user_type, user.is_personal_user));
      setUserRole(getRoleOptions().find((r) => r.value === user.user_type));

      if (unitMasters && unitMasters.length) {
        const unitObject = unitMasters.find((u) => u._id === user.unit._id);
        if (unitObject) {
          setUnit({ label: unitObject.name, value: unitObject._id });
        }
      }

      if (subUnitsData && subUnitsData.length && user?.subUnit) {
        const subUnitObject = subUnitsData.find(
          (u) => u._id === user.subUnit._id
        );
        if (subUnitObject) {
          setSubUnit({ label: subUnitObject.name, value: subUnitObject._id });
        }
      }

      // Start Shift Time
      if (user?.start_shift) {
        setShift((prev) => {
          return {
            ...prev,
            start_shift: user.start_shift,
          };
        });
      }

      // End Shift Time
      if (user?.end_shift) {
        setShift((prev) => {
          return {
            ...prev,
            end_shift: user.end_shift,
          };
        });
      }
    }
  }, [user, unitMasters, subUnitsData]);

  return createPortal(
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-1 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {user ? "Update User" : "Create a new user"}
                    </Dialog.Title>
                    {!user ? (
                      <div className="mt-2 mb-4">
                        <Input
                          placeholder="Enter mobile number"
                          title="Mobile Number"
                          valKey="phone"
                          inputValues={userMobile}
                          setInputValues={(data) => setUserMobile(data)}
                          type="text"
                        />
                      </div>
                    ) : null}

                    <div className="mt-2 mb-4">
                      <CustomSelect
                        id="UserType"
                        name="User Type"
                        value={userType}
                        onChange={(selectedOption) => {
                          setUserType(selectedOption);
                        }}
                        options={userTypes}
                      />
                    </div>

                    {userType?.value === "E" ? (
                      <>
                        <div className="mt-2 mb-4">
                          <CustomSelect
                            id="role"
                            name="Role"
                            value={userRole}
                            onChange={(selectedOption) =>
                              setUserRole(selectedOption)
                            }
                            options={getRoleOptions().filter(
                              (o) => o.value !== "U"
                            )}
                          />
                        </div>

                        <div className="mt-2 mb-4">
                          <CustomSelect
                            id="unit"
                            name="Unit"
                            value={unit}
                            onChange={(selectedOption) => {
                              setUnit(selectedOption);
                              setSubUnit(null);
                            }}
                            options={
                              unitMasters
                                ? unitMasters.map((k) => {
                                    return {
                                      label: k.name,
                                      value: k._id,
                                    };
                                  })
                                : []
                            }
                          />
                        </div>

                        {userRole?.value === "SUM_STAFF" ? (
                          <div className="mt-2 mb-4">
                            <CustomSelect
                              id="subunit"
                              name="Sub-Unit"
                              value={subUnit}
                              onChange={(selectedOption) =>
                                setSubUnit(selectedOption)
                              }
                              options={
                                subUnitsData
                                  ? subUnitsData.map((k) => {
                                      return {
                                        label: k.name,
                                        value: k._id,
                                      };
                                    })
                                  : []
                              }
                            />
                          </div>
                        ) : null}

                        <div className="mt-2 mb-4">
                          <Input
                            placeholder="Enter shift start time"
                            title="Shift Start"
                            valKey="start_shift"
                            inputValues={shift}
                            setInputValues={(data) =>
                              setShift((prev) => {
                                return {
                                  ...prev,
                                  start_shift: data.start_shift,
                                };
                              })
                            }
                            type="time"
                          />

                          <Input
                            placeholder="Enter shift end time"
                            title="Shift End"
                            valKey="end_shift"
                            inputValues={shift}
                            setInputValues={(data) =>
                              setShift((prev) => {
                                return { ...prev, end_shift: data.end_shift };
                              })
                            }
                            type="time"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="mt-[100px]">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => handleCreateUpdateUser()}
                    disabled={createUserMutation.isLoading}
                  >
                    {user
                      ? isLoadingEditUserRole
                        ? "Updating..."
                        : "Update user"
                      : createUserMutation.isLoading
                      ? "Creating..."
                      : "Create user"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>,
    document.getElementById("delete-modal")
  );
}
