export const getRoleOptions = (is_admin = false) => {
  let roles = [
    { value: "A", label: "Admin" },
    { value: "U", label: "Visitor" },
    { value: "MAKER", label: "Maker" },
    { value: "CHECKER", label: "Checker" },
    { value: "AUTHORIZER", label: "Authorizer" },
    { value: "UM_STAFF", label: "Unit Admin" },
    { value : "UM_BASIC", label : "Unit Basic" },
    { value: "SUM_STAFF", label: "Sub Unit Admin" },
    { value: "UM_GUARD", label: "Guard" },
    { value: "STAFF", label: "Staff" },
  ];

  if (!is_admin) {
    roles = roles.filter((role) => role.value !== "A");
  }

  return roles;
};

export const getRoleMapping = () => {
  return {
    A: "Admin",
    U: "Visitor",
    MAKER: "Maker",
    CHECKER: "Checker",
    AUTHORIZER: "Authorizer",
    UM_STAFF: "Unit Admin",
    UM_BASIC: "Unit Basic",
    SUM_STAFF: "Sub Unit Admin",
    UM_GUARD: "Guard",
    STAFF: "Staff",
  };
};

export const getUserRole = (user_type, is_personal_user) => {
  if(!is_personal_user) return "Business"

  return getRoleMapping()[user_type]
}

export const maskAdhaarNumber = (number) => {
  // Convert the number to a string
  let numStr = number.toString();

  // Get the length of the string
  let length = numStr.length;

  // If the number has 4 or fewer digits, return it as is
  if (length <= 4) {
    return numStr;
  }

  // Get the first two and last two digits
  let firstTwo = numStr.slice(0, 2);
  let lastTwo = numStr.slice(-2);

  // Create a string of 'X' characters for the middle part
  let maskedPart = "X".repeat(length - 4);

  // Combine the parts and return
  return `${firstTwo}${maskedPart}${lastTwo}`;
}
