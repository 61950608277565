import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const getAllAdminUsersApi = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/admin-users`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAdminUserApi = async (userId) => {
  try {
    const res = await axios.get(`${BASE_URL}/admin-users/${userId}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteAdminUserApi = async (userId) => {
  try {
    const res = await axios.delete(`${BASE_URL}/admin-users/${userId}`, {
      headers: {
        ...authHeader(),
      },
    });
    toast.success("Admin user deleted successfully");
    return res.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};