import { useState, useMemo } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Input from "../../../components/Input/Input";
import { useUserDetailStore } from "../../../store/userDetail";
import {
  aadharOtpValidation,
  personalDetailSingleValidate,
} from "../../../validation/personalValidation";
import { Switch } from "@headlessui/react";
import FileInput from "../../../components/Input/FileInput";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Button from "../../../components/Button/Button";
import {
  getAadharOtpApi,
  verifyAadharOtpApi,
  verifyRcApi,
  verifyVoterApi,
} from "../../../services/user.services";
import CustomSelect from "../../../components/Input/Select";
import SetMap from "../../../components/Maps/SetMap";
import stateOptions from "../../../utils/data/state_data.json";
import { useUserStore } from "../../../store/user";
import { maskAdhaarNumber } from "../../../utils/user";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const userType = localStorage.getItem("storeform-user-type");
export default function PersonalDetail({
  userDetail,
  userId,
  fieldsToDisplay,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPersonalDetail, setCurrentPersonalDetail] = useState(null);
  const { userType } = useUserStore((state) => state.userDetail);

  const personalDetail = useUserDetailStore((state) => state.personalDetail);
  const setPersonalDetail = useUserDetailStore(
    (state) => state.setPersonalDetail
  );
  const personalError = useUserDetailStore((state) => state.personalError);
  const setPersonalError = useUserDetailStore(
    (state) => state.setPersonalError
  );

  const [isOtpInputVisible, setIsOtpInputVisible] = useState(
    userDetail?.personal_details?.map((detail) => false)
  );
  const [otpObj, setOtpObj] = useState({
    otp: "",
  });
  const [otpError, setOtpError] = useState({
    otp: "",
  });

  const setCustomPersonalDetail = (data, currentTab) => {
    const updatedData = [...personalDetail];
    updatedData[currentTab] = data;
    setPersonalDetail(updatedData);
  };

  // ------------------- Aadhar otp -------------------
  const getAadharOtpMutation = useMutation({
    mutationFn: getAadharOtpApi,
    onSuccess: (data) => {
      setIsOtpInputVisible(
        isOtpInputVisible.map((item, index) =>
          index === currentTab ? true : item
        )
      );
      toast.success(data.msg);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleGetOtp = (aadhaar_number) => {
    getAadharOtpMutation.mutate({
      data: {
        aadhaar_number: aadhaar_number,
        consent: "Y",
      },
      userId,
    });
  };

  // ------------------- Aadhar otp verification -------------------
  const verifyAadharOtpMutation = useMutation({
    mutationFn: verifyAadharOtpApi,
    onSuccess: (data) => {
      setIsOtpInputVisible(
        isOtpInputVisible.map((item, index) =>
          index === currentTab ? false : item
        )
      );
      const updatedPersonalDetails = [...personalDetail];
      const currentIndex = updatedPersonalDetails.findIndex(
        (detail) => detail._id == data.data._id
      );
      const filledObj = Object.fromEntries(
        Object.keys(updatedPersonalDetails[currentIndex]).map((key) => [
          key,
          data.data[key] || "",
        ])
      );
      updatedPersonalDetails[currentIndex] = filledObj;
      setPersonalDetail(updatedPersonalDetails);
      toast.success(data.msg);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleVerfiyOtp = (user_detail_id, aadhaar_number) => {
    verifyAadharOtpMutation.mutate({
      data: {
        aadhaar_number: aadhaar_number,
        otp: otpObj.otp,
        transaction_id: getAadharOtpMutation.data.data,
        user_detail_id,
      },
      userId,
    });
  };

  // ------------------- Voter verification -------------------
  const voterDetailMutation = useMutation({
    mutationFn: verifyVoterApi,
    onSuccess: (data) => {
      console.log("data", data.data);
      const updatedPersonalDetails = [...personalDetail];
      const currentIndex = updatedPersonalDetails.findIndex(
        (detail) => detail._id == data.data._id
      );
      const filledObj = Object.fromEntries(
        Object.keys(updatedPersonalDetails[currentIndex]).map((key) => [
          key,
          data.data[key] || "",
        ])
      );
      updatedPersonalDetails[currentIndex] = filledObj;
      setPersonalDetail(updatedPersonalDetails);
      toast.success("Voter verified successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleVoterVerification = (voterNo, detailId) => {
    voterDetailMutation.mutate({
      data: {
        voter_id: voterNo,
        consent: "Y",
        user_detail_id: detailId,
      },
      userId,
    });
  };

  // ------------------- RC verification -------------------
  const rcDetailMutation = useMutation({
    mutationFn: verifyRcApi,
    onSuccess: (data) => {
      console.log("data", data.data);
      const updatedPersonalDetails = [...personalDetail];
      const currentIndex = updatedPersonalDetails.findIndex(
        (detail) => detail._id == data.data._id
      );
      const filledObj = Object.fromEntries(
        Object.keys(updatedPersonalDetails[currentIndex]).map((key) => [
          key,
          data.data[key] || "",
        ])
      );
      updatedPersonalDetails[currentIndex] = filledObj;
      setPersonalDetail(updatedPersonalDetails);
      toast.success("RC verified successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleRcVerification = (rcNo, detailId) => {
    rcDetailMutation.mutate({
      data: {
        rc_number: rcNo,
        consent: "Y",
        // extract_variant: true,
        // extract_mapping: true,
        user_detail_id: detailId,
      },
      userId,
    });
  };

  console.log({personalDetail})

  const multiplePersonalDetails = useMemo(() => 
    userType === "U"
      ? [userDetail?.personal_details[0]]
      : userDetail?.personal_details,
    [userType, userDetail?.personal_details]
  );

  console.log("dffs", personalDetail);
  const renderField = (fieldName) => {
    if (fieldsToDisplay && !fieldsToDisplay.includes(fieldName)) {
      return null;
    }

    switch (fieldName) {
      case "loginMobile":
        return (
          <Input
            placeholder={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            title={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            valKey={fieldName}
            inputValues={userDetail}
            setInputValues={(data) => {
              setCustomPersonalDetail(data, currentTab);
            }}
            singleFieldValidation={personalDetailSingleValidate}
            setFormErrors={(data) => setPersonalError(data, currentTab)}
            formErrors={personalError[currentTab]}
            disabled={true}
            isTextArea={false}
            type={"text"}
          />
        );
      case "aadhar":
        return (
          <>
            <Input
              placeholder="Aadhar number"
              title="Aadhar No"
              valKey="aadhar"
              inputValues={personalDetail[currentTab]}
              setInputValues={(data) =>
                setCustomPersonalDetail(data, currentTab)
              }
              displayFunction={maskAdhaarNumber}
              singleFieldValidation={personalDetailSingleValidate}
              setFormErrors={(data) => setPersonalError(data, currentTab)}
              formErrors={personalError[currentTab]}
              disabled={personalDetail[currentTab].is_aadhar_verified >= 1}
              isMasked={personalDetail[currentTab].is_aadhar_verified >= 1}
            />
            {isOtpInputVisible[currentTab] && (
              <Input
                placeholder="Enter otp here"
                title="Aadhar Otp"
                valKey="otp"
                inputValues={otpObj}
                setInputValues={setOtpObj}
                singleFieldValidation={aadharOtpValidation}
                setFormErrors={setOtpError}
                formErrors={otpError}
              />
            )}
            <div className="flex items-center justify-between gap-2 mt-1">
              {personalDetail[currentTab].is_aadhar_verified >= 1 ? (
                <p className="text-xs text-green-800">
                  Your aadhar number is secured with us
                </p>
              ) : (
                <p></p>
              )}
              <div className="flex gap-2">
                {isOtpInputVisible[currentTab] && (
                  <Button
                    isLoading={getAadharOtpMutation.isLoading}
                    onClick={() =>
                      setIsOtpInputVisible(
                        isOtpInputVisible.map((item, index) =>
                          index === currentTab ? false : item
                        )
                      )
                    }
                    title="Retry"
                  />
                )}
                <Button
                  isLoading={
                    getAadharOtpMutation.isLoading ||
                    verifyAadharOtpMutation.isLoading
                  }
                  onClick={
                    personalDetail[currentTab].is_aadhar_verified >= 1
                      ? () => null
                      : isOtpInputVisible[currentTab]
                      ? () =>
                          handleVerfiyOtp(
                            personalDetail[currentTab]._id,
                            personalDetail[currentTab].aadhar
                          )
                      : () => handleGetOtp(personalDetail[currentTab].aadhar)
                  }
                  title={
                    personalDetail[currentTab].is_aadhar_verified > 0
                      ? personalDetail[currentTab].is_aadhar_verified >= 1
                        ? "Validated"
                        : isOtpInputVisible[currentTab]
                        ? "Verify Otp"
                        : "Verify Aadhar again"
                      : isOtpInputVisible[currentTab]
                      ? "Verify Otp"
                      : "Send Otp"
                  }
                  disabled={
                    personalDetail[currentTab]?.aadhar === "" ||
                    personalError[currentTab]?.aadhar
                  }
                />
              </div>
            </div>
          </>
        );
      case "voter":
        return (
          <>
            <Input
              placeholder="Voter number"
              title="Voter No"
              valKey="voter"
              inputValues={personalDetail[currentTab]}
              displayFunction={maskAdhaarNumber}
              setInputValues={(data) =>
                setCustomPersonalDetail(data, currentTab)
              }
              singleFieldValidation={personalDetailSingleValidate}
              setFormErrors={(data) => setPersonalError(data, currentTab)}
              formErrors={personalError[currentTab]}
              disabled={personalDetail[currentTab].is_voter_verified >= 1}
              isMasked={personalDetail[currentTab].is_voter_verified >= 1}
            />
            <div className="flex items-center justify-between gap-2 mt-1">
              {personalDetail[currentTab].is_voter_verified >= 1 ? (
                <p className="text-xs text-green-800">
                  Your voter number is secured with us
                </p>
              ) : (
                <p></p>
              )}
              <Button
                isLoading={voterDetailMutation.isLoading}
                onClick={
                  personalDetail[currentTab].is_voter_verified >= 1
                    ? () => null
                    : () =>
                        handleVoterVerification(
                          personalDetail[currentTab].voter,
                          personalDetail[currentTab]._id
                        )
                }
                title={
                  personalDetail[currentTab].is_voter_verified > 0
                    ? personalDetail[currentTab].is_voter_verified >= 1
                      ? "Validated"
                      : "Verify Voter again"
                    : "Verify Voter"
                }
                disabled={
                  personalDetail[currentTab].voter === "" ||
                  personalError[currentTab].voter
                }
              />
            </div>
          </>
        );
      case "dob":
      case "name":
      case "mobile":
      case "whatsapp":
      case "email":
      case "father":
      case "house":
      case "street":
      case "district":
      case "sub_district":
      case "landmark":
      case "locality":
      case "post_office":
      case "pincode":
        return (
          <Input
            placeholder={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            title={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
            valKey={fieldName}
            inputValues={personalDetail[currentTab]}
            setInputValues={(data) => {
              setCustomPersonalDetail(data, currentTab);
            }}
            singleFieldValidation={personalDetailSingleValidate}
            setFormErrors={(data) => setPersonalError(data, currentTab)}
            formErrors={personalError[currentTab]}
            disabled={false}
            // disabled={((personalDetail[currentTab].is_aadhar_verified >= 1) && (personalDetail[currentTab][fieldName] && personalDetail[currentTab][fieldName].toString().trim().length))}
            isTextArea={["house", "street", "landmark"].includes(fieldName)}
            type={fieldName === "dob" ? "date" : "text"}
          />
        );
      case "exact_location":
        return (
          <SetMap
            allowDragging={true}
            initialMapData={personalDetail[currentTab]["exact_location"]}
            onSetMap={(data) =>
              setCustomPersonalDetail(
                {
                  ...personalDetail[currentTab],
                  exact_location: data,
                },
                currentTab
              )
            }
          />
        );
      case "gender":
        const genderOptions = [
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
          { label: "Other", value: "other" },
        ];

        const currentGenderObject =
          genderOptions.find(
            (option) =>
              option.value.toLowerCase() ===
              personalDetail[currentTab].gender.toLowerCase()
          ) || null;

        // console.log({gender : personalDetail[currentTab].gender })

        return (
          <>
            <div className="mt-4"></div>
            <CustomSelect
              id="gender"
              name="Gender"
              value={currentGenderObject}
              onChange={(selectedOption) =>
                setCustomPersonalDetail(
                  {
                    ...personalDetail[currentTab],
                    gender: selectedOption.value,
                  },
                  currentTab
                )
              }
              options={genderOptions}
              disabled={personalDetail[currentTab].is_aadhar_verified >= 1}
            />
          </>
        );
      case "state":
        const currentStateObject =
          stateOptions.find(
            (option) =>
              option.value.toLowerCase() ===
                personalDetail[currentTab].state.toLowerCase() ||
              option.label.toLowerCase() ===
                personalDetail[currentTab].state.toLowerCase()
          ) || null;

        return (
          <>
            <div className="mt-4"></div>
            <CustomSelect
              id="state"
              name="State"
              value={currentStateObject}
              onChange={(selectedOption) =>
                setCustomPersonalDetail(
                  {
                    ...personalDetail[currentTab],
                    state: selectedOption.value,
                  },
                  currentTab
                )
              }
              options={stateOptions}
              disabled={personalDetail[currentTab].is_aadhar_verified >= 1}
            />
          </>
        );
      case "is_vehicle":
        return (
          <Switch.Group
            as="div"
            className="flex items-center justify-between mt-8"
          >
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6 text-gray-900"
                passive
              >
                Do you have vehicle or not?
              </Switch.Label>
            </span>
            <Switch
              checked={personalDetail[currentTab]["is_vehicle"] === "Yes"}
              onChange={(selectedItem) => {
                setCustomPersonalDetail(
                  {
                    ...personalDetail[currentTab],
                    is_vehicle: selectedItem ? "Yes" : "No",
                  },
                  currentTab
                );
              }}
              className={classNames(
                personalDetail[currentTab]["is_vehicle"] === "Yes"
                  ? "bg-indigo-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  personalDetail[currentTab]["is_vehicle"] === "Yes"
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </Switch.Group>
        );
      case "rc":
        return (
          personalDetail[currentTab]["is_vehicle"] === "Yes" && (
            <>
              <Input
                placeholder="RC number"
                title="RC No"
                valKey="rc"
                inputValues={personalDetail[currentTab]}
                setInputValues={(data) =>
                  setCustomPersonalDetail(data, currentTab)
                }
                singleFieldValidation={personalDetailSingleValidate}
                setFormErrors={(data) => setPersonalError(data, currentTab)}
                formErrors={personalError[currentTab]}
                disabled={personalDetail[currentTab].is_rc_verified >= 1}
                isMasked={personalDetail[currentTab].is_rc_verified >= 1}
              />
              <div className="flex items-center justify-between gap-2 mt-1">
                {personalDetail[currentTab].is_rc_verified >= 1 ? (
                  <p className="text-xs text-green-800">
                    Your RC number is secured with us
                  </p>
                ) : (
                  <p></p>
                )}
                <Button
                  isLoading={rcDetailMutation.isLoading}
                  onClick={
                    personalDetail[currentTab].is_rc_verified >= 1
                      ? () => null
                      : () =>
                          handleRcVerification(
                            personalDetail[currentTab].rc,
                            personalDetail[currentTab]._id
                          )
                  }
                  title={
                    personalDetail[currentTab].is_rc_verified > 0
                      ? personalDetail[currentTab].is_rc_verified >= 1
                        ? "Validated"
                        : "Verify RC again"
                      : "Verify RC"
                  }
                />
              </div>
            </>
          )
        );
      case "fb_url":
      case "twitter_url":
      case "instagram_url":
      case "linkedin_url":
        return (
          <div>
            <Input
              placeholder={`${
                fieldName.split("_")[0].charAt(0).toUpperCase() +
                fieldName.split("_")[0].slice(1)
              } link`}
              title={`${
                fieldName.split("_")[0].charAt(0).toUpperCase() +
                fieldName.split("_")[0].slice(1)
              } Link`}
              valKey={fieldName}
              inputValues={personalDetail[currentTab]}
              setInputValues={(data) =>
                setCustomPersonalDetail(data, currentTab)
              }
              singleFieldValidation={personalDetailSingleValidate}
              setFormErrors={(data) => setPersonalError(data, currentTab)}
              formErrors={personalError[currentTab]}
            />
            {fieldName === "linkedin_url" ? (
              <hr className="mt-5 border-gray-300" />
            ) : null}
          </div>
        );
      case "user_photo":
        return (
          <>
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
              Personal Photo
            </p>
            <FileInput
              valKey="user_photo"
              inputValues={personalDetail[currentTab]}
              setInputValues={(data) =>
                setCustomPersonalDetail(data, currentTab)
              }
              isProfile={true}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {!userDetail?.is_personal_user ? (
        <>
          <div className="sm:hidden mt-8">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              // defaultValue={userDetail?.personal_details[0]?.personal_type}
              onChange={(e) => {
                setCurrentTab(e.target.value);
                setCurrentPersonalDetail(
                  userDetail?.personal_details[e.target.value]
                );
              }}
              // defaultValue={tabs.find((tab) => tab.current).name}
            >
              {multiplePersonalDetails?.map((personalDetail, currentTab) => (
                <option key={personalDetail._id} value={currentTab}>
                  {personalDetail.personal_type}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block mt-8">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
            >
              {multiplePersonalDetails?.map((personalDetail, index) => (
                <div
                  key={personalDetail._id}
                  onClick={() => {
                    setCurrentTab(index);
                    setCurrentPersonalDetail(personalDetail);
                  }}
                  className={classNames(
                    currentTab === index
                      ? "bg-gray-200 text-gray-800"
                      : "text-gray-600 hover:text-gray-800",
                    "px-3 py-2 text-sm font-medium cursor-pointer flex-1 text-center"
                  )}
                  // aria-current={tab.current ? 'page' : undefined}
                >
                  {userType === "U"
                    ? "Personal Details"
                    : personalDetail.personal_type}
                </div>
              ))}
            </nav>
          </div>
        </>
      ) : null}

      <div className="mt-4 rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-blue-400"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              We'd like to collect some information about your system to
              personalize your experience. This data will be used to send you
              custom greetings and well-wishes. Your privacy is important to us
              - this information will be visible only to you and not shared with
              anyone else.
            </p>
          </div>
        </div>
      </div>

      {(
        fieldsToDisplay || [
          "loginMobile",
          "aadhar",
          "voter",
          "name",
          "dob",
          "gender",
          "mobile",
          "whatsapp",
          "email",
          "house",
          "street",
          "district",
          "sub_district",
          "landmark",
          "locality",
          "post_office",
          "pincode",
          "state",
          "exact_location",
          "is_vehicle",
          "rc",
          "fb_url",
          "twitter_url",
          "instagram_url",
          "linkedin_url",
          "user_photo",
        ]
      ).map((fieldName) => renderField(fieldName))}
    </div>
  );
}
