import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
  userLoginApi,
  createUserApi,
  editUserRoleApi,
  getAllUserDataApi,
  getUserDetailApi,
  getAdminuserDetailApi,
  getCurrentUserDetailApi,
  updateUserDetailApi,
  fetchGstDetailApi,
  fetchPanDetailApi,
  verifyBankApi,
  getAadharOtpApi,
  verifyAadharOtpApi,
  verifyVoterApi,
  verifyRcApi,
  deleteUserApi,
  getOtpApi,
  verifyOtpApi,
  updateAdminUserDetailApi
} from "../services/user.services"; // Assuming this is the correct path

// User Login Hook
export const useUserLogin = () => {
  return useMutation({
    mutationFn: userLoginApi,
    onSuccess: (data) => {
      toast.success("Login successful");
    },
    onError: (error) => {
      toast.error(error.message || "Login failed");
    }
  });
};

// Create User Hook
export const useCreateUser = () => {
  return useMutation({
    mutationFn: createUserApi,
    onSuccess: () => {
      toast.success("User created successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to create user");
    }
  });
};

// Edit User Role Hook
export const useEditUserRole = ({onSuccess}) => {
  return useMutation({
    mutationFn: ({ id, data }) => editUserRoleApi(id, data),
    onSuccess: onSuccess,
    onError: (error) => {
      toast.error(error.message || "Failed to update user role");
    }
  });
};

// Get All Users Hook
export const useGetAllUsers = (filter={}, enabled=true) => {
  return useQuery({
    queryKey: ["allusers", filter],
    queryFn: () => getAllUserDataApi(filter),
    enabled : enabled,
    onError: (error) => {
      toast.error(error.message || "Failed to fetch users");
    }
  });
};

// Get User Detail Hook
export const useGetUserDetail = (userId) => {
  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUserDetailApi({ id: userId }),
    enabled: !!userId,
    onError: (error) => {
      toast.error(error.message || "Failed to fetch user details");
    }
  });
};

// Get Admin User Detail Hook
export const useGetAdminUserDetail = () => {
  return useQuery({
    queryKey: ["adminUser"],
    queryFn: getAdminuserDetailApi,
    onError: (error) => {
      toast.error(error.message || "Failed to fetch admin user details");
    }
  });
};

// Get Current User Detail Hook
export const useGetCurrentUserDetail = () => {
  return useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUserDetailApi,
    onError: (error) => {
      toast.error(error.message || "Failed to fetch current user details");
    }
  });
};

// Update User Detail Hook
export const useUpdateUserDetail = () => {
  return useMutation({
    mutationFn: updateUserDetailApi,
    onSuccess: () => {
      toast.success("User details updated successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to update user details");
    }
  });
};

// Fetch GST Detail Hook
export const useFetchGstDetail = () => {
  return useMutation({
    mutationFn: fetchGstDetailApi,
    onSuccess: () => {
      toast.success("GST details fetched successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to fetch GST details");
    }
  });
};

// Fetch PAN Detail Hook
export const useFetchPanDetail = () => {
  return useMutation({
    mutationFn: fetchPanDetailApi,
    onSuccess: () => {
      toast.success("PAN details fetched successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to fetch PAN details");
    }
  });
};

// Verify Bank Hook
export const useVerifyBank = () => {
  return useMutation({
    mutationFn: verifyBankApi,
    onSuccess: () => {
      toast.success("Bank verified successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to verify bank");
    }
  });
};

// Get Aadhar OTP Hook
export const useGetAadharOtp = () => {
  return useMutation({
    mutationFn: getAadharOtpApi,
    onSuccess: () => {
      toast.success("Aadhar OTP sent successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to send Aadhar OTP");
    }
  });
};

// Verify Aadhar OTP Hook
export const useVerifyAadharOtp = () => {
  return useMutation({
    mutationFn: verifyAadharOtpApi,
    onSuccess: () => {
      toast.success("Aadhar OTP verified successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to verify Aadhar OTP");
    }
  });
};

// Verify Voter Hook
export const useVerifyVoter = () => {
  return useMutation({
    mutationFn: verifyVoterApi,
    onSuccess: () => {
      toast.success("Voter details verified successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to verify voter details");
    }
  });
};

// Verify RC Hook
export const useVerifyRc = () => {
  return useMutation({
    mutationFn: verifyRcApi,
    onSuccess: () => {
      toast.success("RC details verified successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to verify RC details");
    }
  });
};

// Delete User Hook
export const useDeleteUser = () => {
  return useMutation({
    mutationFn: deleteUserApi,
    onSuccess: () => {
      toast.success("User deleted successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to delete user");
    }
  });
};

// Get OTP Hook
export const useGetOtp = () => {
  return useMutation({
    mutationFn: getOtpApi,
    onSuccess: () => {
      toast.success("OTP sent successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to send OTP");
    }
  });
};

// Verify OTP Hook
export const useVerifyOtp = () => {
  return useMutation({
    mutationFn: verifyOtpApi,
    onSuccess: () => {
      toast.success("OTP verified successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to verify OTP");
    }
  });
};

// Update Admin User Detail Hook
export const useUpdateAdminUserDetail = () => {
  return useMutation({
    mutationFn: updateAdminUserDetailApi,
    onSuccess: () => {
      toast.success("Admin user details updated successfully");
    },
    onError: (error) => {
      toast.error(error.message || "Failed to update admin user details");
    }
  });
};