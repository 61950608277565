import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CustomSelect from "../../../components/Input/Select";
import FileInput from "../../../components/Input/FileInput";
import Input from "../../../components/Input/Input";
import { useUserStore } from "../../../store/user";
import {
  uploadDocSingleValidate,
  uploadDocValidate,
} from "../../../validation/uploadDocValidation";
import dummyData from "../data.json";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getDocApi,
  getDocRequestApi,
  updateDocApi,
  uploadDocApi,
} from "../../../services/document.services";
import { toast } from "react-hot-toast";
import { useGetAllUsers } from "../../../http-hooks/useUser";
const dummyDocData = dummyData.data;

export default function AddDocModal({
  open,
  setOpen,
  currentData,
  setCurrentSelectedDoc,
  isView = false,
  isDocRequest = false,
}) {
  const queryClient = useQueryClient();
  const userDetail = useUserStore((state) => state.userDetail);
  const {data : users, isLoading : isUsersLoading} = useGetAllUsers()

  const [formValues, setFormValues] = useState({
    filepath: "",
    doc_group: "",
    doc_type: "",
    doc_for: "",
    reminder_date: "",
    status: "",
  });
  const [formError, setFormError] = useState({
    doc_group: null,
    doc_type: null,
    doc_for: null,
    reminder_date: null,
    filepath: null,
  });

  const handleFormChange = async (value, label) => {
    // const { isValid, errors } = await uploadDocSingleValidate(label, value.value);
    // if (!isValid) {
    //   setFormError((prev) => ({
    //     ...prev,
    //     [label]: errors[label],
    //   }));
    //   return;
    // }
    setFormValues((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  const createDocMutation = useMutation({
    mutationFn: uploadDocApi,
    onSuccess: (data) => {
      console.log(data);
      toast.success(data.msg);
      setOpen(false);
      queryClient.invalidateQueries("allDocs");
      // navigate(`/user-edit/${data.data._id}`);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const updateDocMutation = useMutation({
    mutationFn: updateDocApi,
    onSuccess: (data) => {
      console.log(data);
      toast.success(data.msg);
      setOpen(false);
      queryClient.invalidateQueries("allDocs");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const getDocDetailQuery = useQuery({
    queryKey: ["docDetail", currentData?._id],
    retry: false,
    enabled: false,
    queryFn: () => getDocApi(currentData?._id),
    onSuccess: (data) => {
      if (data) {
        setFormValues((prev) => ({
          ...prev,
          doc_group: {
            value: data.doc_group,
            label: data.doc_group,
          },
          doc_type: {
            value: data.doc_type,
            label: data.doc_type,
          },
          doc_for: {
            label: data.doc_for?.legal_name || data.doc_for?.loginMobile,
            value: data.doc_for?._id,
          },
          filepath: data.filepath,
          reminder_date: data.reminder_date
            ? new Date(data.reminder_date).toISOString().split("T")[0]
            : "",
          status: data.status,
        }));
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setOpen(false);
    },
  });

  const getDocRequestDetailQuery = useQuery({
    queryKey: ["docRequestDetail", currentData?._id],
    retry: false,
    enabled: false,
    queryFn: () =>
      getDocRequestApi(currentData?._id, currentData?.docRequestId),
    onSuccess: (data) => {
      if (data) {
        setFormValues((prev) => ({
          ...prev,
          doc_group: {
            value: data.doc_group,
            label: data.doc_group,
          },
          doc_type: {
            value: data.doc_type,
            label: data.doc_type,
          },
          doc_for: {
            label: data.doc_for.legal_name || data.doc_for.loginMobile,
            value: data.doc_for._id,
          },
          filepath: data.filepath,
          reminder_date: data.reminder_date
            ? new Date(data.reminder_date).toISOString().split("T")[0]
            : "",
          status: data.status,
        }));
      }
    },
    onError: (error) => {
      toast.error(error.message);
      setOpen(false);
    },
  });

  const uploadDocButton = async (e) => {
    e.preventDefault();
    const payload = {
      doc_group: formValues.doc_group.value,
      doc_type: formValues.doc_type.value,
      doc_for: formValues.doc_for.value,
      reminder_date: formValues.reminder_date,
      filepath: formValues.filepath,
    };

    const { isValid, errors } = await uploadDocValidate(payload);

    if (!isValid) {
      setFormError(errors);
      return;
    }

    if (currentData) {
      updateDocMutation.mutate({
        payload: {
          ...payload,
          metadata: getDocDetailQuery.data?.metadata,
        },
        id: currentData._id,
      });
    } else {
      createDocMutation.mutate(payload);
    }
  };

  useEffect(() => {
    if (!open) {
      setFormValues({
        filepath: "",
        doc_group: "",
        doc_type: "",
        doc_for: "",
        reminder_date: "",
      });
      setFormError({
        doc_group: null,
        doc_type: null,
        doc_for: null,
        reminder_date: null,
        filepath: null,
      });
      setCurrentSelectedDoc(null);
    }
  }, [open]);

  useEffect(() => {
    if (currentData?._id && open && !isDocRequest) {
      getDocDetailQuery.refetch();
    }
    if (currentData?._id && open && isDocRequest) {
      getDocRequestDetailQuery.refetch();
    }
  }, [open, currentData]);

  const showApproveButton = () => {
    if (
      formValues?.status === "PENDING" &&
      userDetail.userType === "AUTHORIZER"
    )
      return "Approve";
    else if (
      formValues?.status === "AUTHORIZED" &&
      userDetail.userType === "AUTHORIZER"
    )
      return "Approved";
    else if (
      formValues?.status === "PENDING" &&
      userDetail.userType === "CHECKER"
    )
      return "Check";
    else if (
      formValues?.status === "CHECKED" &&
      userDetail.userType === "CHECKER"
    )
      return "Checked";
    else return "Save";
  };

  const handleMetaDataChange = (obj, key) => {
    queryClient.setQueryData(["docDetail", currentData?._id], (oldData) =>
      oldData
        ? {
            ...oldData,
            metadata: {
              ...oldData.metadata,
              [key]: obj[key],
            },
          }
        : oldData
    );
  };

  const renderMetaData = () => {
    if (
      getDocDetailQuery.data?.metadata &&
      Object.keys(getDocDetailQuery.data?.metadata).length > 0
    ) {
      return Object.keys(getDocDetailQuery.data?.metadata).map((key, index) => (
        <div className="mt-4" key={`meta-data-${index}`}>
          {typeof getDocDetailQuery.data?.metadata[key] === "string" ? (
            <Input
              title={key
                .split("_")
                .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")}
              valKey={key}
              inputValues={getDocDetailQuery.data?.metadata}
              setInputValues={(obj) => handleMetaDataChange(obj, key)}
              // disabled={true}
            />
          ) : //   <label className="block text-sm font-medium leading-6 text-gray-900">
          //   {key.split("_").map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(" ")}:{" "}
          //   <span className="font-light ">
          //     {getDocDetailQuery.data?.metadata[key]}
          //   </span>
          // </label>
          null}
        </div>
      ));
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-105"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-105"
            >
              <Dialog.Panel className="flex w-full min-h-[calc(100vh-74px)] transform text-left text-base transition sm:my-8">
                <form className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8">
                  <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg font-medium text-gray-900">
                      Add Document
                    </h2>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {isView && getDocDetailQuery.isLoading ? (
                    <div>
                      <p className="text-center">Loading...</p>
                    </div>
                  ) : (
                    <div className="grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2 mt-4 items-center h-full">
                      <section
                        aria-labelledby="cart-heading"
                        className="px-4 mx-auto text-center h-full w-full flex items-center justify-center flex-col"
                      >
                        <FileInput
                          valKey="filepath"
                          inputValues={formValues}
                          setInputValues={setFormValues}
                        />
                        {formError?.filepath && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="name-error"
                          >
                            {formError.filepath}
                          </p>
                        )}
                      </section>
                      <section
                        aria-labelledby="summary-heading"
                        className="sm:px-6 lg:px-8 h-full"
                      >
                        <div className="bg-gray-50 p-6 sm:rounded-lg sm:p-8 h-full ">
                          <h2 id="summary-heading" className="sr-only">
                            Doc data{" "}
                          </h2>
                          <div className="flow-root">
                            <dl className="-my-4">
                              <CustomSelect
                                id="doc_group"
                                name="Doc Group"
                                options={
                                  dummyDocData.map((item) => ({
                                    value: item.doc_group,
                                    label: item.doc_group,
                                  })) || []
                                }
                                formError={formError}
                                setFormErrors={setFormError}
                                singleFieldValidation={uploadDocSingleValidate}
                                value={formValues.doc_group}
                                onChange={(e) =>
                                  handleFormChange(e, "doc_group")
                                }
                                className="mb-4"
                              />

                              <CustomSelect
                                id="doc_type"
                                name="Doc Type"
                                options={
                                  dummyDocData
                                    .filter(
                                      (item) =>
                                        item.doc_group ===
                                        formValues.doc_group.label
                                    )[0]
                                    ?.["documents"].map((item) => ({
                                      value: item.doc_type,
                                      label: item.doc_type,
                                    })) || []
                                }
                                formError={formError}
                                setFormErrors={setFormError}
                                singleFieldValidation={uploadDocSingleValidate}
                                value={formValues.doc_type}
                                onChange={(e) =>
                                  handleFormChange(e, "doc_type")
                                }
                                className="mb-4"
                              />
                              
                              <CustomSelect
                                id="doc_for"
                                name="Doc For"
                                options={
                                  users ? users.map((item) => ({
                                    value: item._id,
                                    label:
                                      item.legal_name +
                                      " - " +
                                      item.loginMobile,
                                  })) : []
                                }
                                formError={formError}
                                setFormErrors={setFormError}
                                singleFieldValidation={uploadDocSingleValidate}
                                value={formValues.doc_for}
                                onChange={(e) => handleFormChange(e, "doc_for")}
                                className="mb-4"
                              />

                              <Input
                                type="date"
                                placeholder="DD-MM-YYYY - HH:MM AM/PM"
                                title="Reminder Date"
                                valKey="reminder_date"
                                inputValues={formValues}
                                setInputValues={setFormValues}
                                singleFieldValidation={uploadDocSingleValidate}
                                setFormErrors={setFormError}
                                formErrors={formError}
                              />
                              <div className="mt-4 pb-2 border-b">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Status:{" "}
                                  <span className="font-light ">
                                    {formValues?.status || "Pending"}
                                  </span>
                                </label>
                              </div>
                              {renderMetaData()}
                            </dl>
                          </div>
                        </div>
                      </section>
                    </div>
                  )}

                  {!isView && (
                    <div className="mt-8 flex justify-end px-4 sm:px-6 lg:px-8">
                      <button
                        type="submit"
                        className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        disabled={createDocMutation.isLoading}
                        onClick={uploadDocButton}
                      >
                        {createDocMutation.isLoading ||
                        updateDocMutation.isLoading
                          ? "Loading..."
                          : showApproveButton()}
                      </button>
                    </div>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
