import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const markAttendanceApi = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/attendance`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAttendanceApi = async (filter) => {
  try {
    let queryStringArr = [];
    for (const filterKey in filter) {
      if (filter[filterKey] !== undefined && filter[filterKey] !== null) {
        queryStringArr.push(`${filterKey}=${filter[filterKey]}`);
      }
    }
    const queryParams = queryStringArr.join("&");
    const res = await axios.get(`${BASE_URL}/attendance?${queryParams}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getSingleAttendanceApi = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL}/attendance/${id}`, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAttendanceAnalyticsApi = async (filter) => {
  const formatDate = (date) => date.toISOString().split("T")[0];

  let queryStringArr = [];
  for (const filterKey in filter) {
    let value = filter[filterKey];
    if (value !== undefined && value !== null) {
      if (["startDate", "endDate"].includes(filterKey)) {
        value = formatDate(new Date(value));
        // if(value.includes('-')){
        //   value = value.split('-').reverse().join('-')
        // }
      }
      queryStringArr.push(`${filterKey}=${value}`);
    }
  }
  const queryParams = queryStringArr.join("&");

  try {
    const res = await axios.get(
      `${BASE_URL}/attendance/analytics?${queryParams}`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );
    return res.data.data;
  } catch (e) {
    toast.error(e.response?.data.msg ?? "Something went wrong");
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
