import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  getUserDetailApi,
  updateUserDetailApi,
} from "../../../services/user.services";
import { useUserDetailStore } from "../../../store/userDetail";
import StatutoryDetail from "./StatutoryDetail";
import BankDetail from "./BankDetail";
import PersonalDetail from "./PersonalDetail";
import FamilyDetail from "./FamilyDetail";
import BusinessDetail from "./BusinessDetail";
import UploadDetail from "./UploadDetail";
import TopBar from "./TopBar";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

/*
Personal Login - user_type = "U" and is_personal_user = true 
Employee Login - user_type = "UM_BASIC, UM_STAFF, SUM_STAFF, UM_GUARD" and is_personal_user = true 
Business Login - user_type = "U" and is_personal_user = false 
*/
const componentSteps = ["statutory", "business", "bank", "personal", "upload"]
const componentStepsEmployee = ["personal", "family", "bank"]
const componentStepsPersonal = ["personal", "family"]
// Type can be PROFILE/USER - If PROFILE - Then user can update his/her profile else admin can edit them
export default function UserEdit({ userData }) {
  const scrollRef = useRef(null);
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

  const components = userData?.is_personal_user ? (userData?.user_type === "U" ? componentStepsPersonal : componentStepsEmployee) : componentSteps

  const currentComponent = components[currentComponentIndex]

  const setStatutoryDetail = useUserDetailStore(
    (state) => state.setStatutoryDetail
  );
  const setPersonalDetail = useUserDetailStore(
    (state) => state.setPersonalDetail
  );
  const setBusinessDetail = useUserDetailStore(
    (state) => state.setBusinessDetail
  );
  const setBankDetail = useUserDetailStore((state) => state.setBankDetail);
  const setUploadDetail = useUserDetailStore((state) => state.setUploadDetail);

  const statutoryDetail = useUserDetailStore((state) => state.statutoryDetail);
  const personalDetail = useUserDetailStore((state) => state.personalDetail);
  const businessDetail = useUserDetailStore((state) => state.businessDetail);
  const bankDetail = useUserDetailStore((state) => state.bankDetail);
  const uploadDetail = useUserDetailStore((state) => state.uploadDetail);

  const getUseDetailQuery = useQuery({
    queryKey: ["getUserDetail", userData._id],
    enabled: false,
    staleTime: Infinity,
    queryFn: async () => {
      return getUserDetailApi({ id: userData._id });
    },
    onSuccess: (data) => {
      // console.log("data", data.data);
      setStatutoryDetail(data.data);
      setPersonalDetail(data.data.personal_details);
      // todo: set personal detail expand and error
      setBusinessDetail(data.data);
      setBankDetail(data.data);
      setUploadDetail(data.data);
    },
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (currentComponent) getUseDetailQuery.refetch();
  }, [currentComponent]);

  const handleBack = () => {
    if (currentComponentIndex === 0) return;
    setCurrentComponentIndex(prev => prev - 1)
  };

  const handleNext = () => {
    if (currentComponentIndex === components.length - 1) return;
    setCurrentComponentIndex((prev) => prev + 1)
  };

  const userEditMutation = useMutation({
    mutationFn: updateUserDetailApi,
    onSuccess: (data) => {
      handleNext();
      window.scrollTo(0, 0);
      toast.success("Data updated successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleNextButton = async () => {
    let currentComponentValue = {};
    if (currentComponent === "statutory")
      currentComponentValue = statutoryDetail;
    else if (currentComponent === "business")
      currentComponentValue = businessDetail;
    else if (currentComponent === "bank") currentComponentValue = bankDetail;
    else if (currentComponent === "personal")
      currentComponentValue = personalDetail;
    else if (currentComponent === "upload")
      currentComponentValue = uploadDetail;

    // const objWithValue = Object.fromEntries(
    //   Object.entries(currentComponentValue).filter(
    //     ([key, value]) => value // !== ""
    //   )
    // );
    if (["personal", "family"].includes(currentComponent)) {
      console.log({personalDetail})
      userEditMutation.mutate({
        userId : userData._id,
        data: { personal_details: personalDetail },
      });
    } else userEditMutation.mutate({ userId : userData._id, data: currentComponentValue });
  };

  const renderComponent = (currentComponent) => {
    if (!userData || getUseDetailQuery.isLoading || !getUseDetailQuery.data) return null
    switch (currentComponent) {
      case "statutory":
        return (
          <StatutoryDetail
          userId={userData._id}
          />
        );
      case "personal":
        return (
          <PersonalDetail
            userDetail={getUseDetailQuery.data.data}
            isLoading={getUseDetailQuery.isLoading}
            scrollRef={scrollRef}
          />
        );
      case "family":
        return (
          <FamilyDetail
            userDetail={getUseDetailQuery.data.data}
            isLoading={getUseDetailQuery.isLoading}
            scrollRef={scrollRef}
          />
        );
      case "business":
        return (
          <BusinessDetail
            isLoading={getUseDetailQuery.isLoading}
            scrollRef={scrollRef}
          />
        );
      case "bank":
        return <BankDetail userId={userData._id} />;
      case "upload":
        return (
          <UploadDetail
            scrollRef={scrollRef}
          />
        );
      default:
        return <h1>Reload your page</h1>;
    }
  };
  
  return (
    <> 
      <TopBar
        currentComponentIndex={currentComponentIndex}
        currentComponentName={currentComponent}
        setCurrentComponentIndex={setCurrentComponentIndex}
        components={components}
      />
      {renderComponent(currentComponent)}
      <div className="isolate inline-flex rounded-md mt-8 justify-between w-full">
        {currentComponentIndex !== 0 ? (
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-indigo-400 ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50 focus:z-10"
            onClick={handleBack}
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            <span className="text-sm">Previous</span>
          </button>
        ) : (
          <div></div>
        )}
        <button
          type="button"
          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-indigo-400 ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50 focus:z-10"
          onClick={handleNextButton}
        >
          <span className="text-sm">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
