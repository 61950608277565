import { useQuery } from "@tanstack/react-query";
import Card from "./components/Card";
import { useParams } from "react-router-dom";
import { getUserDetailApi } from "../../services/user.services";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserViewPage() {
  const { id: userId } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPersonalDetail, setCurrentPersonalDetail] = useState(null);

  const getUseDetailQuery = useQuery({
    queryKey: ["getUserDetail", userId],
    // enabled: false,
    // staleTime: Infinity,
    queryFn: async () => {
      return getUserDetailApi({ id: userId });
    },
    onSuccess: (data) => {
      setUserDetail(data.data);
    },
    onError: (err) => toast.error(err.message),
  });

  const getFileExtension = (filename) => {
    return filename?.split(".").pop().toLowerCase();
  };

  useEffect(() => {
    if (userDetail?.personal_details?.length > 0)
      setCurrentPersonalDetail(userDetail?.personal_details[0]);
  }, [userDetail]);

  const renderImage = (photo, alt) => {
    if (getFileExtension(photo) === "pdf") {
      return (
        <div className="text-sm underline">
          <a
            href={process.env.REACT_APP_BACKEND_IMAGE_URL + photo}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click to view pdf
          </a>
        </div>
      );
    } else if (photo) {
      return (
        <img
          className="inline-block h-9 w-9 rounded-full ring-2 ring-violet-500"
          src={process.env.REACT_APP_BACKEND_IMAGE_URL + photo}
          alt={alt}
        />
      );
    } else {
      return <CrossIcon className="h-9 w-9" />;
    }
  };

  // console.log("userDetail", currentTab);
  // console.log("currentPersonalDetail", currentPersonalDetail, userDetail);
  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {!userDetail?.is_personal_user ? (
        <>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Statutory Information
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Card label="Gst Number" value={userDetail.gst} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Business type" value={userDetail.business_type} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Pan Number" value={userDetail.pan} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Fssai Number" value={userDetail.fssai} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Msme Number" value={userDetail.msme} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Trade License" value={userDetail.trade} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Cin/ Din Number" value={userDetail.cin_din} />
            </div>

            <div className="sm:col-span-6 flex justify-between flex-wrap gap-2">
              <div className="flex gap-1 text-sm">
                {userDetail.is_gst_verified > 0 ? (
                  <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
                ) : (
                  <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
                )}
                <p>Gst Verified</p>
              </div>
              <div className="flex gap-1 text-sm">
                {userDetail.is_pan_verified > 0 ? (
                  <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
                ) : (
                  <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
                )}
                <p>Pan Verified</p>
              </div>
              <div className="flex gap-1 text-sm">
                {userDetail.is_fssai_verified > 0 ? (
                  <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
                ) : (
                  <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
                )}
                <p>Fssai Verified</p>
              </div>{" "}
              <div className="flex gap-1 text-sm">
                {userDetail.is_msme_verified > 0 ? (
                  <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
                ) : (
                  <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
                )}
                <p>Msme Verified</p>
              </div>
              <div className="flex gap-1 text-sm">
                {userDetail.is_cin_din_verified > 0 ? (
                  <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
                ) : (
                  <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
                )}
                <p>Cin Din Verified</p>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Businesss */}
      {!userDetail?.is_personal_user ? (
        <>
          <h2 className="text-base font-semibold leading-7 text-gray-900 mt-12">
            Business Information
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Card label="Legal Name" value={userDetail.legal_name} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Trade Name" value={userDetail.trade_name} />
            </div>
            <div className="sm:col-span-6">
              <Card
                label="Center Jurisdiction"
                value={userDetail.center_jurisdiction}
              />
            </div>
            <div className="sm:col-span-6">
              <Card
                label="State Jurisdiction"
                value={userDetail.state_jurisdiction}
              />
            </div>
            <div className="sm:col-span-3">
              <Card label="Taxpayer Type" value={userDetail.taxpayer_type} />
            </div>
            <div className="sm:col-span-3">
              <Card
                label="Date of Gst registration"
                value={userDetail.date_of_gst_registration}
              />
            </div>
            <div className="sm:col-span-6">
              <Card label="Office address" value={userDetail.office_address} />
            </div>
            <div className="sm:col-span-3">
              <Card label="State" value={userDetail.state} />
            </div>
            <div className="sm:col-span-3">
              <Card label="Directors" value={userDetail.directors} />
            </div>
          </div>
        </>
      ) : null}

      {/* Bank */}
      <h2 className="text-base font-semibold leading-7 text-gray-900 mt-12">
        Bank Information
      </h2>
      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <Card label="Account Number" value={userDetail.account_number} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Ifsc" value={userDetail.ifsc} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Bank Name" value={userDetail.bank_name} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Branch" value={userDetail.branch} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Holder Name" value={userDetail.holder_name} />
        </div>
        <div className="sm:col-span-3">
          <Card label="City" value={userDetail.city} />
        </div>
        <div className="flex gap-1 text-sm">
          {userDetail.is_bank_verified > 0 ? (
            <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
          ) : (
            <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
          )}
          <p>Bank Verified</p>
        </div>
      </div>

      {/* Personal data */}
      <h2 className="text-base font-semibold leading-7 text-gray-900 mt-12">
        Personal Information
      </h2>

      {!userDetail?.is_personal_user ? (
        <>
          <div className="sm:hidden mt-8">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              // defaultValue={userDetail?.personal_details[0]?.personal_type}
              onChange={(e) => {
                setCurrentTab(e.target.value);
                setCurrentPersonalDetail(
                  userDetail?.personal_details[e.target.value]
                );
              }}
              // defaultValue={tabs.find((tab) => tab.current).name}
            >
              {userDetail?.personal_details?.map((personalDetail, index) => (
                <option key={personalDetail._id} value={index}>
                  {personalDetail.personal_type}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block mt-8">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
            >
              {userDetail?.personal_details?.map((personalDetail, index) => (
                <div
                  key={personalDetail._id}
                  onClick={() => {
                    setCurrentTab(index);
                    setCurrentPersonalDetail(personalDetail);
                  }}
                  className={classNames(
                    currentTab === index
                      ? "bg-gray-200 text-gray-800"
                      : "text-gray-600 hover:text-gray-800",
                    "px-3 py-2 text-sm font-medium cursor-pointer flex-1 text-center"
                  )}
                  // aria-current={tab.current ? 'page' : undefined}
                >
                  {personalDetail.personal_type}
                </div>
              ))}
            </nav>
          </div>
        </>
      ) : null}

      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <Card label="Name" value={currentPersonalDetail?.name} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Mobile" value={currentPersonalDetail?.mobile} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Email" value={currentPersonalDetail?.email} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Father" value={currentPersonalDetail?.father} />
        </div>
        <div className="sm:col-span-2">
          <Card label="Dob" value={currentPersonalDetail?.dob} />
        </div>
        <div className="sm:col-span-2">
          <Card label="Gender" value={currentPersonalDetail?.gender} />
        </div>
        <div className="sm:col-span-2">
          <Card label="Pincode" value={currentPersonalDetail?.pincode} />
        </div>
        <div className="sm:col-span-3">
          <Card label="House" value={currentPersonalDetail?.house} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Street" value={currentPersonalDetail?.street} />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Sub District"
            value={currentPersonalDetail?.sub_district}
          />
        </div>
        <div className="sm:col-span-3">
          <Card label="District" value={currentPersonalDetail?.district} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Landmark" value={currentPersonalDetail?.landmark} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Locality" value={currentPersonalDetail?.locality} />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Post office"
            value={currentPersonalDetail?.post_office}
          />
        </div>
        <div className="sm:col-span-3">
          <Card label="State" value={currentPersonalDetail?.state} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Aadhar Number" value={currentPersonalDetail?.aadhar} />
        </div>
        <div className="sm:col-span-3">
          <Card label="Voter Id" value={currentPersonalDetail?.voter} />
        </div>
        {/* <div className="sm:col-span-3">
          <Card label="Dl Id" value={currentPersonalDetail?.dl} />
        </div> */}
        <div className="sm:col-span-3">
          <Card label="Rc Id" value={currentPersonalDetail?.rc} />
        </div>

        <div className="sm:col-span-6 flex justify-between flex-wrap gap-2">
          <div className="flex gap-1 text-sm">
            {currentPersonalDetail?.is_aadhar_verified > 0 ? (
              <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
            ) : (
              <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
            )}
            <p>Aadhar Verified</p>
          </div>
          <div className="flex gap-1 text-sm">
            {currentPersonalDetail?.is_voter_verified > 0 ? (
              <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
            ) : (
              <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
            )}
            <p>Voter Verified</p>
          </div>
          {/* <div className="flex gap-1 text-sm">
            {currentPersonalDetail?.is_voter_verified > 0 ? (
              <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
            ) : (
              <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
            )}
            <p>Voter Verified</p>
          </div> */}
          {/* <div className="flex gap-1 text-sm">
            {currentPersonalDetail?.is_dl_verified > 0 ? (
              <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
            ) : (
              <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
            )}
            <p>Dl Verified</p>
          </div> */}
          <div className="flex gap-1 text-sm">
            {currentPersonalDetail?.is_rc_verified > 0 ? (
              <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
            ) : (
              <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
            )}
            <p>Rc Verified</p>
          </div>
        </div>

        <div className="sm:col-span-3">
          <Card label="Fb Url" value={currentPersonalDetail?.fb_url} />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Twitter Url"
            value={currentPersonalDetail?.twitter_url}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Instagram Url"
            value={currentPersonalDetail?.instagram_url}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Linkedin Url"
            value={currentPersonalDetail?.linkedin_url}
          />
        </div>

        <div className="w-full sm:col-span-6"></div>

        <div className="sm:col-span-3">
          <Card
            label="Parents Anniversary Date"
            value={currentPersonalDetail?.parents_anniversary_date}
          />
        </div>
        <div className="sm:col-span-3">
          <Card label="Father Name" value={currentPersonalDetail?.father} />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Father Date of Birth"
            value={currentPersonalDetail?.father_dob}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Father Occupation"
            value={currentPersonalDetail?.father_occupation}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Father Mobile"
            value={currentPersonalDetail?.father_mobile}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Mother Name"
            value={currentPersonalDetail?.mother_name}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Mother Date of Birth"
            value={currentPersonalDetail?.mother_dob}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Mother Occupation"
            value={currentPersonalDetail?.mother_occupation}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Mother Mobile"
            value={currentPersonalDetail?.mother_mobile}
          />
        </div>

        <div className="flex gap-1 text-sm w-full sm:col-span-6">
          {currentPersonalDetail?.is_married === "Yes" ? (
            <CheckIcon className="bg-green-600 w-5 rounded-md text-white " />
          ) : (
            <XMarkIcon className="bg-red-600 w-5 rounded-md text-white " />
          )}
          <p>Is Married</p>
        </div>

        <div className="sm:col-span-3">
          <Card
            label="Anniversary Date"
            value={currentPersonalDetail?.anniversary_date}
          />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Spouse Name"
            value={currentPersonalDetail?.spouse_name}
          />
        </div>
        <div className="sm:col-span-3">
          <Card label="Spouse Dob" value={currentPersonalDetail?.spouse_dob} />
        </div>
        <div className="sm:col-span-3">
          <Card
            label="Number of kids"
            value={currentPersonalDetail?.num_kids}
          />
        </div>
        <div className="flex items-center">
          <img
            className="inline-block h-24 w-24 ring-violet-500 ring-2"
            src={
              process.env.REACT_APP_BACKEND_IMAGE_URL +
              currentPersonalDetail?.user_photo
            }
            alt="User Adhar"
          />

          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 ml-3">
            User photo
          </p>
        </div>
      </div>

      {/* Upload data */}
      {!userDetail?.is_personal_user ? (
        <>
          <h2 className="text-base font-semibold leading-7 text-gray-900 mt-12">
            Upload Information
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.gst_photo, "User GST")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Gst photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.pancard_photo, "User Pancard")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Pancard photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.fsaai_photo, "User FSSAI")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Fssai photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.msme_photo, "User MSME")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Msme photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.trade_photo, "User Trade License")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Trade photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.office_photo, "User Office")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Office photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.shop_photo, "User Shop")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Shop photo
              </p>
            </div>
            <div className="flex items-center gap-3">
              {renderImage(userDetail?.godown_photo, "User Godown")}
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Godown photo
              </p>
            </div>
          </div>
        </>
      ) : null}
    </main>
  );
}

const CrossIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ff2929"
    {...props}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path
        d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
        fill="#fd2626"
      />
    </g>
  </svg>
);