import { toast } from "react-hot-toast";
import Input from "../../../components/Input/Input";
import { useUserDetailStore } from "../../../store/userDetail";
import { statutoryDetailSingleValidate } from "../../../validation/statutoryValidation";
import {
  fetchGstDetailApi,
  fetchPanDetailApi,
} from "../../../services/user.services";
import { useMutation } from "@tanstack/react-query";
import Button from "../../../components/Button/Button";

const business_types = [
  "Proprietorship",
  "Public Limited Company",
  "Private Limited Company",

  // "Partnership",
  // "LLP",
  // "Private Limited",
  // "Public Limited",
  // "Trust",
  // "Society",
  // "HUF",
  // "AOP",
  // "BOI",
  // "Local Authority",
  // "Foreign Company",
  // "Others",
];

export default function StatuaryDetail({ userId }) {
  const statutoryDetail = useUserDetailStore((state) => state.statutoryDetail);
  const setStatutoryDetail = useUserDetailStore(
    (state) => state.setStatutoryDetail
  );
  const statutoryError = useUserDetailStore((state) => state.statutoryError);
  const setStatutoryError = useUserDetailStore(
    (state) => state.setStatutoryError
  );

  const gstDetailMutation = useMutation({
    mutationFn: fetchGstDetailApi,
    onSuccess: (data) => {
      console.log("data", data.data);
      setStatutoryDetail(data.data);
      toast.success("GSTIN verified successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const handleVerifyGst = () => {
    gstDetailMutation.mutate({
      data: { gstin: statutoryDetail.gst, consent: "Y" },
      userId: userId,
    });
  };

  const panDetailMutation = useMutation({
    mutationFn: fetchPanDetailApi,
    onSuccess: (data) => {
      console.log("data", data.data);
      setStatutoryDetail(data.data);
      toast.success("PAN verified successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const handleVerifyPan = () => {
    panDetailMutation.mutate({
      data: { pan_number: statutoryDetail.pan, consent: "Y" },
      userId: userId,
    });
  };

  // console.log("statutoryDetail", statutoryDetail);

  return (
    <div className="mt-4">
      <Input
        placeholder="GST number"
        title="GSTIN"
        valKey="gst"
        inputValues={statutoryDetail}
        setInputValues={setStatutoryDetail}
        singleFieldValidation={statutoryDetailSingleValidate}
        setFormErrors={setStatutoryError}
        formErrors={statutoryError}
        disabled={statutoryDetail.is_gst_verified >= 1}
        isMasked={statutoryDetail.is_gst_verified >= 1}
      />
      <div className="flex items-center justify-between gap-2 mt-1">
        {statutoryDetail.is_gst_verified >= 1 ? (
          <p className="text-xs text-green-800">
            Your GST number is secured with us
          </p>
        ) : (
          <p></p>
        )}
        <Button
          isLoading={gstDetailMutation.isLoading}
          onClick={
            statutoryDetail.is_gst_verified >= 1 ? () => null : handleVerifyGst
          }
          title={
            statutoryDetail.is_gst_verified > 0
              ? statutoryDetail.is_gst_verified >= 1
                ? "Validated"
                : "Verify GSTIN again"
              : "Verify GSTIN"
          }
          disabled={statutoryError.gst || statutoryDetail.gst == ""}
        />
      </div>

      <select
        id="business_type"
        name="business"
        className="mt-4 block w-full rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        value={statutoryDetail.business_type}
        disabled={statutoryDetail.is_gst_verified >= 1}
        onChange={(e) =>
          setStatutoryDetail({
            ...statutoryDetail,
            business_type: e.target.value,
          })
        }

      >
      {business_types.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
      </select>

      <Input
        placeholder="PAN number"
        title="PAN No"
        valKey="pan"
        inputValues={statutoryDetail}
        setInputValues={setStatutoryDetail}
        singleFieldValidation={statutoryDetailSingleValidate}
        setFormErrors={setStatutoryError}
        formErrors={statutoryError}
        disabled={statutoryDetail.is_pan_verified >= 1}
        isMasked={statutoryDetail.is_pan_verified >= 1}
      />
      <div className="flex items-center justify-between gap-2 mt-1">
        {statutoryDetail.is_pan_verified >= 1 ? (
          <p className="text-xs text-green-800">
            Your PAN number is secured with us
          </p>
        ) : (
          <p></p>
        )}
        <Button
          isLoading={panDetailMutation.isLoading}
          onClick={
            statutoryDetail.is_pan_verified >= 1 ? () => null : handleVerifyPan
          }
          title={
            statutoryDetail.is_pan_verified > 0
            ? statutoryDetail.is_pan_verified >= 1
            ? "Validated"
            : "Verify Pan again"
            : "Verify Pan"
          }
          disabled={statutoryError.pan || statutoryDetail.pan == ""}
        />
      </div>

      <Input
        placeholder="FSAAI license"
        title="FSAAI License"
        valKey="fssai"
        inputValues={statutoryDetail}
        setInputValues={setStatutoryDetail}
        singleFieldValidation={statutoryDetailSingleValidate}
        setFormErrors={setStatutoryError}
        formErrors={statutoryError}
      />
      <Input
        placeholder="MSME license"
        title="MSME License"
        valKey="msme"
        inputValues={statutoryDetail}
        setInputValues={setStatutoryDetail}
        singleFieldValidation={statutoryDetailSingleValidate}
        setFormErrors={setStatutoryError}
        formErrors={statutoryError}
      />
      <Input
        placeholder="Trade license"
        title="Trade License"
        valKey="trade"
        inputValues={statutoryDetail}
        setInputValues={setStatutoryDetail}
        singleFieldValidation={statutoryDetailSingleValidate}
        setFormErrors={setStatutoryError}
        formErrors={statutoryError}
      />
      {(statutoryDetail["business_type"] === business_types[1] ||
        statutoryDetail["business_type"] === business_types[2]) && (
        <Input
          placeholder="Cin/ Din license"
          title="Cin/ Din License"
          valKey="cin_din"
          inputValues={statutoryDetail}
          setInputValues={setStatutoryDetail}
          singleFieldValidation={statutoryDetailSingleValidate}
          setFormErrors={setStatutoryError}
          formErrors={statutoryError}
        />
      )}
    </div>
  );
}
