import * as yup from "yup";

const rules = {
  personal_type: yup.string().required("Personal Type is required"),
  aadhar: yup
    .string()
    .matches(/^[0-9]{12}$/, "Invalid Aadhar number")
    .required("Aadhar number is required"),
  name: yup.string().required("Name is required"),
  dob: yup.string().required("Date of Birth is required"),
  gender: yup.string().required("Gender is required"),
  mobile: yup.string().required("Mobile number is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  father: yup.string().required("Father's name is required"),
  house: yup.string().required("House name/number is required"),
  street: yup.string().required("Street is required"),
  district: yup.string().required("District is required"),
  sub_district: yup.string().required("Sub-district is required"),
  landmark: yup.string().required("Landmark is required"),
  locality: yup.string().required("Locality is required"),
  post_office: yup.string().required("Post office is required"),
  state: yup.string().required("State is required"),
  pincode: yup
    .string()
    .matches(/^[0-9]{6}$/, "Invalid PIN code")
    .required("PIN code is required"),
  voter: yup.string(),
};

const schema = yup.object(rules);

export const personalDetailValidate = async (values) => {
  try {
    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const personalDetailSingleValidate = async (fieldName, value) => {
  try {
    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};

const otpRules= {
 
  otp: yup.string().required("Otp is required"),
  
};

const otpSchema = yup.object(otpRules);


export const aadharOtpValidation = async (fieldName, value) => {
  try {
    await otpSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};