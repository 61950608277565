import { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getUserRole } from "../../../utils/user";
import Pagination from "../../../components/Common/Pagination";
import { useGetAllAttendance } from "../../../http-hooks/useAttendance";
import AttendanceDetailsPopup from "./AttendanceDetailsPopup";
import { useGetAllUnitMasters } from "../../../http-hooks/useUnitMaster";
import { useGetAllUsers } from "../../../http-hooks/useUser";
import CustomSelect from "../../../components/Input/Select";

export default function DataTable() {
  const navigate = useNavigate();
  const [unit, setUnit] = useState(null);
  const [user, setUser] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,

    // startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    // endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [userFilter, setUserFilter] = useState({
    is_personal_user: true,
    is_employee: true,
  });
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [currentAttendance, setCurrentAttendance] = useState(null);
  const userType = localStorage.getItem("storeform-user-type");

  const { data: unitMasters, isLoading: isLoadingUnitMasters } =
    useGetAllUnitMasters();
  const { data: allUsersData, isLoading: isLoadingAllUsers } =
    useGetAllUsers(userFilter);
  const { data, isLoading } = useGetAllAttendance(filter);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Attendance
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            View the attendance given by all users.
          </p>
        </div>
        {userType === "A" ? (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => navigate("/attendance/analytics")}
            >
              Analytics
            </button>
          </div>
        ) : null}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="flex gap-2">
              <div className="mt-2 mb-4">
                <CustomSelect
                  id="unit"
                  name="Unit"
                  value={unit}
                  isSmallHeight={false}
                  onChange={(selectedOption) => {
                    setUnit(selectedOption);
                    setFilter((prev) => {
                      return { ...prev, unit: selectedOption?.value };
                    });
                    setUserFilter((prev) => {
                      return { ...prev, unit: selectedOption?.value };
                    });
                  }}
                  options={
                    unitMasters
                      ? unitMasters.map((k) => {
                          return {
                            label: k.name,
                            value: k._id,
                          };
                        })
                      : []
                  }
                />
              </div>

              <div className="mt-2 mb-4">
                <CustomSelect
                  id="user"
                  name="User"
                  value={user}
                  isSmallHeight={false}
                  onChange={(selectedOption) => {
                    setUser(selectedOption);
                    setFilter((prev) => {
                      return { ...prev, user: selectedOption?.value };
                    });
                  }}
                  options={
                    allUsersData
                      ? allUsersData.map((k) => {
                          return {
                            label: k?.personal_details?.[0]?.name || "N/A",
                            value: k._id,
                          };
                        })
                      : []
                  }
                />
              </div>
            </div>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Unit
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Time
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <p>loading...</p>
                  ) : (
                    data.attendance.map((attendance) => (
                      <tr key={attendance._id}>
                        {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {user?.personal_details?.[0]?.user_photo ? (
                            <img
                              className="w-16"
                              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${user?.personal_details?.[0]?.user_photo}`}
                              alt=""
                            />
                          ) : null}
                        </td> */}

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {attendance.user?.personal_details?.[0]?.name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {getUserRole(
                            attendance?.user?.user_type,
                            attendance?.user?.is_personal_user
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {attendance?.unit?.name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(attendance.createdAt).format(
                            "MMMM D, YYYY h:mm A"
                          )}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                            onClick={() => {
                              setAttendanceModalOpen(true);
                              setCurrentAttendance(attendance);
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <Pagination
                {...data?.pagination}
                onPageChange={(page) =>
                  setFilter((prev) => {
                    return { ...prev, page };
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Attendance Popup */}
      {attendanceModalOpen && currentAttendance?._id ? (
        <AttendanceDetailsPopup
          open={attendanceModalOpen}
          setOpen={setAttendanceModalOpen}
          currentId={currentAttendance?._id}
        />
      ) : null}
    </div>
  );
}
