const moment = require("moment");
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TableData({
  data,
  setDeleteModalOpen,
  setCurrentSelectedDoc,
  isLoading,
  onViewClick,
}) {
  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : data && data.length ? (
        <div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Data Month
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Invoice Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Invoice Number
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Invoice Value
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Party Legal Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Party GST
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data.map((doc_temp) => {
                const doc = doc_temp;
                // const doc = selectedCard === "Common Docs" ? doc_temp["tally"].data : doc_temp
                return (
                  <tr key={`${doc._id}`}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {moment.utc(doc.doc_month).local().format("MM-YYYY")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {doc.metadata.invoice_date
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("-")}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {doc.metadata.invoice_number}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      ₹{doc.metadata.invoice_value}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {doc.metadata.legal_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {doc.metadata.gst_of_party}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-indigo-600 shadow-sm hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                        onClick={() => {
                          setCurrentSelectedDoc({ _id: doc._id });
                          onViewClick();
                        }}
                      >
                        {doc?.filepath && doc.filepath.length ? "View" : "Upload Doc"}
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-red-600 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                        onClick={() => {
                          setCurrentSelectedDoc(doc._id);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  Total Invoice Value
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  ₹{" "}
                  {data.reduce((acc, doc_temp) => {
                    return acc + parseFloat(doc_temp.metadata.invoice_value);
                  }, 0)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  );
}
