import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
  { id: 7, name: "Caroline Schultz" },
  { id: 8, name: "Mason Heaney" },
  { id: 9, name: "Claudie Smitham" },
  { id: 10, name: "Emil Schaefer" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CustomSelect = ({
  id,
  name,
  options,
  value,
  onChange,
  formError,
  className,
  isMultiple = false,
  defaultValue = "-- select an option --",
  isRequired = false,
  isSmallHeight = true
}) => {
  const valueString = () => {
    let labelString = defaultValue;
    if (value) {
      if (isMultiple) {
        if (value.length) {
          labelString = value.map((v) => v.label).join(", ");
        }
      } else {
        if (value?.label) {
          labelString = value?.label;
        }
      }
    }
    return labelString;
  };

  return (
    <div>
      <Listbox value={value} onChange={onChange} multiple={isMultiple}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {name}{" "}
              {isRequired ? <span className="text-red-600">*</span> : null}
            </Listbox.Label>
            <div className={classNames("relative mt-1", className)}>
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{valueString()}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className={`absolute z-10 mt-1 ${isSmallHeight ? "max-h-20" : "max-h-40"} w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.label}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex w-full justify-between">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.label}
                          </span>
                          {selected ? (
                            <svg
                              width="24px"
                              height="24px"
                              viewBox="0 -0.5 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.5 12.5L10.167 17L19.5 8"
                                stroke="#000000"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      {formError && formError[id] && (
        <p className="mb-2 -mt-2 text-xs text-red-600" id="id-error">
          {formError[id]}
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
