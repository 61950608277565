export default function SummaryCard({
  getAllDocsSummaryQuery,
  onCardClick,
  filteredDocs,
}) {
  const formatCamelCase = (str) => {
    if (str === "total_pending") {
      return "Total Pending for Checker";
    } else if (str === "total_checked") {
      return "Total Pending for Authorizer (Checked)";
    } else if (str === "total_authorized") {
      return "Total Authorized";
    }
    // return str.replace("_", " ").replace(/^./, function (str) {
    //   return str.toUpperCase();
    // });
  };

  const isActiveCard = (data) => {
    const { status } = filteredDocs;
    console.log(status,data);
    if (status?.includes("AUTHORIZED") && data === "total_authorized") {
      return "bg-green-100";
    }
    if (status?.includes("CHECKED") && data === "total_checked") {
      return "bg-yellow-100";
    }
    if (status?.includes("PENDING") && data === "total_pending") {
      return "bg-red-200";
    }
  };

  return getAllDocsSummaryQuery.isLoading ? (
    <p>Loading...</p>
  ) : (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-4">
      {Object.keys(getAllDocsSummaryQuery.data?.data).map((data, index) => (
        <div
          key={`datasummary-${index}`}
          className={`relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 ${isActiveCard(data)}`}
        >
          <div className="min-w-0 flex-1">
            <div
              className="focus:outline-none hover:cursor-pointer"
              onClick={() => onCardClick(data)}
            >
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-gray-900">
                {formatCamelCase(data)}
              </p>
              <p className="truncate text-sm text-gray-500">
                {getAllDocsSummaryQuery.data.data[data]}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
