import React from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteUnitMasterApi,
  getUnitMastersApi,
} from "../../services/unit-master.services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../HomePage/components/DeleteModal";
// import AttendanceAnalytics from "../AllAdminUsers/components/AttendanceAnalytics";

export default function UnitMasterPage() {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [currentSelectedUnit, setCurrentSelectedUnit] = React.useState(null);
  const [showAnalytics, setShowAnalytics] = React.useState(false);

  const getAllUnitMasters = useQuery({
    queryKey: ["allunitmasters"],
    retry: false,
    queryFn: () => getUnitMastersApi(),
    onSuccess: (data) => console.log(data),
  });

  const deleteUnitMasterMutation = useMutation({
    mutationFn: deleteUnitMasterApi,
    onSuccess: () => {
      setDeleteModalOpen(false);
      toast.success("Unit Master deleted successfully");
      getAllUnitMasters.refetch();
    },
  });

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Unit Master
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the buildings and units.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-4">
          <button
            type="button"
            className="inline-flex block bg-white rounded-md border border-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => navigate("/unit-master/create")}
          >
            Create
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      City
                    </th>
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Contact Person
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      State
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getAllUnitMasters.isLoading ? (
                    <tr>
                      <td className="text-center" colSpan={5}>
                        loading...
                      </td>
                    </tr>
                  ) : (
                    getAllUnitMasters?.data.map((unit_master) => (
                      <tr key={unit_master._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {unit_master.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {unit_master.add1.substring(0, 15)}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {unit_master.contact_person_detail?.email}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {unit_master.city}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {unit_master.state}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                            onClick={() =>
                              navigate(`/unit-master/${unit_master._id}`)
                            }
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                            onClick={() => {
                              setCurrentSelectedUnit(unit_master._id);
                              setDeleteModalOpen(true);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        handleDelete={() =>
          deleteUnitMasterMutation.mutate({ id: currentSelectedUnit })
        }
      />

      {/* <AttendanceAnalytics open={showAnalytics} setOpen={setShowAnalytics} /> */}
    </main>
  );
}
