import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import FileInput from "../../../components/Input/FileInput";
import { useMutation } from "@tanstack/react-query";
import { markAttendanceApi } from "../../../services/attendance";
import { toast } from "react-hot-toast";
import SetMap from "../../../components/Maps/SetMap";

export default function AttendancePopup({ open, setOpen, userId }) {
  const [currentImage, setCurrentImage] = useState({
    image: null,
  });
  const [locationPin, setLocationPin] = useState(null);

  const markAttendanceMutation = useMutation({
    mutationFn: markAttendanceApi,
    onSuccess: (data) => {
      toast.success(`Attendance marked successfully`);
      setOpen(null);
    },
  });

  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-105"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-105"
            >
              <Dialog.Panel className="flex w-full min-h-[calc(100vh-74px)] transform text-left text-base transition sm:my-8">
                <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8">
                  <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg font-medium text-gray-900">
                      Mark Attendance
                    </h2>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(null)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <FileInput
                    valKey="image"
                    inputValues={currentImage}
                    setInputValues={(data) => setCurrentImage(data)}
                    isImageCenter={true}
                    isFileInputCamera={true}
                  />

                  <div className="mx-4 flex items-center justify-center">
                    <div className="w-full sm:w-2/3">
                      <SetMap
                        label="Your current location"
                        onSetMap={(data) => setLocationPin(() => data)}
                        allowDragging={false}
                        // isRenderMap={false}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      onClick={() =>
                        markAttendanceMutation.mutate({
                          sourceImagePath: currentImage.image,
                          userId,
                          locationPin
                        })
                      }
                    >
                      {markAttendanceMutation.isLoading ||
                      markAttendanceMutation.isLoading
                        ? "Please wait"
                        : " Mark Attendance"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
