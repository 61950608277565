import { create } from "zustand";

var localStorageToken = localStorage.getItem("storeform-token");
var localStorageUserDetail = JSON.parse(
  localStorage.getItem("storeform-userdetail")
);

export const useUserStore = create((set) => ({
  token: localStorageToken ?? "",

  userDetail: localStorageUserDetail ?? {
    userType: "",
    email: "",
  },

  setToken: (data) =>
    set((state) => {
      localStorage.setItem("storeform-token", data);
      return {
        ...state,
        token: data,
      };
    }),

  setUserDetail: (data) =>
    set((state) => {
      const userData = {
        userType: data.userType,
        email: data.email,
      };
      localStorage.setItem("storeform-userdetail", JSON.stringify(userData));
      // localStorage.setItem("storeform-user-name", )
      localStorage.setItem("storeform-user-type", userData.userType)
      localStorage.setItem("storeform-user-email", userData.email)
      return {
        ...state,
        userDetail: userData,
      };
    }),
}));
