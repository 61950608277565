import * as yup from "yup";

export const docMismatchSummaryFilterValidate = async (values) => {
  
  try {
    const rules = {
      month_string: yup.string().required("Month and Year is required"),
      doc_type: yup.string().required("Document type is required"),
      doc_for: yup.string().required("Document for is required"),
      // reminder_date: yup.string().required("Reminder date is required"),
    };
    
    const schema = yup.object(rules);

    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const uploadDocValidate = async (values) => {
  
  try {
    const rules = {
      filepath: yup.string().required("File is required"),
      doc_group: yup.string().required("Document group is required"),
      doc_type: yup.string().required("Document type is required"),
      doc_for: yup.string().required("Document for is required"),
      // reminder_date: yup.string().required("Reminder date is required"),
    };
    
    const schema = yup.object(rules);

    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const uploadDocSingleValidate = async (fieldName, value) => {
  try {
    const rules = {
      filepath: yup.string().required("File is required"),
      doc_group: yup.string().required("Document group is required"),
      doc_type: yup.string().required("Document type is required"),
      doc_for: yup.string().required("Document for is required"),
      // reminder_date: yup.string().required("Reminder date is required"),
    };
    
    const schema = yup.object(rules);

    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};
