import FileInput from "../../../components/Input/FileInput";
import { useUserDetailStore } from "../../../store/userDetail";

export default function UploadDetail({}) {
  const uploadDetail = useUserDetailStore((state) => state.uploadDetail);
  const setUploadDetail = useUserDetailStore((state) => state.setUploadDetail);

  return (
    <div>
      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
      GST Licence 
      </p>
      <FileInput
        valKey="gst_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       Pancard
      </p>
      <FileInput
        valKey="pancard_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       FSSAI Licence
      </p>
      <FileInput
        valKey="fssai_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       MSME Certificate
      </p>
      <FileInput
        valKey="msme_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       Trade License
      </p>
      <FileInput
        valKey="trade_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       Office Photo
      </p>
      <FileInput
        valKey="office_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       Shop Photo
      </p>
      <FileInput
        valKey="shop_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
       <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 mt-8">
       Godown Photo
      </p>
      <FileInput
        valKey="godown_photo"
        inputValues={uploadDetail}
        setInputValues={setUploadDetail}
      />
    </div>
  );
}
