import React, { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import CustomSelect from "../../components/Input/Select";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUnitMastersApi } from "../../services/unit-master.services";
import {
  createSubUnitMasterApi,
  getSubUnitMasterApi,
  updateSubUnitMasterApi,
} from "../../services/sub-unit-master.services";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const allStatuses = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
]
export default function AddSubUnitMasterPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isContactPersonNewPass, setIsContactPersonNewPass] = useState(false);
  const [subUnitMaster, setSubUnitMaster] = React.useState({
    name: "",
    unit: "",
    remarks: "",
    status: allStatuses[0],
    // contact_person_detail: {
    //   name: "",
    //   email: "",
    //   phone: "",
    // },
  });

  const onChangeField = (data, field, subField) => {
    setSubUnitMaster((prev) => {
      return {
        ...prev,
        [field]: {
          ...prev[field],
          [subField]: data[subField],
        },
      };
    });
  };

  const getAllUnitMasters = useQuery({
    queryKey: ["allunitmasters"],
    retry: false,
    queryFn: () => getUnitMastersApi(),
    onSuccess: (data) => console.log(data),
  });

  const createSubUnitMasterMutation = useMutation({
    mutationFn: createSubUnitMasterApi,
    onSuccess: (data) => {
      console.log(data)
      navigate(-1);
    },
    onError: (err) => {
      // toast.error(err.message);
    },
  });

  const updateSubUnitMasterMutation = useMutation({
    mutationFn: updateSubUnitMasterApi,
    onSuccess: (data) => {
      toast.success("Unit Master updated");
      navigate(-1);
    },
    onError: (err) => {
      // toast.error(err.message);
    },
  });

  const getSubUnitMaster = useQuery({
    queryKey: ["subunitmaster", id],
    retry: false,
    enabled: false,
    staleTime: Infinity,
    queryFn: () => getSubUnitMasterApi(id),
    onSuccess: (data) => {
      const { unit, status, ...rest } = data;
      data.unit = {
        label: unit.name,
        value: unit._id,
      };
      data.status = {
        label: status ? "Active" : "Inactive",
        value: status,
      };

      setSubUnitMaster(data);
    },
  });

  useEffect(() => {
    if (id && id !== "create") {
      getSubUnitMaster.refetch();
    }
  }, [id]);

  const handleSave = () => {
    const payload = {
      ...subUnitMaster,
    };
    payload.unit = payload.unit.value;
    payload.status = payload.status.value ?? false;
    if (id && id !== "create") {
      updateSubUnitMasterMutation.mutate({ id, data: payload });
    } else {
      createSubUnitMasterMutation.mutate(payload);
    }
    console.log(subUnitMaster);
  };

  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-base font-semibold leading-6 text-gray-900 mb-8">
        Add Sub Unit Master
      </h1>

      <div>
        <Input
          placeholder="Enter name"
          title="Name"
          valKey="name"
          inputValues={subUnitMaster}
          setInputValues={setSubUnitMaster}
          isRequired={true}
        />

        <div className="mt-4"></div>
        <CustomSelect
          id="unit"
          name="Unit Master"
          value={subUnitMaster.unit}
          onChange={(e) =>
            setSubUnitMaster((prev) => {
              return {
                ...prev,
                unit: e,
              };
            })
          }
          isRequired={true}
          options={
            getAllUnitMasters?.data
              ? getAllUnitMasters.data?.map((unit) => ({
                  label: unit.name,
                  value: unit._id,
                }))
              : []
          }
        />

        <Input
          placeholder="Enter remarks"
          title="Remarks"
          valKey="remarks"
          inputValues={subUnitMaster}
          setInputValues={setSubUnitMaster}
        />

        <div className="mt-4"></div>
        <CustomSelect
          id="status"
          name="Status"
          value={subUnitMaster.status}
          onChange={(e) =>
            setSubUnitMaster((prev) => {
              return {
                ...prev,
                status: e,
              };
            })
          }
          options={allStatuses.map((unit) => ({
            label: unit.label,
            value: unit.value,
          }))}
          isRequired={true}
        />

        {/* <p className="mt-8">
          Contact person detail <span className="text-red-600">*</span>
        </p>

        <Input
          placeholder="Enter name"
          title="Name"
          valKey="name"
          inputValues={subUnitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "name")
          }
        />
        <Input
          placeholder="Enter email"
          title="Email"
          valKey="email"
          inputValues={subUnitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "email")
          }
        />
        <Input
          placeholder="Enter mobile number"
          title="Mobile"
          valKey="mobile"
          inputValues={subUnitMaster.contact_person_detail}
          setInputValues={(data) =>
            onChangeField(data, "contact_person_detail", "mobile")
          }
        />
        {id === "create" ? (
          <Input
            placeholder="Enter password"
            title="Password"
            valKey="password"
            inputValues={subUnitMaster.contact_person_detail}
            setInputValues={(data) =>
              onChangeField(data, "contact_person_detail", "password")
            }
          />
        ) : (
          <label className="inline-flex items-center cursor-pointer mt-4">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500 rounded-sm"
              checked={isContactPersonNewPass}
              onChange={() => setIsContactPersonNewPass((prev) => !prev)}
            />
            <span className="ml-2 text-gray-700">
              Change existing password?
            </span>
          </label>
        )}
        {isContactPersonNewPass && (
          <Input
            placeholder="Enter new password"
            title="New password"
            valKey="new_password"
            inputValues={subUnitMaster.contact_person_detail}
            setInputValues={(data) =>
              onChangeField(data, "contact_person_detail", "new_password")
            }
          />
        )} */}
      </div>
      <div className="isolate inline-flex rounded-md mt-8 justify-end w-full">
        <button
          type="button"
          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleSave}
        >
          {createSubUnitMasterMutation.isLoading ||
          updateSubUnitMasterMutation.isLoading
            ? "Saving..."
            : "Save"}
        </button>
      </div>
    </main>
  );
}
