import { Navigate, Route, Routes } from "react-router-dom";
import { useUserStore } from "../store/user";

import { useQuery } from "@tanstack/react-query";
import { getUserData } from "../services/user.services";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import HomePage from "../pages/HomePage/HomePage.";
import UserViewPage from "../pages/UserViewPage/UserViewPage";
import UserEditPage from "../pages/UserEditPage/UserEditPage";
import ProfileEditPage from "../pages/UserEditPage/ProfileEditPage";
import SideBar from "../components/Sidebar/Sidebar";
import Appbar from "../components/Appbar/Appbar";
import DocumentPage from "../pages/DocumentPage/DocumentPage";
import DocumentApprovePage from "../pages/DocumentApprovePage/DocumentApprovePage";
import DocumentCartPage from "../pages/DocumentCartPage/DocumentCartPage";
import DocumentMismatchSummary from "../pages/DocumentMismatchSummary/DocumentMismatchSummary";
import UnitMasterPage from "../pages/UnitMasterPage/UnitMasterPage";
import SubUnitMasterPage from "../pages/SubUnitMasterPage/SubUnitMasterPage";
import AddUnitMasterPage from "../pages/AddUnitMasterPage/AddUnitMasterPage";
import AddSubUnitMasterPage from "../pages/AddSubUnitMasterPage/AddSubUnitMasterPage";
import AccessRequestPage from "../pages/AccessRequestPage/AccessRequestPage";
import AllAccessRequestPage from "../pages/AllAccessRequestPage/AllAccessRequestPage";
import AttendancePage from "../pages/AttendancePage/AttendancePage";
import AllAdminUsers from "../pages/AllAdminUsers/AllAdminUsers";
import AdminProfilePage from "../pages/UserEditPage/AdminProfilePage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import AttendanceAnalytics from "../pages/AttendancePage/Analytics";

function CustomRoutes() {
  const token = useUserStore((state) => state.token);
  const setUserDetail = useUserStore((state) => state.setUserDetail);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // const {
  // 	data: userData,
  // 	error,
  // 	isLoading,
  // } = useQuery({
  // 	queryKey: ['getUserData'],
  // 	staleTime: 1000 * 60 * 60 * 1,
  // 	queryFn: getUserData,
  // 	onError: (err) => toast.error(err.message),
  // });

  // useEffect(() => {
  // 	if (userData?.data) {
  // 		setUserDetail(userData.data[0]);
  // 	}
  // }, [userData?.data]);

  console.log("token", token);
  return token ? (
    <div className="bg-gray-50 min-h-screen">
    <Appbar setSidebarOpen={setSidebarOpen}/>
     <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/user" element={<HomePage />} />
        <Route path="/user/:id" element={<UserViewPage />} />
        <Route path="/user-edit/:id" element={<UserEditPage />} />
        <Route path="/profile" element={<ProfileEditPage />} />
        <Route path="/admin-profile" element={<AdminProfilePage />} />
        <Route path="/documents" element={<DocumentPage />} />
        <Route path="/documents/cart" element={<DocumentCartPage />} />
        <Route path="/documents/approve" element={<DocumentApprovePage />} />
        <Route path="/documents/accounts/mismatch-summary" element={<DocumentMismatchSummary />} />
        <Route path="/unit-master" element={<UnitMasterPage />} />
        <Route path="/unit-master/:id" element={<AddUnitMasterPage />} />
        <Route path="/sub-unit-master" element={<SubUnitMasterPage />} />
        <Route path="/sub-unit-master/:id" element={<AddSubUnitMasterPage />} />
        <Route path="/access-request" element={<AllAccessRequestPage />} />
        <Route path="/access-request/:unitMasterId" element={<AccessRequestPage />} />
        <Route path="/attendance/" element={<AttendancePage />} />
        <Route path="/attendance/analytics" element={<AttendanceAnalytics />} />
        <Route path="/all-admin-users" element={<AllAdminUsers />} />
      </Routes>
    </div>
  ) : (
    <Navigate to="/personal-login" />
  );
}

export default CustomRoutes;
