export const statusChip = ({ status }) => {
  let chipColor = "";
  switch (status.toLowerCase()) {
    case "pending":
      chipColor = "bg-yellow-100 text-yellow-800 border-yellow-300";
      break;
    case "approved":
      chipColor = "bg-green-100 text-green-800 border-green-300";
      break;
    case "declined":
      chipColor = "bg-red-100 text-red-800 border-red-300";
      break;
    default:
      chipColor = "bg-gray-100 text-gray-800 border-gray-300";
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${chipColor}`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};


export const statusChipWithText = ({ status, text }) => {
  let chipColor = "";
  switch (status.toLowerCase()) {
    case "pending":
      chipColor = "bg-yellow-100 text-yellow-800 border-yellow-300";
      break;
    case "approved":
      chipColor = "bg-green-100 text-green-800 border-green-300";
      break;
    case "declined":
      chipColor = "bg-red-100 text-red-800 border-red-300";
      break;
    default:
      chipColor = "bg-gray-100 text-gray-800 border-gray-300";
  }

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${chipColor}`}
    >
      {text}
    </span>
  );
};
