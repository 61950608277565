import { create } from "zustand";

export const documentStore = create((set) => ({
  isBulkDocumentUploadModalOpened: false,

  setIsBulkDocumentUploadModalOpened: (data) =>
    set((state) => {
      return {
        ...state,
        isBulkDocumentUploadModalOpened: data,
      };
    }),
}));
