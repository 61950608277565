import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Input from "../../../components/Input/Input";
import { useUserDetailStore } from "../../../store/userDetail";
import {
  personalDetailSingleValidate,
} from "../../../validation/personalValidation";
import { Switch } from "@headlessui/react";
import { useUserStore } from "../../../store/user";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// const userType = localStorage.getItem("storeform-user-type");
export default function PersonalDetail({
  userDetail,
  userId,
  fieldsToDisplay,
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPersonalDetail, setCurrentPersonalDetail] = useState(null);
  const { userType } = useUserStore((state) => state.userDetail);

  const personalDetail = useUserDetailStore((state) => state.personalDetail);
  const setPersonalDetail = useUserDetailStore(
    (state) => state.setPersonalDetail
  );
  const personalError = useUserDetailStore((state) => state.personalError);
  const setPersonalError = useUserDetailStore(
    (state) => state.setPersonalError
  );

  const setCustomPersonalDetail = (data, currentTab) => {
    const updatedData = [...personalDetail];
    updatedData[currentTab] = data;
    setPersonalDetail(updatedData);
  };

  const multiplePersonalDetails =
    userType === "U"
      ? [userDetail?.personal_details[0]]
      : userDetail?.personal_details;

  //   console.log("dffs", personalDetail);
  const renderField = (fieldName) => {
    if (fieldsToDisplay && !fieldsToDisplay.includes(fieldName)) {
      return null;
    }

    switch (fieldName) {
      case "parents_anniversary_date":
      case "father_dob":
      case "father_occupation":
      case "father_mobile":
      case "mother_name":
      case "mother_dob":
      case "mother_occupation":
      case "mother_mobile":
        return (
          <Input
            placeholder={fieldName
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
            title={fieldName
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
            valKey={fieldName}
            inputValues={personalDetail[currentTab]}
            setInputValues={(data) => setCustomPersonalDetail(data, currentTab)}
            singleFieldValidation={personalDetailSingleValidate}
            setFormErrors={(data) => setPersonalError(data, currentTab)}
            formErrors={personalError[currentTab]}
            type={
              fieldName.includes("dob") || fieldName.includes("anniversary")
                ? "date"
                : "text"
            }
          />
        );
      case "is_married":
        return (
          <Switch.Group
            as="div"
            className="flex items-center justify-between mt-8"
          >
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6 text-gray-900"
                passive
              >
                Are you married?
              </Switch.Label>
            </span>
            <Switch
              checked={personalDetail[currentTab]["is_married"] === "Yes"}
              onChange={(selectedItem) => {
                setCustomPersonalDetail(
                  {
                    ...personalDetail[currentTab],
                    is_married: selectedItem ? "Yes" : "No",
                  },
                  currentTab
                );
              }}
              className={classNames(
                personalDetail[currentTab]["is_married"] === "Yes"
                  ? "bg-indigo-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  personalDetail[currentTab]["is_married"] === "Yes"
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </Switch.Group>
        );
      case "anniversary_date":
      case "spouse_name":
      case "spouse_dob":
      case "num_kids":
        return (
          personalDetail[currentTab]["is_married"] === "Yes" && (
            <Input
              placeholder={fieldName
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              title={fieldName
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
              valKey={fieldName}
              inputValues={personalDetail[currentTab]}
              setInputValues={(data) =>
                setCustomPersonalDetail(data, currentTab)
              }
              singleFieldValidation={personalDetailSingleValidate}
              setFormErrors={(data) => setPersonalError(data, currentTab)}
              formErrors={personalError[currentTab]}
            />
          )
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {!userDetail?.is_personal_user ? (
        <>
          <div className="sm:hidden mt-8">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              // defaultValue={userDetail?.personal_details[0]?.personal_type}
              onChange={(e) => {
                setCurrentTab(e.target.value);
                setCurrentPersonalDetail(
                  userDetail?.personal_details[e.target.value]
                );
              }}
              // defaultValue={tabs.find((tab) => tab.current).name}
            >
              {multiplePersonalDetails?.map((personalDetail, currentTab) => (
                <option key={personalDetail._id} value={currentTab}>
                  {personalDetail.personal_type}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block mt-8">
            <nav
              className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
            >
              {multiplePersonalDetails?.map((personalDetail, index) => (
                <div
                  key={personalDetail._id}
                  onClick={() => {
                    setCurrentTab(index);
                    setCurrentPersonalDetail(personalDetail);
                  }}
                  className={classNames(
                    currentTab === index
                      ? "bg-gray-200 text-gray-800"
                      : "text-gray-600 hover:text-gray-800",
                    "px-3 py-2 text-sm font-medium cursor-pointer flex-1 text-center"
                  )}
                  // aria-current={tab.current ? 'page' : undefined}
                >
                  {userType === "U"
                    ? "Personal Details"
                    : personalDetail.personal_type}
                </div>
              ))}
            </nav>
          </div>
        </>
      ) : null}

      <div className="mt-4 rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              aria-hidden="true"
              className="h-5 w-5 text-blue-400"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              We'd like to collect some information about your system to
              personalize your experience. This data will be used to send you
              custom greetings and well-wishes. Your privacy is important to us
              - this information will be visible only to you and not shared with
              anyone else.
            </p>
          </div>
        </div>
      </div>

      {(
        fieldsToDisplay || [
          "parents_anniversary_date",
          "father",
          "father_dob",
          "father_occupation",
          "father_mobile",
          "mother_name",
          "mother_dob",
          "mother_occupation",
          "mother_mobile",
          "is_married",
          "anniversary_date",
          "spouse_name",
          "spouse_dob",
          "num_kids",
        ]
      ).map((fieldName) => renderField(fieldName))}
    </div>
  );
}
