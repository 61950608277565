import { useState } from "react";
import {
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import dummyData from "../data.json";
import { useGetAllUsers } from "../../../http-hooks/useUser";

const dummyDocData = dummyData.data;

export default function DataFilter({ filteredDocs, setFilteredDocs }) {
  const [open, setOpen] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedFor, setSelectedFor] = useState(null);

  const {data : users, isLoading : isUsersLoading} = useGetAllUsers()

  const handleGroupChange = (e, group) => {
    setSelectedGroup(group);
    
    setFilteredDocs((prev) => {
      return {
        ...prev,
        doc_group: group.doc_group,
        doc_type: null,
      };
    });
  };

  const handleTypeChange = (e, type) => {
    setFilteredDocs((prev) => {
      return {
        ...prev,
        doc_type: type.doc_type,
      };
    });
  };

  const handleChangeDocFor = (user) => {
    setSelectedFor(user.legal_name + " - " + user.loginMobile)
    setFilteredDocs((prev) => {
      return {
        ...prev,
        doc_for: user._id,
      };
    });
  };

  return (
    <div className="bg-gray-50">
      <div className="flex items-center justify-end">
        
        <button
          type="button"
          className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
          onClick={() => setOpen(true)}
        >
          Filters
        </button>
        <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
          <Popover as="div" className="relative inline-block text-left">
            <div>
              <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <span>{filteredDocs?.doc_group || "Select Document Group"}</span>
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="space-y-4">
                  {dummyDocData.map((group, idx) => (
                    <div key={`input-${idx}`} className="flex items-center">
                      <input
                        id={`group-${idx}`}
                        name="doc_group"
                        type="radio"
                        checked={group.doc_group == selectedGroup?.doc_group}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) => handleGroupChange(e, group)}
                      />
                      <label
                        htmlFor={`group-${idx}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                      >
                        {group.doc_group}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Transition>
          </Popover>

          {selectedGroup && (
            <Popover as="div" className="relative inline-block text-left">
              <div>
                <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  <span>{filteredDocs?.doc_type || "Select Document Type"}</span>
                  
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Popover.Button>
              </div>

              <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <form className="space-y-4">
                    {selectedGroup.documents.map((doc, idx) => (
                      <div key={idx} className="flex items-center">
                        <input
                          id={`type-${idx}`}
                          name="doc_type"
                          type="radio"
                          checked={filteredDocs?.doc_type === (doc.doc_type)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          onChange={(e) =>
                            handleTypeChange(e, doc)
                          }
                        />
                        <label
                          htmlFor={`type-${idx}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                        >
                          {doc.doc_type}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Transition>
            </Popover>
          )}

          <Popover as="div" className="relative inline-block text-left">
            <div>
              <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
              <span>{selectedFor || "Select Document For"}</span>
                
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="space-y-4">
                  {users.map((user, idx) => (
                    <div key={idx} className="flex items-center">
                      <input
                        id={`user-${idx}`}
                        name="doc_for"
                        type="radio"
                        checked={filteredDocs?.doc_for === user._id}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={() => handleChangeDocFor(user)}
                      />
                      <label
                        htmlFor={`user-${idx}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                      >
                        {user.legal_name + " - " + user.loginMobile}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
      </div>
    </div>
  );
}
