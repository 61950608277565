import React, { Fragment, useEffect, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { documentStore } from "../../../store/documents";
import FileInput from "../../../components/Input/FileInput";
import {
  uploadBulkDocApi,
  extractFilePropertiesFromBulkDocApi,
} from "../../../services/document.services";
import { useMutation } from "@tanstack/react-query";
import { createUserApi } from "../../../services/user.services";
import { toast } from "react-hot-toast";

const bulkUploadInitialState = {
    filepath: null,
    dataFrom: "GSTR",
    doc_for: null,
    doc_type: null,
    doc_group: null,
    doc_month : null
  }
function BulkUploadDocModal() {
  const isBulkDocumentUploadModalOpened = documentStore(
    (state) => state.isBulkDocumentUploadModalOpened
  );
  const setIsBulkDocumentUploadModalOpened = documentStore(
    (state) => state.setIsBulkDocumentUploadModalOpened
  );

  const [bulkUploadDetail, setBulkUploadDetail] = useState(bulkUploadInitialState);

  const [file, setFile] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const uploadCsvMutation = useMutation({
    mutationFn: uploadBulkDocApi,
    onSuccess: (data) => {
      setIsBulkDocumentUploadModalOpened(false);
      setCurrentStep(1)
      setBulkUploadDetail((prev) => {
        return bulkUploadInitialState
      })
      toast.success(`${data.uploaded_data.length} docs imported. ${data.duplicate_data.length} docs were duplicate`);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const extractFilePropertiesMutation = useMutation({
    mutationFn: extractFilePropertiesFromBulkDocApi,
    onSuccess: (data) => {
      console.log(data);
      setBulkUploadDetail((prev) => {
        return {
          ...prev,
          doc_for: data.doc_for,
          doc_type: data.doc_type,
          doc_group: data.doc_group,
          doc_month : data.doc_month,
        };
      });
      setCurrentStep(2);
      toast.success(
        "Meta Data Fetched from this file. You can proceed to extract data now"
      );
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  useEffect(() => {
    if (file) {
    }
  }, [file]);

  return (
    <Transition.Root show={isBulkDocumentUploadModalOpened} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsBulkDocumentUploadModalOpened(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900 text-center"
                >
                  Upload Excel{" "}
                </Dialog.Title>

                <div>
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Data From
                  </label>
                  <select
                    id="location"
                    name="location"
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={bulkUploadDetail.dataFrom}
                    onChange={(e) =>
                      setBulkUploadDetail((prev) => {
                        return { ...prev, dataFrom: e.target.value };
                      })
                    }
                  >
                    <option>GSTR</option>
                    <option>TALLY</option>
                  </select>
                </div>
                <FileInput
                  valKey="filepath"
                  inputValues={bulkUploadDetail}
                  setInputValues={setBulkUploadDetail}
                  setFile={(f) => setFile(f)}
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />

                {currentStep === 2 && bulkUploadDetail ? (
                  <div className="flex flex-col w-full my-2">
                    <div className="text-sm">
                      <span className="font-semibold">Doc For: </span>
                      {bulkUploadDetail.doc_for?.legal_name ||
                        bulkUploadDetail.doc_for?.loginMobile}
                    </div>
                    <div className="text-sm">
                      <span className="font-semibold">Doc Group: </span>
                      {bulkUploadDetail.doc_group}
                    </div>
                    <div className="text-sm">
                      <span className="font-semibold">Doc Type: </span>
                      {bulkUploadDetail.doc_type}
                    </div>
                  </div>
                ) : null}
                <div className="mt-5 sm:mt-6">
                  {currentStep === 2 || bulkUploadDetail.dataFrom === "TALLY" ? (
                    <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      uploadCsvMutation.mutate({
                        data_from: bulkUploadDetail.dataFrom,
                        doc_group: bulkUploadDetail.doc_group,
                        doc_type: bulkUploadDetail.doc_type,
                        doc_for: bulkUploadDetail.doc_for?._id,
                        filepath: bulkUploadDetail.filepath,
                        doc_month : bulkUploadDetail.doc_month
                        // users: bulkUploadDetail.users,
                      });
                    }}
                    disabled={uploadCsvMutation.isLoading}
                  >
                    {uploadCsvMutation.isLoading
                      ? "Uploading..."
                      : "Upload excel"}
                  </button>) : (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        extractFilePropertiesMutation.mutate({
                          filepath: file?.name,
                          dataFrom: bulkUploadDetail?.dataFrom,
                        });
                      }}
                      disabled={uploadCsvMutation.isLoading}
                    >
                      {extractFilePropertiesMutation.isLoading
                        ? "Loading..."
                        : "Proceed"}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default BulkUploadDocModal;
