import * as yup from "yup";

const rules = {
  legal_name: yup.string().required("Legal Name is required"),
  trade_name: yup.string().required("Trade Name is required"),
  // center_jurisdiction: yup.string().required("Center Jurisdiction is required"),
  // state_jurisdiction: yup.string().required("State Jurisdiction is required"),
  taxpayer_type: yup.string().required("Taxpayer Type is required"),
  date_of_gst_registration: yup.string().required("Date of GST Registration is required"),
  office_address: yup.string().required("Office Address is required"),
  state: yup.string().required("State is required"),
  directors: yup.string().required("Directors is required"), 
};

const schema = yup.object(rules);

export const businessDetailValidate = async (values) => {
  try {
    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const businessDetailSingleValidate = async (fieldName, value) => {
  try {
    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};
