import { useQuery, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
    getSubUnitMastersApi
} from "../services/sub-unit-master.services";

export const useGetAllSubUnitMasters = (filter={}) => {
  return useQuery({
    queryKey: ["sub-unit-masters", filter],
    queryFn: () => getSubUnitMastersApi(filter),
    onError: (error) => {
      toast.error(error.message || "Failed to fetch sub units");
    }
  });
};

// export const useGetAdminUser = (userId) => {
//   return useQuery({
//     queryKey: ["admin-user", userId],
//     queryFn: () => getAdminUserApi(userId),
//     enabled: !!userId,
//     onError: (error) => {
//       toast.error(error.message || "Failed to fetch admin user");
//     }
//   });
// };

// export const useDeleteAdminUser = () => {
//   return useMutation({
//     mutationFn: deleteAdminUserApi,
//     onSuccess: () => {
//       toast.success("Admin user deleted successfully");
//     },
//     onError: (error) => {
//       toast.error(error.message || "Failed to delete admin user");
//     }
//   });
// };