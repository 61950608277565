import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddDocModal from "../DocumentPage/components/AddDocModal";
import Input from "../../components/Input/Input";
import { uploadDocRequestApi } from "../../services/document.services";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

export default function DocumentCartPage() {
  const { state: data } = useLocation();
  const navigate = useNavigate();

  const [addDocModal, setAddDocModal] = useState(false);
  const [currentSelectedDoc, setCurrentSelectedDoc] = useState("");
  const [formValues, setFormValues] = useState({
    purpose: "",
    docs: data || [],
  });

  const requestDocMutation = useMutation((data) => uploadDocRequestApi(data), {
    onSuccess: (data) => {
      toast.success(data.msg);
      navigate(-1);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Documents for Approval
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            List of documents that needs to be approved
          </p>
        </div>
      </div>

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Doc Group
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Doc Type
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Doc For
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status{" "}
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data.map((doc) => (
              <tr key={doc._id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {doc.doc_group}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {doc.doc_type}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {doc.doc_for.Legal_name || doc.doc_for.loginMobile}
                </td>
                <td
                  className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 `}
                >
                  {doc.status}
                </td>

                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                    onClick={() => {
                      setCurrentSelectedDoc(doc);
                      setAddDocModal(true);
                    }}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Input
        placeholder=""
        title="Purpose"
        valKey="purpose"
        inputValues={formValues}
        setInputValues={setFormValues}
      />

      <div className="mt-8 flex justify-end">
        <button
          type="submit"
          className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          disabled={requestDocMutation.isLoading}
          onClick={() =>
            requestDocMutation.mutate({
              purpose: formValues.purpose,
              docs: [
                ...formValues.docs.map((doc) => {
                  // return {
                  //   doc_group: doc.doc_group,
                  //   doc_type: doc.doc_type,
                  //   doc_for: doc.doc_for._id,
                  // };
                  return doc._id
                }),
              ],
            })
          }
        >
          {requestDocMutation.isLoading ? "Loading..." : "Request Doc"}
        </button>
      </div>
      {/* Add doc modal */}
      <AddDocModal
        open={addDocModal}
        setOpen={setAddDocModal}
        currentData={currentSelectedDoc}
        setCurrentSelectedDoc={setCurrentSelectedDoc}
        isView={true}
      />
    </main>
  );
}
