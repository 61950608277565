import React, { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import PersonalDetail from "../UserEditPage/components/PersonalDetail";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCurrentUserDetailApi,
  updateUserDetailApi,
} from "../../services/user.services";
import { getUnitMasterApi } from "../../services/unit-master.services";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../UserViewPage/components/Card";
import { useUserDetailStore } from "../../store/userDetail";
import CustomSelect from "../../components/Input/Select";
import Input from "../../components/Input/Input";
import { createAccessRequestApi } from "../../services/request-access";
import { useGetAllSubUnitMasters } from "../../http-hooks/useSubUnit";

export default function AccessRequestPage() {
  const navigate = useNavigate();
  const { unitMasterId } = useParams();
  const personalDetail = useUserDetailStore((state) => state.personalDetail);
  const setPersonalDetail = useUserDetailStore(
    (state) => state.setPersonalDetail
  );
  const [data, setData] = useState({
    remarks: "",
    meet_with : "",
    subUnit: null,
  });

  const {data : subUnitsData, isLoading : isLoadingSubUnits} = useGetAllSubUnitMasters()

  const getUseDetailQuery = useQuery({
    queryKey: ["getCurrentUserDetail"],
    queryFn: async () => {
      return getCurrentUserDetailApi();
    },
    onSuccess: (data) => {
      // this will take the manager1 otherwise default one will be shown
      setPersonalDetail(data.data.personal_details);
    },
    onError: (err) => toast.error(err.message),
  });

  const getUnitMaster = useQuery({
    queryKey: ["unitmaster", unitMasterId],
    retry: false,
    staleTime: Infinity,
    queryFn: () => getUnitMasterApi(unitMasterId),
    onSuccess: (data) => {},
  });

  const userEditMutation = useMutation({
    mutationFn: updateUserDetailApi,
    onSuccess: (data) => {
      toast.success("Data updated successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const createAccessRequestMutation = useMutation({
    mutationFn: createAccessRequestApi,
    onSuccess: (data) => {
      toast.success("Request created successfully");
    },
  });

  const handleRequestButton = () => {
    if(!checkIfMinimumKYCCompleted()) return toast.error("Please fill the required information for KYC")

    if (!data.subUnit) {
      toast.error("Please select a sub unit");
      return;
    }

    if (!data.remarks) {
      toast.error("Please enter your purpose");
      return;
    }

    if (!data.meet_with) {
      toast.error("Please enter whom you want to meet");
      return;
    }

    Promise.all([
      createAccessRequestMutation.mutateAsync({
        subUnit: data.subUnit.value,
        purpose: data.remarks,
        meet_with: data.meet_with,
      }),

      userEditMutation.mutateAsync({
        userId: getUseDetailQuery.data.data._id,
        data: { personal_details: personalDetail },
      }),
    ]).then(() => {
      navigate("/access-request");
    });
  };

  const checkIfMinimumKYCCompleted = () => {
    // return true
    return (personalDetail?.[0]?.is_aadhar_verified && personalDetail?.[0]?.aadhar)
  }

  return (
    <main className="py-10 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {getUseDetailQuery.isLoading ? (
        "Loading"
      ) : (
        <>
          {getUnitMaster.isLoading ? (
            "Loading"
          ) : (
            <>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Unit Master Information
              </h2>
              
              {
                !checkIfMinimumKYCCompleted() ? <AlertMessage /> : null
              }
              

              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Card label="Name" value={getUnitMaster.data.name} />
                </div>
                <div className="sm:col-span-3">
                  <Card label="State" value={getUnitMaster.data.state} />
                </div>
                <div className="sm:col-span-3">
                  <Card label="City" value={getUnitMaster.data.city} />
                </div>
                <div className="sm:col-span-3">
                  <Card label="Pincode" value={getUnitMaster.data.pincode} />
                </div>
                <div className="sm:col-span-3">
                  <Card label="Address 1" value={getUnitMaster.data.add1} />
                </div>
                <div className="sm:col-span-3">
                  <Card label="Address 2" value={getUnitMaster.data.add2} />
                </div>
              </div>
            </>
          )}
          <div className="mt-8"></div>
          {isLoadingSubUnits ? (
            "Loading"
          ) : (
            <CustomSelect
              id="subUnit"
              name="Select a sub unit"
              value={data.subUnit}
              onChange={(e) => setData({ ...data, subUnit: e })}
              isRequired={true}
              options={
                subUnitsData
                  ? subUnitsData?.map((sub_unit) => ({
                      label: sub_unit.name,
                      value: sub_unit._id,
                    }))
                  : []
              }
            />
          )}
          <Input
            title={"Purpose"}
            placeholder={"Enter your visit purpose"}
            valKey={"remarks"}
            inputValues={data}
            setInputValues={setData}
            isRequired={true}
            isTextArea={true}
          />

<Input
            title={"Meeting With?"}
            placeholder={"Enter whom you want to meet "}
            valKey={"meet_with"}
            inputValues={data}
            setInputValues={setData}
            isRequired={true}
            isTextArea={false}
          />

          <PersonalDetail
            userDetail={getUseDetailQuery?.data?.data}
            isLoading={getUseDetailQuery.isLoading}
            // fieldsToDisplay={[
            //   "name",
            //   "email",
            //   "dob",
            //   "gender",
            //   "address",
            //   "city",
            //   "state",
            //   "pincode",
            //   "district",
            //   "aadhar",
            //   "user_photo",
            // ]}
          />
        </>
      )}
      <button
        type="button"
        className="rounded-md bg-white px-2 py-2 text-indigo-400 ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50 w-full text-center mt-8"
        onClick={handleRequestButton}
      >
        <span className="text-sm">
          {createAccessRequestMutation.isLoading || userEditMutation.isLoading
            ? "Loading"
            : "Request Access"}
        </span>
      </button>
    </main>
  );
}

function AlertMessage() {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-yellow-400"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-yellow-700">
            Hi, you would need to complete your KYC before requesting a gatepass. Please complete it below.
          </p>
          {/* <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <a
              href="#"
              className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
            >
              Details
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}
