import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { getAccountsYearlyDocMismatchSummaryApi } from "../../services/document.services";
import CustomSelect from "../../components/Input/Select";
import { getAllUserDataApi } from "../../services/user.services";
import dummyData from "../../utils/data/dummy_data.json";
const dummyDocData = dummyData.data;

const fys = [{ value: "2023-2024", label: "23-24" },{ value: "2024-2025", label: "24-25" }]
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export function YearlyData() {
  const [fy, setFY] = useState(fys[1]);
  const [docFor, setDocFor] = useState(null);
  const [docType, setDocType] = useState(null);
  const [formError, setFormError] = useState({
    doc_type: null,
    doc_for: null,
  });

  const getAllUsersQuery = useQuery({
    queryKey: ["allusers"],
    enabled: true,
    queryFn: () => getAllUserDataApi(),
  });

  const { data: apiData, isLoading } = useQuery({
    queryKey: [
      "allAccountsYearlyMismatchDocsSummary",
      fy?.value,
      docFor?.value,
      docType?.value,
    ],
    retry: false,
    queryFn: () => {
      return getAccountsYearlyDocMismatchSummaryApi({
        fy : fy?.value,
        doc_for: docFor?.value,
        doc_type: docType?.value,
      });
    },
    onSuccess: (data) => console.log(data),
    onError: (error) => toast.error(error.message),
  });

  let labels = [],
    data = {};
  if (apiData) {
    labels = apiData.labels;
    data = {
      labels,
      datasets: [
        {
          label: "Non Reconciled Tally Docs",
          data: apiData.values.map((k) => k.tally),
          backgroundColor: "rgb(255, 99, 132)",
          stack: "Stack 0",
        },
        {
          label: "Non Reconciled GST Docs",
          data: apiData.values.map((k) => k.gst),
          backgroundColor: "rgb(53, 162, 235)",
          stack: "Stack 0",
        },
        {
          label: "Reconciled Documents",
          data: apiData.values.map((k) => k.common),
          backgroundColor: "rgb(75, 192, 192)",
          stack: "Stack 0",
        },
      ],
    };
  }

  return (
    <div>
      <div className="w-full flex gap-2">
        <CustomSelect
          id="fy"
          name="Financial Year"
          options={fys}
          formError={formError}
          setFormErrors={setFormError}
          // singleFieldValidation={docMismatchSummaryFilterValidate}
          value={fy}
          onChange={(e) => setFY(e)}
          className="mb-4"
        />
        <CustomSelect
          id="doc_type"
          name="Doc Type"
          options={
            dummyDocData
              .filter((item) => item.doc_group === "Accounts")[0]
              ?.["documents"].map((item) => ({
                value: item.doc_type,
                label: item.doc_type,
              })) || []
          }
          formError={formError}
          setFormErrors={setFormError}
          // singleFieldValidation={docMismatchSummaryFilterValidate}
          value={docType}
          onChange={(e) => setDocType(e)}
          className="mb-4"
        />
        <CustomSelect
          id="doc_for"
          name="Doc For"
          options={
            getAllUsersQuery.data?.data.map((item) => ({
              value: item._id,
              label: item.legal_name + " - " + item.loginMobile,
            })) || []
          }
          formError={formError}
          setFormErrors={setFormError}
          // singleFieldValidation={docMismatchSummaryFilterValidate}
          value={docFor}
          onChange={(e) => setDocFor(e)}
          className="mb-4"
        />
      </div>
      <div className="w-full">
        {!isLoading && data ? (
          <Bar options={options} data={data} />
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
}
