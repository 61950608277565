import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MapPinIcon from "../../assets/images/map-pointer.png"
const ShowMap = ({ lat, lng, zoom = 13 }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      // Initialize the map
      mapInstanceRef.current = L.map(mapRef.current).setView([lat, lng], zoom);

      // Add the OpenStreetMap tiles
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapInstanceRef.current);

      // Create a custom icon
      const customIcon = L.icon({
        iconUrl: MapPinIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });

      // Add a marker at the specified location with the custom icon
      L.marker([lat, lng], { icon: customIcon }).addTo(mapInstanceRef.current)
        .bindPopup('Your location')
        .openPopup();
    }

    // Clean up function
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, [lat, lng, zoom]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.setView([lat, lng], zoom);
    }
  }, [lat, lng, zoom]);

  return (
    <div ref={mapRef} style={{ height: '400px', width: '100%' }} />
  );
};

export default ShowMap;