import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { Toaster } from "react-hot-toast";
import HomePage from "./pages/HomePage/HomePage.";
import LoginPage from "./pages/LoginPage/LoginPage";
import PersonalLoginPage from "./pages/LoginPage/PersonalLoginPage";
import BusinessLoginPage from "./pages/LoginPage/BusinessLoginPage";
import AdminLoginPage from "./pages/LoginPage/AdminLoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";

import React, { useEffect, useState } from "react";

import CustomRoutes from "./routes/CustomRoutes";
import TicketPage from "./pages/TicketPage/TicketPage";

function App() {
  return (
    <BrowserRouter>
      {CustomToast()}
      <Routes>
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/admin-login" exact element={<AdminLoginPage />} />
        {/* <Route path="/employee-login" exact element={<EmployeeLoginPage />} /> */}
        <Route path="/personal-login" exact element={<PersonalLoginPage />} />
        <Route path="/business-login" exact element={<BusinessLoginPage />} />
        <Route path="/ticket-confirm/:id" exact element={<TicketPage />} />
        {/* <Route
          path="/signup"
          exact
          element={
            <SignupPage />
          }
        /> */}
        {/* <Route
          path="/*"
          element={
            <Root />
          }
        />  */}
        <Route path="/*" element={<CustomRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const CustomToast = () => {
  return createPortal(
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{
        position: "fixed",
        // zIndex: "999999"
      }}
      toastOptions={{
        // Define default options
        className: "toaster-style text-sm rounded-md",
        duration: 5000,
        style: {
          background: "#333",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          style: {
            background: "#f0fdf4",
            color: "#15803d",
          },
        },
        error: {
          style: {
            background: "#fef2f2",
            color: "#b91c1c",
          },
        },
      }}
    />,
    document.getElementById("alert-modal")
  );
};
