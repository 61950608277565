import Input from "../../../components/Input/Input";
import { useUserDetailStore } from "../../../store/userDetail";
import { businessDetailSingleValidate } from "../../../validation/businessValidation";

export default function BusinessDetail({}) {
  const statutoryDetail = useUserDetailStore((state) => state.statutoryDetail);

  const businessDetail = useUserDetailStore((state) => state.businessDetail);
  const setBusinessDetail = useUserDetailStore(
    (state) => state.setBusinessDetail
  );
  const businessError = useUserDetailStore((state) => state.businessError);
  const setBusinessError = useUserDetailStore(
    (state) => state.setBusinessError
  );

  return (
    <div>
      <Input
        placeholder="Legal Name"
        title="Legal Name"
        valKey="legal_name"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
      <Input
        placeholder="Trade Name"
        title="Trade Name"
        valKey="trade_name"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
      {/* <Input
            placeholder="Center Jurisdiction"
            title="Center Jurisdiction"
            valKey="center_jurisdiction"
            inputValues={businessDetail}
            setInputValues={setBusinessDetail}
            singleFieldValidation={businessDetailSingleValidate}
            setFormErrors={setBusinessError}
            formErrors={businessError}
            disabled={statutoryDetail.is_gst_verified >= 1}
          /> */}
      {/* <Input
            placeholder="State Jurisdiction"
            title="State Jurisdiction"
            valKey="state_jurisdiction"
            inputValues={businessDetail}
            setInputValues={setBusinessDetail}
            singleFieldValidation={businessDetailSingleValidate}
            setFormErrors={setBusinessError}
            formErrors={businessError}
            disabled={statutoryDetail.is_gst_verified >= 1}
          /> */}
      <Input
        placeholder="Taxpayer Type"
        title="Taxpayer Type"
        valKey="taxpayer_type"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
      {/* <Input
            placeholder="Date of GST Registration (DD-MM-YYYY)"
            title="Date of GST Registration"
            valKey="date_of_gst_registration"
            inputValues={businessDetail}
            setInputValues={setBusinessDetail}
            singleFieldValidation={businessDetailSingleValidate}
            setFormErrors={setBusinessError}
            formErrors={businessError}
            disabled={statutoryDetail.is_gst_verified >= 1}
            // isDate={true}
          /> */}
      <Input
        placeholder="Office Address"
        title="Office Address"
        valKey="office_address"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        isTextArea={true}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
      <Input
        placeholder="State"
        title="State"
        valKey="state"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
      <Input
        placeholder="Directors Name"
        title="Directors"
        valKey="directors"
        inputValues={businessDetail}
        setInputValues={setBusinessDetail}
        singleFieldValidation={businessDetailSingleValidate}
        setFormErrors={setBusinessError}
        formErrors={businessError}
        disabled={statutoryDetail.is_gst_verified >= 1}
      />
    </div>
  );
}
