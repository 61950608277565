import React from "react";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getUnitMasterGaurdsApi } from "../../services/unit-master.services";
import AttendancePopup from "./components/AttendancePopup";
// import AttendanceAnalytics from "./components/AttendanceAnalytics";
import {useGetAllAdminUsers} from "../../http-hooks/useAdminUser"
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function AllAdminUsers() {
  const navigate = useNavigate();
  const [currentGaurdId, setCurrentGaurdId] = React.useState(null);
  const [showAnalytics, setShowAnalytics] = React.useState(false);

  const getAllGaurds = useGetAllAdminUsers()

  const checkTime = (guardData) => {
    const now = new Date();
    let currentTime = now.getHours() * 60 + now.getMinutes();
    
    const [startHour, startMinute] = guardData.start_shift.split(':').map(Number);
    const [endHour, endMinute] = guardData.end_shift.split(':').map(Number);
    
    let startTime = startHour * 60 + startMinute;
    let endTime = endHour * 60 + endMinute;
    
    // Handle overnight shifts
    if (endTime < startTime) {
      endTime += 24 * 60; // Add 24 hours to end time
      if (currentTime < startTime) {
        currentTime += 24 * 60; // Add 24 hours to current time if it's past midnight
      }
    }
    
    return currentTime >= startTime && currentTime <= endTime;
  };

  const handleMarkAttendance = (guard) => {
    if (checkTime(guard)) {
      setCurrentGaurdId(guard._id);
      setShowAnalytics(false);
    }
  }

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Unit Master Gaurds
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the gaurds in the unit master
          </p>
        </div>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          onClick={() => setShowAnalytics(true)}
        >
          Attendance Analytics
        </button>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                  <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Photo
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Mobile
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Shifts
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last Attendance At
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getAllGaurds.isLoading ? (
                    <p>loading...</p>
                  ) : (
                    getAllGaurds?.data.map((gaurd) => (
                      <tr key={gaurd._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {
                            gaurd.image ? (<img alt="" className="w-[40px] rounded" src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${gaurd.image}`} />) : null
                          }
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {gaurd?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {gaurd?.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {gaurd?.mobile}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {gaurd?.start_shift} - {gaurd?.end_shift} 
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {gaurd?.lastAttendance ? dayjs(gaurd?.lastAttendance?.createdAt).fromNow() : null}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                            onClick={() =>
                              // alert("Marking present is not implemented yet")
                              handleMarkAttendance(gaurd)
                            }
                          >
                            Mark Present
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      { currentGaurdId ? <AttendancePopup open={currentGaurdId} setOpen={setCurrentGaurdId} />  : null }
     
    </main>
  );
}
