import * as yup from "yup";

const rules = {
  account_number: yup.string().required("Account Number is required"),
  ifsc: yup.string().required("IFSC Code is required"),
  bank_name: yup.string().required("Bank Name is required"),
  branch: yup.string().required("Branch is required"),
  holder_name: yup.string().required("Account Holder Name is required"),
  city: yup.string().required("City is required"),
};

const schema = yup.object(rules);

export const bankDetailValidate = async (values) => {
  try {
    // Validate the values against the schema
    await schema.validate(values, { abortEarly: false });

    // If validation is successful, return an empty errors object
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    // Validation failed, collect errors for each field
    const errors = {};

    // Loop through the error objects and populate the errors object
    error.inner.forEach((e) => {
      errors[e.path] = e.message;
    });

    return {
      isValid: false,
      errors,
    };
  }
};

export const bankDetailSingleValidate = async (fieldName, value) => {
  try {
    const fieldSchema = yup.object().shape({
      [fieldName]: schema.fields[fieldName], // Get the schema for the specified field
    });

    await fieldSchema.validate({ [fieldName]: value }, { abortEarly: false });
    return {
      isValid: true,
      errors: {},
    };
  } catch (error) {
    const errors = {};
    if (error.inner && error.inner.length > 0) {
      // Return the first error encountered
      errors[fieldName] = error.inner[0].message;
      return {
        isValid: false,
        errors,
      };
    } else {
      // If it's not an inner error, handle it as a single error
      errors[fieldName] = error.message;
      return {
        isValid: false,
        errors,
      };
    }
  }
};
