import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { getAccessRequestApi } from "../../../services/request-access";
import { statusChip } from "../../../utils/status-chip";

export default function ViewRequestModal({ open, setOpen, currentId }) {
  const getAccessRequestQuery = useQuery({
    queryKey: ["allaccessrequest", currentId],
    retry: false,
    staleTime: Infinity,
    enabled: !!currentId,
    queryFn: () => getAccessRequestApi(currentId),
    onSuccess: (data) => console.log(data),
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-105"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-105"
            >
              <Dialog.Panel className="flex w-full min-h-[calc(100vh-74px)] transform text-left text-base transition sm:my-8">
                <div className="relative flex w-full flex-col overflow-hidden bg-white pb-8 pt-6 sm:rounded-lg sm:pb-6 lg:py-8">
                  <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
                    <h2 className="text-lg font-medium text-gray-900">
                      Request Details
                    </h2>
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(null)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {getAccessRequestQuery.isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="grid grid-cols-2 gap-4 px-4 sm:px-6 lg:px-8 mt-8">
                         <div>
                        <p className="text-sm font-medium text-gray-500">
                          Requester
                        </p>
                        {/* <p className="mt-1">
                          ID: {getAccessRequestQuery.data.requester._id}
                        </p> */}
                        <p className="mt-1">
                          {getAccessRequestQuery.data.requester?.personal_details?.[0]?.user_photo ? <img className="w-32" alt="" src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${getAccessRequestQuery.data.requester?.personal_details?.[0]?.user_photo}`} /> : null}
                        </p>
                        <p className="mt-1">
                          Mobile:{" "}
                          {getAccessRequestQuery.data.requester.loginMobile}
                        </p>
                      </div>

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Sub Unit Approval
                        </p>
                        {statusChip({
                          status:
                            getAccessRequestQuery.data.subUnitApproval.status,
                        })}
                      </div>

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Sub Unit
                        </p>
                        {/* <p className="mt-1">
                          ID: {getAccessRequestQuery.data.subUnit._id}
                        </p> */}
                        <p className="mt-1">
                          Name: {getAccessRequestQuery.data.subUnit.name}
                        </p>
                      </div>

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Guard Approval
                        </p>
                        {statusChip({
                          status:
                            getAccessRequestQuery.data.guardApproval.status,
                        })}
                      </div>

                      

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Purpose
                        </p>
                        <p className="mt-1">
                          {getAccessRequestQuery.data.purpose}
                        </p>
                      </div>

                     

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Overall Status
                        </p>
                        {statusChip({
                          status: getAccessRequestQuery.data.status,
                        })}
                      </div>
                      

                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Created At
                        </p>
                        <p className="mt-1">
                          {formatDate(getAccessRequestQuery.data.createdAt)}
                        </p>
                      </div>

                     
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
