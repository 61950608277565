import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { uploadFilesApi } from "../../services/upload.services";
import { useRef } from "react";
import { MinusCircleIcon } from "@heroicons/react/20/solid";

export default function FileInput({
  inputValues,
  setInputValues,
  valKey,
  isProfile = false,
  accept = "image/* pdf/*",
  isFileInputCamera = false,
  isImageCenter = false,
  setFile = () => {}
}) {
  const inputRef = useRef(null);

  // uri:process.env.EXPO_PUBLIC_BACKEND_IMAGE_URI+ inputValues[valKey]
  const uploadImagesMutation = useMutation({
    mutationFn: uploadFilesApi,
    onSuccess: (data) => {
      console.log("response", data);
      setInputValues({
        ...inputValues,
        [valKey]: data.filePath,
      });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleFileUpload = (file) => {
    const obj = {
      uri: URL.createObjectURL(file),
      name: file.name,
      type: file.type,
    };
    const formData = new FormData();
    formData.append("file", file);
    uploadImagesMutation.mutate(formData);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFile(file)
      handleFileUpload(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file)
      handleFileUpload(file);
    }
  };

  const getFileExtension = (filename) => {
    return filename?.split(".").pop().toLowerCase() ?? "png";
  };

  const getFileName = (filename) => {
    const fileArray = filename?.split("/");
    return fileArray[fileArray.length - 1];
  };

  const handleRemoveFile = () => {
    setInputValues({
      ...inputValues,
      [valKey]: "",
    });
  };

  return (
    <form
      className="flex items-center justify-center space-x-6 mt-4 w-full"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {inputValues[valKey] ? (
        <div className={`shrink-0 flex gap-2 items-start w-full ${isImageCenter? 'justify-center': ''}`}>
          {getFileExtension(inputValues[valKey]) === "pdf" ? (
            // <div className="text-sm underline">
            //   <a
            //     href={
            //       process.env.REACT_APP_BACKEND_IMAGE_URL + inputValues[valKey]
            //     }
            //     target="_blank"
            //     rel="noopener noreferrer"
            //   >
            //     Click to view pdf
            //   </a>
            // </div>
            <iframe
              src={
                process.env.REACT_APP_BACKEND_IMAGE_URL + inputValues[valKey]
              }
              width="100%"
              height="500px"
              title="Image"
            />
          ) : ["png", "jpg", "jpeg"].includes(
              getFileExtension(inputValues[valKey])
            ) ? (
            <img
              className={`${
                isProfile ? "h-16 w-16 rounded-full" : "h-64 rounded-sm"
              } object-cover`}
              src={
                process.env.REACT_APP_BACKEND_IMAGE_URL + inputValues[valKey]
              }
              alt="Current profile"
            />
          ) : (
            <span className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              {getFileName(inputValues[valKey])}
              {/* <button
                type="button"
                className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
              >
                <span className="sr-only">Remove</span>
                <svg
                  viewBox="0 0 14 14"
                  className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75"
                >
                  <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
              </button> */}
            </span>
          )}
          <button
            type="button"
            className="inline-flex items-center gap-x-1 rounded-md border-red-600 border px-1.5 py-1 text-xs  text-red-600 shadow-s focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ews-600"
            onClick={handleRemoveFile}
          >
            <MinusCircleIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
            Remove
          </button>
        </div>
      ) : (
        <label className="block">
          <span className="sr-only">Choose profile photo</span>
          <input
            type="file"
            accept={accept}
            className="hidden 
              "
            onChange={handleFileInputChange}
            ref={inputRef}
            {...(isFileInputCamera ? { capture: "user" } : {})}
          />
          <div
            className="w-full h-64 rounded border border-dashed border-gray-400 opacity-75 flex flex-col items-center justify-center cursor-pointer p-8"
            // onClick={() => inputRef.current.click()}
          >
            <p className="text-gray-500 text-sm">
              Drag & drop or click to select a file
            </p>
          </div>
        </label>
      )}
    </form>
  );
}
