import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const userLoginApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/auth/admin", data);
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const createUserApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/user", data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const editUserRoleApi = async (userId, data) => {
  try {
    const res = await axios.put(`${BASE_URL}/user/${userId}/assign`, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllUserDataApi = async (filter={}) => {
  try {
    let queryStringArr = []
    for(const filterKey in filter) {
      if(filter[filterKey] !== undefined && filter[filterKey] !== null) {
        queryStringArr.push(`${filterKey}=${filter[filterKey]}`)
      }
    }
    const queryParams = queryStringArr.join('&');
    const res = await axios.get(`${BASE_URL}/user?${queryParams}`, {
      headers: {
        ...authHeader(),
      },
    });

    return res.data?.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getUserDetailApi = async (data) => {
  try {
    const res = await axios.get(BASE_URL + "/user/" + data.id, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};


export const getAdminuserDetailApi = async (data) => {
  try {
    const res = await axios.get(BASE_URL + "/user/admin/detail", {
      headers: {
        ...authHeader(),
      },
    });
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
}

export const getCurrentUserDetailApi = async (data) => {
  try {
    const res = await axios.get(BASE_URL + "/user/me", {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateUserDetailApi = async ({ userId, data }) => {
  try {
    const res = await axios.put(BASE_URL + "/user/" + userId, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const fetchGstDetailApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/gstDetail", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const fetchPanDetailApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/panDetail", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const verifyBankApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/bankDetail", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAadharOtpApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/generateAadharOtp", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const verifyAadharOtpApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/verifyAadharOtp", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const verifyVoterApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/voterDetail", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const verifyRcApi = async ({ data, userId }) => {
  try {
    const res = await axios.post(BASE_URL + "/user/rcDetail", data, {
      headers: {
        ...authHeader(),
        "x-user-id": userId,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteUserApi = async ({ userId }) => {
  try {
    const res = await axios.delete(BASE_URL + "/user/" + userId, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getOtpApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/auth", data, {
      headers: { ...authHeader() },
    });
    return res.data;
  } catch (e) {
    console.log(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const verifyOtpApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL + "/auth/verify-otp", data, {
      headers: { ...authHeader() },
    });
    return res.data;
  } catch (e) {
    throw Error( e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateAdminUserDetailApi = async ({ userId, data }) => {
  try {
    const res = await axios.put(BASE_URL + "/user/admin/" + userId, data, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
}
