import React from "react";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
  getAccessRequestsApi,
  processAccessRequestApi,
} from "../../services/request-access";
import { useUserStore } from "../../store/user";
import { useNavigate } from "react-router-dom";
import { statusChip, statusChipWithText } from "../../utils/status-chip";
import ViewRequestModal from "./components/ViewRequestModal";

export default function AllAccessRequestPage() {
  const userType = localStorage.getItem("storeform-user-type");
  const [currentAccessRequest, setCurrentAccessRequest] = React.useState(null);
  // const userDetail = useUserStore((state) => state.userDetail);
  const navigate = useNavigate();

  const getAllAccessRequests = useQuery({
    queryKey: ["allaccessrequests"],
    retry: false,
    staleTime: Infinity,
    queryFn: getAccessRequestsApi,
    onSuccess: (data) => console.log(data),
  });

  const processAccessRequestMutation = useMutation({
    mutationFn: processAccessRequestApi,
    onSuccess: () => {
      toast.success("Access request processed successfully");
      getAllAccessRequests.refetch();
    },
  });

  const handleProcess = (id, status) => {
    processAccessRequestMutation.mutate({ id, data: { status } });
  };

  const getStatusChipProps = (request, key) => {
    let status, text
    if(!request[key]?.timestamp){
      status = "pending"
      text = dayjs(request.createdAt).format(
        "MMM D, YY h:mm A"
      )
    } 
    else{
      status = request[key]?.status
      text = dayjs(request[key]?.timestamp).format(
        "MMM D, YY h:mm A"
      )
    }

    return {
      text, status
    }
  } 

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Gate Pass
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the gate passes
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                  <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Photo
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Requester
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Meet With
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Purpose
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Request Time
                    </th>
                    {/* <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Staff Appr.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Guard Appr.
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getAllAccessRequests.isLoading ? (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    getAllAccessRequests?.data?.map((request) => (
                      <tr key={request._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {request.requester?.personal_details?.[0]?.user_photo ? <img className="w-16" src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${request.requester?.personal_details?.[0]?.user_photo}`} alt="" /> : null }
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {request.requester?.personal_details?.[0]?.name
                            }
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {request.meet_with}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {request.purpose}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dayjs(request.createdAt).format(
                            "MMM D, YY h:mm A"
                          )}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {statusChip({ status: request.status })}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {statusChipWithText(getStatusChipProps(request, "subUnitApproval"))}
                          
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {statusChipWithText(getStatusChipProps(request, "guardApproval"))}
                          
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                
                          {["A", "UM_STAFF", "UM_GUARD", "SUM_STAFF"].includes(userType) &&
                            request.status === "pending" && 
                            (
                              <>
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                  onClick={() =>
                                    handleProcess(request._id, "approved")
                                  }
                                >
                                  {request.subUnitApproval?.status === "pending"
                                    ? "Approve"
                                    : "Approve"}
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                  onClick={() =>
                                    handleProcess(request._id, "declined")
                                  }
                                >
                                  Decline
                                </button>
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                  onClick={() =>
                                    setCurrentAccessRequest(request._id)
                                  }
                                >
                                  View
                                </button>
                              </>
                            )}

                          {userType === "U" &&
                            request.status === "approved" && (
                              <button
                                type="button"
                                className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                                onClick={() =>
                                  navigate("/ticket-confirm/" + request._id)
                                }
                              >
                                View Pass
                              </button>
                            )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ViewRequestModal
        open={currentAccessRequest ? true : false}
        setOpen={setCurrentAccessRequest}
        currentId={currentAccessRequest}
      />
    </main>
  );
}
