import { nanoid } from "nanoid";

export default function authHeader() {
  const storeFormToken = localStorage.getItem("storeform-token");

  if (storeFormToken) {
    return { "x-auth-token": storeFormToken, "ngrok-skip-browser-warning": nanoid() };
  } else {
    return {"ngrok-skip-browser-warning": nanoid()};
  }
}
