import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useGetSingleAttendance } from "../../../http-hooks/useAttendance";
import SetMap from "../../../components/Maps/SetMap";
import { getUserRole } from "../../../utils/user";
import dayjs from "dayjs";
import ShowMap from "../../../components/Maps/ShowMap";

export default function AttendanceDetailsPopup({ open, setOpen, currentId }) {
  const { data, isLoading } = useGetSingleAttendance(currentId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Attendance Details
                    </Dialog.Title>
                    {isLoading ? (
                      <p className="mt-2">Loading...</p>
                    ) : (
                      <div className="mt-4 space-y-6">
                        <div className="sm:flex-row flex-col flex gap-4 items-center">
                          <div className="w-full sm:h-[400px] h-[200px] flex items-center justify-center">
                            <img
                              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.image}`}
                              alt="Attendance"
                              className="w-auto h-full rounded-lg"
                            />
                          </div>
                          <div className="w-full">
                            <ShowMap {...data?.locationPin} />
                          </div>
                        </div>
                        <div className="border-t pt-4">
                          <h4 className="font-medium text-gray-900">User Details</h4>
                          <dl className="mt-2 divide-y divide-gray-200">
                            <div className="flex justify-between py-2">
                              <dt className="text-gray-500">Name</dt>
                              <dd className="text-gray-900">{data?.user?.personal_details[0]?.name || 'N/A'}</dd>
                            </div>
                            {/* <div className="flex justify-between py-2">
                              <dt className="text-gray-500">Mobile</dt>
                              <dd className="text-gray-900">{data?.user?.loginMobile || 'N/A'}</dd>
                            </div> */}
                            <div className="flex justify-between py-2">
                              <dt className="text-gray-500">User Type</dt>
                              <dd className="text-gray-900">{getUserRole(data?.user?.user_type, data?.user?.is_personal_user)}</dd>
                            </div>
                            <div className="flex justify-between py-2">
                              <dt className="text-gray-500">Time</dt>
                              <dd className="text-gray-900">{dayjs(data?.createdAt).format("MMMM D, YYYY h:mm A")}</dd>
                            </div>
                          </dl>
                        </div>
                        <div className="border-t pt-4">
                          <h4 className="font-medium text-gray-900">Unit Details</h4>
                          <dl className="mt-2 divide-y divide-gray-200">
                            <div className="flex justify-between py-2">
                              <dt className="text-gray-500">Unit Name</dt>
                              <dd className="text-gray-900">{data?.unit?.name || 'N/A'}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}