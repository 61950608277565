import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAccessRequestApi } from "../../services/request-access";
import { useParams } from "react-router-dom";
import { CheckCircleIcon, MapPinIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { statusChipWithText } from "../../utils/status-chip";
import logo from "../../assets/images/logo.png";
export default function TicketPage() {
  const { id } = useParams();

  const { isLoading, data } = useQuery({
    queryKey: ["allaccessrequest", id],
    retry: false,
    staleTime: Infinity,
    queryFn: () => getAccessRequestApi(id),
    onSuccess: (data) => console.log(data),
  });

  return (
    <body class="bg-gray-100 flex justify-center items-center min-h-screen">
      {isLoading ? (
        <div class="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div class="bg-white p-4 rounded-lg shadow-lg">
            <p class="font-semibold">Loading...</p>
          </div>
        </div>
      ) : (
        <div class="bg-white w-96 rounded-xl shadow-lg overflow-hidden">
          <div class="pb-4 pt-2">
            <img className="mx-auto h-32 w-auto" src={logo} alt="Nutri Bake" />
            <h2 class="px-6 text-xl font-bold text-gray-800 text-center">
              GATE PASS
            </h2>
            <div className="flex w-full items-center justify-center my-3">
              <img
                alt=""
                className="max-w-[200px]"
                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.requester?.personal_details?.[0]?.user_photo}`}
              />
            </div>
            <div class="flex flex-col gap-2 mt-4 text-gray-600">
              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Name: </div>
                <div className="font-semibold w-full flex-1">
                  {data?.requester?.personal_details?.[0]?.name}
                </div>
              </p>
              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Mobile: </div>
                <div className="font-semibold w-full flex-1">
                  {data?.requester?.loginMobile}
                </div>
              </p>
              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Approved At: </div>
                <div className="font-semibold w-full flex-1">
                  {statusChipWithText({
                    status: "approved",
                    text: dayjs(data.updatedAt).format("MMM D, YY h:mm A"),
                  })}
                </div>
              </p>
              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Unit: </div>
                <div className="font-semibold w-full flex-1">
                  {data?.subUnit?.unit?.name}
                </div>
              </p>

              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Sub-Unit: </div>
                <div className="font-semibold w-full flex-1">
                  {data?.subUnit?.name}
                </div>
              </p>
              <p class="flex items-center justify-center gap-4">
                <div className="w-full text-right flex-1">Meeting With: </div>
                <div className="font-semibold w-full flex-1">
                  {data?.meet_with}
                </div>
              </p>
            </div>

            <div class="mt-2 border-t border-gray-200"></div>

            <div class="mt-2 text-gray-600">
              <p class="mb-2 flex items-center justify-center gap-4 font-semibold underline">
                Purpose
              </p>
              <p class="flex items-center justify-center gap-4">
                <span>{data?.purpose}</span>
              </p>
            </div>
          </div>

          <div class="flex justify-center items-center py-3">
            {data?.status === "rejected" ? (
              <img
                src={require("../../assets/denied.png")}
                alt="Rejected"
                class="w-32 h-32"
              />
            ) : null}
            {data?.status === "approved" ? (
              <img
                src={require("../../assets/approve.png")}
                alt="Approved"
                class="w-32 h-32"
              />
            ) : null}
          </div>
        </div>
      )}
    </body>
  );
}
