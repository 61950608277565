import {
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  ClipboardDocumentCheckIcon,
  FolderIcon,
  TicketIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const navigation = [
  // { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
  {
    name: "Users",
    href: "/user",
    icon: UsersIcon,
    userTypes: ["A", "UM_BASIC", "UM_STAFF"],
  },
  {
    name: "Profile",
    href: "/profile",
    icon: UsersIcon,
    userTypes: [
      "U",
      "UM_STAFF",
      "SUM_STAFF",
      "UM_GUARD",
      "STAFF",
      "MAKER",
      "CHECKER",
      "AUTHORIZER",
    ],
  },
  {
    name: "Unit master",
    href: "/unit-master",
    icon: BuildingOfficeIcon,
    userTypes: ["A"],
  },
  {
    name: "Sub unit master",
    href: "/sub-unit-master",
    icon: BuildingOffice2Icon,
    userTypes: ["A", "UM_STAFF"],
  },
  { name: "Documents", href: "/documents", icon: FolderIcon, userTypes: ["A"] },
  {
    name: "Attendance",
    href: "/attendance",
    icon: ClipboardDocumentCheckIcon,
    userTypes: ["A"],
  },
  {
    name: "Gate Pass",
    href: "/access-request",
    icon: TicketIcon,
    userTypes: ["A", "UM_STAFF", "SUM_STAFF", "UM_GUARD", "U"], // TODO: subunit master can check only his passes
  },
];

export const dashboardCards = [
  {
    name: "Total Users",
    slug: "users",
    initials: "U",
    href: "/user",
    label: "Loading...",
    bgColor: "bg-pink-600",
    access: ["A", "UM_BASIC", "UM_STAFF"],
  },
  {
    name: "Profile",
    slug: "update_profile",
    initials: "P",
    href: "/profile",
    label: "Update Profile",
    bgColor: "bg-blue-600",
    access: [
      "U",
      "UM_STAFF",
      "SUM_STAFF",
      "UM_GUARD",
      "STAFF",
      "MAKER",
      "CHECKER",
      "AUTHORIZER",
    ],
  },
  {
    name: "Attendance",
    slug: "attendance",
    initials: "A",
    href: "/attendance",
    label: "Look all employee attendances",
    bgColor: "bg-red-600",
    access: ["A"],
  },
  {
    name: "Units",
    slug: "unit_masters",
    initials: "UN",
    href: "/unit-master",
    label: "Loading...",
    bgColor: "bg-purple-600",
    access: ["A"],
  },
  {
    name: "Gate Pass",
    slug: "gate_pass",
    initials: "GP",
    href: "/access-request",
    label: "All Gate Passes",
    bgColor: "bg-green-600",
    access: ["A", "UM_STAFF", "SUM_STAFF", "UM_GUARD", "U"],
  },
];
