import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteDocApi,
  getAccountsDocMismatchSummaryApi,
  getAccountsYearlyDocMismatchSummaryApi
} from "../../services/document.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";
import TableData from "./TableData";
import TableDataReconciled from "./TableDataReconciled";
import TableDataSmartMismatch from "./TableDataSmartMismatch";
import { getAllUserDataApi } from "../../services/user.services";
import dummyData from "../../utils/data/dummy_data.json";
import { docMismatchSummaryFilterValidate } from "../../validation/uploadDocValidation";
import CustomSelect from "../../components/Input/Select";
import DeleteModal from "../HomePage/components/DeleteModal";
import { YearlyData } from "./YearlyData";
import AddDocModal from "../DocumentPage/components/AddDocModal";
const dummyDocData = dummyData.data;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const months = [
  {
    label: "Jan",
    value: "01",
  },
  {
    label: "Feb",
    value: "02",
  },
  {
    label: "Mar",
    value: "03",
  },
  {
    label: "Apr",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "Jun",
    value: "06",
  },
  {
    label: "Jul",
    value: "07",
  },
  {
    label: "Aug",
    value: "08",
  },
  {
    label: "Sep",
    value: "09",
  },
  {
    label: "Oct",
    value: "10",
  },
  {
    label: "Nov",
    value: "11",
  },
  {
    label: "Dec",
    value: "12",
  },
];

const years = [
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
].reverse();

export default function DocumentMismatchSummary() {
  const queryClient = useQueryClient();

  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2024");
  const [docFor, setDocFor] = useState(null);
  const [docType, setDocType] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentSelectedDoc, setCurrentSelectedDoc] = useState(null);
  const [addDocModal, setAddDocModal] = useState(false);

  const [formError, setFormError] = useState({
    doc_type: null,
    doc_for: null,
  });

  const [selectedCard, setSelectedCard] = useState(null);

  const getAllAcountMismatchDocsQuery = useQuery({
    queryKey: [
      selectedMonth,
      selectedYear,
      docFor,
      docType,
      "allAccountMismatchDocsSummary",
    ],
    retry: false,
    queryFn: () => {
      let monthStringArr = [];

      for (let selectedMonthRow of selectedMonth) {
        monthStringArr.push(`${selectedMonthRow.value}${selectedYear?.value}`);
      }

      return getAccountsDocMismatchSummaryApi(
        monthStringArr.join(","),
        docFor?.value,
        docType?.value
      );
    },
    onSuccess: (data) => console.log(data),
    onError: (error) => toast.error(error.message),
  });

  const getAllUsersQuery = useQuery({
    queryKey: ["allusers"],
    enabled: true,
    queryFn: () => getAllUserDataApi(),
  });

  let stats = [];
  if (
    getAllAcountMismatchDocsQuery?.data &&
    !getAllAcountMismatchDocsQuery?.isLoading
  ) {
    for (let key in getAllAcountMismatchDocsQuery.data.summary) {
      stats.push({
        name: key,
        value: getAllAcountMismatchDocsQuery.data.summary[key].length,
      });
    }

    stats.push({
      name: "Smart Mismatch",
      value: "",
    });
  }

  const docDeleteMutation = useMutation({
    mutationFn: deleteDocApi,
    onSuccess: (data) => {
      console.log(data);
      toast.success(data.msg);
      setDeleteModalOpen(false);
      getAllAcountMismatchDocsQuery.refetch();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Accounts Documents Summary Year Wise
          </h1>
        </div>
      </div>

      <YearlyData />

      <div className="sm:flex sm:items-center my-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Accounts Documents Summary Month Wise
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            List of documents from Tally and GST which are common / missing
          </p>
        </div>
      </div>

      <div className="w-full flex gap-2">
        <CustomSelect
          id="month"
          name="Month"
          options={months}
          formError={formError}
          setFormErrors={setFormError}
          singleFieldValidation={docMismatchSummaryFilterValidate}
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e)}
          className="mb-4 w-[200px]"
          isMultiple={true}
        />

        <CustomSelect
          id="year"
          name="Year"
          options={years}
          formError={formError}
          setFormErrors={setFormError}
          singleFieldValidation={docMismatchSummaryFilterValidate}
          value={selectedYear}
          onChange={(e) => setSelectedYear(e)}
          className="mb-4 w-[100px]"
        />

        <CustomSelect
          id="doc_type"
          name="Doc Type"
          options={
            dummyDocData
              .filter((item) => item.doc_group === "Accounts")[0]
              ?.["documents"].map((item) => ({
                value: item.doc_type,
                label: item.doc_type,
              })) || []
          }
          formError={formError}
          setFormErrors={setFormError}
          singleFieldValidation={docMismatchSummaryFilterValidate}
          value={docType}
          onChange={(e) => setDocType(e)}
          className="mb-4"
        />
        <CustomSelect
          id="doc_for"
          name="Doc For"
          options={
            getAllUsersQuery.data?.data.map((item) => ({
              value: item._id,
              label: item.legal_name + " - " + item.loginMobile,
            })) || []
          }
          formError={formError}
          setFormErrors={setFormError}
          singleFieldValidation={docMismatchSummaryFilterValidate}
          value={docFor}
          onChange={(e) => setDocFor(e)}
          className="mb-4"
        />
      </div>

      <dl className="my-2 rounded-lg mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <div
            onClick={() => setSelectedCard(stat.name)}
            key={stat.name}
            className="cursor-pointer flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white p-6"
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>
            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
      {selectedCard && getAllAcountMismatchDocsQuery?.data ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle sm:px-6 lg:px-8">
              {selectedCard === "Smart Mismatch" ? (
                <TableDataSmartMismatch
                  data={
                    getAllAcountMismatchDocsQuery?.data?.smart_mismatch
                      ?.common_mismatch
                  }
                  setDeleteModalOpen={setDeleteModalOpen}
                  setCurrentSelectedDoc={setCurrentSelectedDoc}
                  isLoading={
                    getAllAcountMismatchDocsQuery.isLoading ||
                    getAllAcountMismatchDocsQuery.isRefetching
                  }
                  onViewClick={() => setAddDocModal(true)}
                />
              ) : selectedCard === "Reconciled Documents" ? (
                <TableDataReconciled
                  data={
                    getAllAcountMismatchDocsQuery?.data?.summary[selectedCard]
                  }
                  setDeleteModalOpen={setDeleteModalOpen}
                  setCurrentSelectedDoc={setCurrentSelectedDoc}
                  isLoading={
                    getAllAcountMismatchDocsQuery.isLoading ||
                    getAllAcountMismatchDocsQuery.isRefetching
                  }
                  onViewClick={() => setAddDocModal(true)}
                />
              ) : (
                <TableData
                  data={
                    getAllAcountMismatchDocsQuery?.data?.summary[selectedCard]
                  }
                  setDeleteModalOpen={setDeleteModalOpen}
                  setCurrentSelectedDoc={setCurrentSelectedDoc}
                  isLoading={
                    getAllAcountMismatchDocsQuery.isLoading ||
                    getAllAcountMismatchDocsQuery.isRefetching
                  }
                  onViewClick={() => setAddDocModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-center mt-8 text-gray-500">
            Select a card to view data
          </p>
        </div>
      )}
      {/* Delete modal */}
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        handleDelete={() => {
          docDeleteMutation.mutate({ docId: currentSelectedDoc });
        }}
      />

      {/* view modal */}
      <AddDocModal
        open={addDocModal}
        setOpen={setAddDocModal}
        currentData={currentSelectedDoc}
        setCurrentSelectedDoc={setCurrentSelectedDoc}
        isView={true}
      />
    </main>
  );
}
