import { useMutation } from "@tanstack/react-query";
import Input from "../../../components/Input/Input";
import { useUserDetailStore } from "../../../store/userDetail";
import { bankDetailSingleValidate } from "../../../validation/bankValidation";
import { toast } from "react-hot-toast";
import { verifyBankApi } from "../../../services/user.services";
import Button from "../../../components/Button/Button";

export default function BankDetail({ userId }) {
  const bankDetail = useUserDetailStore((state) => state.bankDetail);
  const setBankDetail = useUserDetailStore((state) => state.setBankDetail);
  const bankError = useUserDetailStore((state) => state.bankError);
  const setBankError = useUserDetailStore((state) => state.setBankError);

  const verifyBankMutation = useMutation({
    mutationFn: verifyBankApi,
    onSuccess: (data) => {
      console.log("data", data);
      setBankDetail(data.data);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const handleVerifyBank = () => {
    if (bankDetail.account_number === "" || bankDetail.ifsc === "") {
      toast.error("Please enter account number and ifsc code");
    } else {
      verifyBankMutation.mutate({
        data: {
          account_number: bankDetail.account_number,
          ifsc: bankDetail.ifsc,
          consent: "Y",
        },
        userId: userId,
      });
    }
  };

  return (
    <div>
      <Input
        placeholder="Account Number"
        title="Account Number"
        valKey="account_number"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
        isMasked={bankDetail.is_bank_verified >= 1}
      />
      <Input
        placeholder="IFSC code"
        title="IFSC Code"
        valKey="ifsc"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
        isMasked={bankDetail.is_bank_verified >= 1}
      />
      <div className="flex items-center justify-between gap-2 mt-1">
        {bankDetail.is_bank_verified ? (
          <p className="text-xs text-green-800">
            Your Account detail is secured with us
          </p>
        ) : (
          <p></p>
        )}
        <Button
          isLoading={verifyBankMutation.isLoading}
          onClick={
            bankDetail.is_bank_verified >= 1 ? () => null : handleVerifyBank
          }
          title={
            bankDetail.is_bank_verified > 0
              ? bankDetail.is_bank_verified >= 1
                ? "Validated"
                : "Verify Bank again"
              : "Verify Bank"
          }
          disabled={bankDetail.account_number === "" || bankDetail.ifsc === "" || bankError.account_number || bankError.ifsc}
        />
      </div>

      <Input
        placeholder="Bank Name"
        title="Bank Name"
        valKey="bank_name"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
      />
      <Input
        placeholder="Branch Name"
        title="Branch Name"
        valKey="branch"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
      />
      <Input
        placeholder="Account Holder Name"
        title="Account Holder Name"
        valKey="holder_name"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
      />
      <Input
        placeholder="City"
        title="City"
        valKey="city"
        inputValues={bankDetail}
        setInputValues={setBankDetail}
        singleFieldValidation={bankDetailSingleValidate}
        setFormErrors={setBankError}
        formErrors={bankError}
        disabled={bankDetail.is_bank_verified >= 1}
      />
    </div>
  );
}
