import { useQuery } from "@tanstack/react-query";
const {
  getAttendanceApi,
  getSingleAttendanceApi,
  getAttendanceAnalyticsApi
} = require("../services/attendance");

export const useGetAllAttendance = (filter = {}) => {
  return useQuery({
    queryKey: ["allattendance", filter],
    queryFn: () => getAttendanceApi(filter),
  });
};

export const useGetSingleAttendance = (id) => {
  return useQuery({
    queryKey: ["single-attendance", id],
    queryFn: () => getSingleAttendanceApi(id),
  });
};

export const useAttendanceAnalytics = (filter) => {
  return useQuery({
    queryKey: ["attendanceAnalytics", filter],
    queryFn: () => getAttendanceAnalyticsApi(filter),
    onSuccess: (data) => console.log(data),
  });
};
