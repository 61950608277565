import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddDocModal from "../DocumentPage/components/AddDocModal";
import Input from "../../components/Input/Input";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllRequestedDocsApi, updateDocRequestApi } from "../../services/document.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";

export default function DocumentApprovePage() {
const queryClient = useQueryClient();
const userDetail = useUserStore((state) => state.userDetail);


  const [addDocModal, setAddDocModal] = useState(false);
  const [currentSelectedDoc, setCurrentSelectedDoc] = useState("");

  const getAllRequestedDocsQuery = useQuery({
    queryKey: ["allReqDocs"],
    retry: false,
    queryFn: () => getAllRequestedDocsApi({}),
    onSuccess: (data) => console.log(data),
    onError: (error) => toast.error(error.message),
  });

  const updateDocRequestMutation = useMutation({
    mutationFn: updateDocRequestApi,
    onSuccess: (data) => {
      console.log(data);
      toast.success(data.msg);
      queryClient.invalidateQueries("allReqDocs");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleApprove = (id) => {
    updateDocRequestMutation.mutate({
      id,
      payload: { status: "APPROVE" },
    });
  };

  const handleReject = (id) => {
    updateDocRequestMutation.mutate({
      id,
      payload: { status: "REJECT" },
    });
  }


  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Requested Documents
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            List of documents that needs to be approved
          </p>
        </div>
      </div>

      {getAllRequestedDocsQuery.isLoading
        ? "Loading..."
        : getAllRequestedDocsQuery?.data?.data.map((docRequest) => (
            <div className="mb-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Doc Group
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Doc Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Doc For
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {docRequest.docs.map((doc) => (
                      <tr key={doc._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {doc.doc_group}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {doc.doc_type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {doc.doc_for.legal_name || doc.doc_for.loginMobile}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm ring-1 ring-inset ring-green-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                            onClick={() => {
                              setCurrentSelectedDoc({
                                ...doc,
                                docRequestId:docRequest._id 
                              });
                              setAddDocModal(true);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Input
                title="Purpose"
                valKey="purpose"
                inputValues={docRequest}
                setInputValues={null}
                disabled={true}
              />
               <Input
                title="Status"
                valKey="status"
                inputValues={docRequest}
                setInputValues={null}
                disabled={true}
              />
             {["AUTHORIZER", "A"].includes(userDetail.userType)&& <div className="mt-4 flex justify-end gap-2">
                <button
                  type="submit"
                  className="rounded-md border border-red-600 px-4 py-2 text-sm font-medium text-red-600 shadow-sm hover:border-red-700 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:border-red-600"
                  onClick={() => handleReject(docRequest._id)}
                  disabled={docRequest.status === "REJECT" || updateDocRequestMutation.isLoading}
                >
                  {
                    updateDocRequestMutation.isLoading ? "Rejecting..." : "Reject"
                  }
                </button>
                <button
                  type="submit"
                  className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-indigo-600"
                  onClick={() => handleApprove(docRequest._id)}
                  disabled={docRequest.status === "APPROVE" || updateDocRequestMutation.isLoading}
                >
                  {
                    updateDocRequestMutation.isLoading ? "Approving..." : 
                    docRequest.status === "APPROVE" ? "Approved" : "Approve"
                  }
                </button>
              </div>}
            </div>
          ))}

      {/* Add doc modal */}
      <AddDocModal
        open={addDocModal}
        setOpen={setAddDocModal}
        currentData={currentSelectedDoc}
        setCurrentSelectedDoc={setCurrentSelectedDoc}
        isView={true}
        isDocRequest={true}
      />
    </main>
  );
}
