import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { getOtpApi, verifyOtpApi, userLoginApi } from "../../services/user.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo.png"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AdminLoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const unitMasterId = searchParams.get("unitMasterId");

  const setToken = useUserStore((state) => state.setToken);
  const setUserDetail = useUserStore((state) => state.setUserDetail);

  const userLoginMutation = useMutation({
    mutationFn: userLoginApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      setToken(data.data.token);
      setUserDetail(data.data.user);
      switch (data.data.user.userType) {
        case "UM_STAFF":
          navigate("/sub-unit-master");
          break;
        case "SUM_STAFF":
        case "UM_GUARD":
          navigate("/access-request");
          break;
        default:
          navigate("/user");
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    // You might want to add validation here before making the API call

    // Call the login API using react-query's mutate function
    userLoginMutation.mutate({ email, password });
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-64 w-auto"
            src={logo}
            alt="Nutri Bake"
          />
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Login/Signup
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleLogin} method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                {/* <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div> */}
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={userLoginMutation.isLoading}
              >
                {userLoginMutation.isLoading ? "Loading..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminLoginPage;
