import { create } from "zustand";

const defaultPersonalDetail = {
  _id: "",
  personal_type: "Salesperson",
  name: "",
  dob: "",
  gender: "",
  loginMobile : "",
  mobile: "",
  whatsapp : "",
  email: "",
  house: "",
  street: "",
  district: "",
  sub_district: "",
  landmark: "",
  locality: "",
  post_office: "",
  state: "",
  pincode: "",
  exact_location : null,

  is_aadhar_verified: 0,
  aadhar: "",
  is_voter_verified: 0,
  voter: "",
  is_dl_verified: 0,
  dl: "",
  is_vehicle: "No",
  is_rc_verified: 0,
  rc: "",

  fb_url: "",
  twitter_url: "",
  instagram_url: "",
  linkedin_url: "",

  //Parents details
  parents_anniversary_date : "",
  father: "",
  father_dob: "",
  father_occupation: "",
  father_mobile: "",
  mother_name: "",
  mother_dob: "",
  mother_occupation: "",
  mother_mobile: "",

  is_married: "No",
  anniversary_date: "",
  num_kids: 0,
  spouse_name: "",
  spouse_dob: "",

  user_photo: "",
};
const defaultPersonalDetailError = {
  personal_type: "",
  name: "",
  dob: "",
  gender: "",
  mobile: "",
  email: "",
  father: "",
  house: "",
  street: "",
  district: "",
  sub_district: "",
  landmark: "",
  locality: "",
  post_office: "",
  state: "",
  pincode: "",

  aadhar: "",
  voter: "",
  dl: "",
  rc: "",

  fb_url: "",
  twitter_url: "",
  instagram_url: "",
  linkedin_url: "",
  is_married: "",
  anniversary_date: "",
  num_kids: "",
  spouse_name: "",
  spouse_dob: "",
  user_photo: "",
};

export const useUserDetailStore = create((set) => ({
  isEdited : false,
  statutoryDetail: {
    gst: "",
    business_type: "",
    pan: "",
    fssai: "",
    msme: "",
    trade: "",
    cin_din: "",
    is_gst_verified: 0,
    is_pan_verified: 0,
    is_fssai_verified: 0,
    is_msme_verified: 0,
    is_cin_din_verified: 0,
  },
  statutoryError: {
    gst: "",
    business_type: "",
    pan: "",
    fssai: "",
    msme: "",
    trade: "",
    cin_din: "",
  },

  setStatutoryDetail: (data) =>
    set((state) => {
      const filledObj = Object.fromEntries(
        Object.keys(state.statutoryDetail).map((key) => [
          key,
          typeof data[key] === "number" ? data[key] || 0 : data[key] || "",
        ])
      );
      return {
        ...state,
        statutoryDetail: filledObj,
      };
    }),

  setStatutoryError: (data) =>
    set((state) => {
      return {
        ...state,
        statutoryError: data,
      };
    }),

  personalDetail: [{ ...defaultPersonalDetail }],
  personalDetailExpand: [false],
  personalError: [{ ...defaultPersonalDetailError }],
  setIsEdited : (data) => set((state) => {
    return {
     ...state,
      isEdited : data,
    }
  }),
  setPersonalDetail: (data) =>
    set((state) => {
      const filledArray = data.map((item) => {
        return Object.fromEntries(
          Object.keys(defaultPersonalDetail).map((key) => [
            key,
            typeof item[key] === "number" ? item[key] || 0 : item[key] || "",
          ])
        );
      });

      return {
        ...state,
        personalDetail: filledArray,
      };
    }),

  addPersonalDetail: (data) =>
    set((state) => {
      console.log("data inside userDetail", data);
      return {
        ...state,
        personalDetail: [
          ...state.personalDetail,
          { ...defaultPersonalDetail, _id: data._id },
        ],
        personalError: [...state.personalError, defaultPersonalDetailError],
        personalDetailExpand: [...state.personalDetailExpand, false],
      };
    }),

  deletePersonalDetail: (currentId) =>
    set((state) => {
      const index = state.personalDetail.findIndex(
        (item) => item._id === currentId
      );
      const updatedDetail = [...state.personalDetail];
      updatedDetail.splice(index, 1);
      const updatedError = [...state.personalError];
      updatedError.splice(index, 1);
      const updatedExpand = [...state.personalDetailExpand];
      updatedExpand.splice(index, 1);
      return {
        ...state,
        personalDetail: updatedDetail,

        personalError: updatedError,
        personalDetailExpand: updatedExpand,
      };
    }),

  setPersonalError: (data, index) =>
    set((state) => {
      const updatedError = [...state.personalError];
      updatedError[index] = data;
      return {
        ...state,
        personalError: updatedError,
      };
    }),

  togglePersonalDetailExpand: (index) =>
    set((state) => {
      const updatedExpand = [...state.personalDetailExpand];
      updatedExpand[index] = !updatedExpand[index];
      return {
        ...state,
        personalDetailExpand: updatedExpand,
      };
    }),

  businessDetail: {
    legal_name: "",
    trade_name: "",
    center_jurisdiction: "",
    state_jurisdiction: "",
    taxpayer_type: "",
    date_of_gst_registration: "",
    office_address: "",
    state: "",
    directors: "",
  },
  businessError: {
    legal_name: "",
    trade_name: "",
    center_jurisdiction: "",
    state_jurisdiction: "",
    taxpayer_type: "",
    date_of_gst_registration: "",
    office_address: "",
    state: "",
    directors: "",
  },

  setBusinessDetail: (data) =>
    set((state) => {
      const filledObj = Object.fromEntries(
        Object.keys(state.businessDetail).map((key) => [
          key,
          typeof data[key] === "number" ? data[key] || 0 : data[key] || "",
        ])
      );
      return {
        ...state,
        businessDetail: filledObj,
      };
    }),
  setBusinessError: (data) =>
    set((state) => {
      return {
        ...state,
        businessError: data,
      };
    }),

  bankDetail: {
    account_number: "",
    ifsc: "",
    bank_name: "",
    branch: "",
    holder_name: "",
    city: "",
    is_bank_verified: 0,
  },
  bankError: {
    account_number: "",
    ifsc: "",
    bank_name: "",
    branch: "",
    holder_name: "",
    city: "",
  },

  setBankDetail: (data) =>
    set((state) => {
      const filledObj = Object.fromEntries(
        Object.keys(state.bankDetail).map((key) => [
          key,
          typeof data[key] === "number" ? data[key] || 0 : data[key] || "",
        ])
      );
      return {
        ...state,
        bankDetail: filledObj,
      };
    }),
  setBankError: (data) =>
    set((state) => {
      return {
        ...state,
        bankError: data,
      };
    }),

  uploadDetail: {
    gst_photo: "",
    pancard_photo: "",
    fssai_photo: "",
    msme_photo: "",
    trade_photo: "",
    // user_photo: "",
    office_photo: "",
    shop_photo: "",
    godown_photo: "",
  },
  uploadError: {
    gst_photo: "",
    pancard_photo: "",
    fssai_photo: "",
    msme_photo: "",
    trade_photo: "",
    office_photo: "",
    shop_photo: "",
    godown_photo: "",
  },

  setUploadDetail: (data) =>
    set((state) => {
      const filledObj = Object.fromEntries(
        Object.keys(state.uploadDetail).map((key) => [
          key,
          typeof data[key] === "number" ? data[key] || 0 : data[key] || "",
        ])
      );
      return {
        ...state,
        uploadDetail: filledObj,
      };
    }),
  setUploadError: (data) =>
    set((state) => {
      return {
        ...state,
        uploadError: data,
      };
    }),
}));
