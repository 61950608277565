import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import CustomSelect from "../../../components/Input/Select";
import { getRoleMapping, getRoleOptions } from "../../../utils/user";
import { useGetAllUnitMasters } from "../../../http-hooks/useUnitMaster";
import { useEditUserRole } from "../../../http-hooks/useUser";
import Input from "../../../components/Input/Input";
import { useQueryClient } from "@tanstack/react-query";

export default function AssignRoleModal({ open, setOpen, user }) {
  const queryClient = useQueryClient();
  const [userRole, setUserRole] = useState(null);
  const [unit, setUnit] = useState(null);
  const [shift, setShift] = useState({ start_shift: null, end_shift: null });

  const { data: unitMasters, isLoading: isLoadingUnitMasters } =
    useGetAllUnitMasters();

  const { mutate: editUserRoleMutate, isLoading: isLoadingEditUserRole } =
    useEditUserRole({
      onSuccess: (data) => {
        console.log(data);
        setOpen(false);
        toast.success("User edited successfully");
        queryClient.invalidateQueries("allusers");
      },
    });

  useEffect(() => {
    // Set User Role
    if (user?.user_type) {
      setUserRole(() => {
        return {
          value: user.user_type,
          label: getRoleMapping()[user.user_type],
        };
      });
    } else {
      setUserRole(() => null);
    }

    // Set Unit
    if (user?.unit) {
      setUnit(() => {
        return { value: user.unit._id, label: user.unit.name };
      });
    } else {
      setUnit(() => null);
    }

    // Start Shift Time
    if(user?.start_shift){
      setShift((prev) => {
        return {
          ...prev,
          start_shift: user.start_shift,
        };
      })
    }

    // End Shift Time
    if(user?.end_shift){
      setShift((prev) => {
        return {
          ...prev,
          end_shift: user.end_shift,
        };
      })
    }
  }, [user]);

  const isLoading = isLoadingUnitMasters;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:px-6 sm:py-8">
                {isLoading ? (
                  "Loading..."
                ) : (
                  <>
                    <div>
                      <div className="mt-1 sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Assign/Update User
                        </Dialog.Title>
                        <div className="mt-2 mb-4">
                          <CustomSelect
                            id="role"
                            name="Role"
                            value={userRole}
                            onChange={(selectedOption) =>
                              setUserRole(selectedOption)
                            }
                            options={getRoleOptions()}
                          />
                        </div>

                        <div className="mt-2 mb-4">
                          <CustomSelect
                            id="unit"
                            name="Unit"
                            value={unit}
                            onChange={(selectedOption) =>
                              setUnit(selectedOption)
                            }
                            options={unitMasters.map((k) => {
                              return {
                                label: k.name,
                                value: k._id,
                              };
                            })}
                          />
                        </div>
                        {userRole?.value === "UM_GUARD" ? (
                          <div className="mt-2 mb-4">
                            <Input
                              placeholder="Enter shift start time"
                              title="Shift Start"
                              valKey="start_shift"
                              inputValues={shift}
                              setInputValues={(data) =>
                                setShift((prev) => {
                                  return { ...prev, start_shift: data.start_shift };
                                })
                              }
                              type="time"
                            />

                            <Input
                              placeholder="Enter shift end time"
                              title="Shift End"
                              valKey="end_shift"
                              inputValues={shift}
                              setInputValues={(data) =>
                                setShift((prev) => {
                                  return { ...prev, end_shift: data.end_shift };
                                })
                              }
                              type="time"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          editUserRoleMutate({
                            data: {
                              unit: unit?.value,
                              user_type: userRole?.value,
                              start_shift : shift.start_shift,
                              end_shift : shift.end_shift
                            },
                            id: user?._id,
                          });
                        }}
                        disabled={isLoadingEditUserRole}
                      >
                        {isLoadingEditUserRole ? "Assigning..." : "Assign"}
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
