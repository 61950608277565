import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import DeleteModal from "../HomePage/components/DeleteModal";
import {  deleteSubUnitMasterApi } from "../../services/sub-unit-master.services";
import { useGetAllSubUnitMasters } from "../../http-hooks/useSubUnit"

export default function SubUnitMasterPage() {
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [currentSelectedSubUnit, setCurrentSelectedSubUnit] = React.useState(null);

  const {isLoading: isLoadingSubUnits, data : subUnitsData, refetch : refetchSubUnits} = useGetAllSubUnitMasters()

  const deleteSubUnitMasterMutation = useMutation({
    mutationFn: deleteSubUnitMasterApi,
    onSuccess: () => {
      setDeleteModalOpen(false);
      toast.success("Sub Unit Master deleted successfully");
      refetchSubUnits()
    }
  });

  return (
    <main className="py-5 lg:pl-72 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Sub Unit Master
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the sub unit masters
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 flex gap-4">
          <button
            type="button"
            className="inline-flex bg-white rounded-md border border-indigo-600 px-3 py-2 text-center text-sm font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            onClick={() => navigate("/sub-unit-master/create")}
          >
            Create
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Unit
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     Remark
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Contact person
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoadingSubUnits ? (
                    <tr><td className="text-center" colSpan={5}>loading...</td></tr>
                  ) : (
                    subUnitsData?.map((sub_unit_master) => (
                      <tr key={sub_unit_master._id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {sub_unit_master.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {sub_unit_master.unit?.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {sub_unit_master.remarks}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {sub_unit_master.contact_person_detail?.email}  
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-yellow-600 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                            onClick={() => navigate(`/sub-unit-master/${sub_unit_master._id}`)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white ml-2"
                            onClick={() => {
                              setCurrentSelectedSubUnit(sub_unit_master._id);
                              setDeleteModalOpen(true);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Delete modal */}
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        handleDelete={() =>
          deleteSubUnitMasterMutation.mutate({ id: currentSelectedSubUnit })
        }
      />
    </main>
  );
}
